import React from "react";
import Head from "./Head";
import About from "./About";
import Summaries from "./Summaries";

function Home() {
  const quoteText = 'Facts would be barren if there were not minds capable of choosing between them, discerning those which have something hidden inside them, and recognizing what is hidden - minds that, within the bare fact, can feel the soul of the fact.'
  const quoteAuthor = 'Henri Poincaré'
  const quoteLink = 'https://archive.org/details/scienceetmthod00poin/page/22/mode/1up'
  
  return (
    <div className="home">
      <Head  quoteText={quoteText} quoteLink={quoteLink} quoteAuthor={quoteAuthor}/>
      <Summaries />
      <About />
    </div>
  );
};

export default Home;