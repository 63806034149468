import React from "react";
import Main from "./Main";

function Blog() {
  return (
    <div className="body bg-color-dark-scale-1">
      <Main />
    </div>
  );
}

export default Blog;