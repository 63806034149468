import React, { useState, useEffect }  from "react";

const Summary = (props) => {
  let [summaryTitle, setSummaryTitle] = useState([])
  let [summaryText, setSummaryText] = useState([])
  let [summaryDate, setSummaryDate] = useState([])
  let [summaryImage, setSummaryImage] = useState([])
  let [postURL, setPostURL] = useState([])

  console.log(props.summaryData)

  useEffect(() => {
    switch(props.summaryData) {
      case "post1":
        setSummaryTitle('Dynamic 24-Cell Projection')
        setSummaryText('Projection of a 24-Cell onto a 3-ball')
        setSummaryDate('March 14, 2023')
        setSummaryImage('./img/24cell.png')
        setPostURL("post1")
        return
      case "post2":
        setSummaryTitle('Dynamic 600-Cell Projection')
        setSummaryText('Projection of a 600-Cell onto a 3-ball')
        setSummaryDate('March 14, 2023')
        setSummaryImage('./img/600cell.png')
        setPostURL("post2");
        return
      case "post3":
        setSummaryTitle('Spinor Representation of Gaussian Primes')
        setSummaryText('A look at the spinor representation of Gaussian primes, which leads to a generalizaton of of Fermat\'s little theorem.')
        setSummaryDate('August 1, 2022')
        setSummaryImage('./img/deltabar.png')
        setPostURL("post3");
        return
      default:
        return
    }
  });

  return (
    <div className="col-sm-10">
      <article className="post post-medium border-0 pb-0 ps-3 pe-3 ">
        <div className="post-image align-self-center align-items-center">
          <a href={postURL}>
            <img src={summaryImage} className="summary-image"/>
          </a>
        </div>
        <div className="post-content">
          <h2 className="font-weight-semibold text-5 line-height-6 mt-3 mb-0">
            <a className="text-color-tertiary" href={postURL}>
              {summaryTitle}
            </a>
          </h2>
          <h2 className="text-4 line-height-4 mt-0 mb-2 text-color-secondary">
            {summaryDate}
          </h2>
          <p className="text-justify text-color-grey">
            {summaryText}
          </p>
        </div>
      </article>
    </div>
  );
}
export default Summary