import React from "react";
import Head from "../Head";
import {MathJax, MathJaxContext} from "better-react-mathjax"
import Cell600 from "../Components/Cell600";
import BBox from "../Components/Test"

const Post2 = (props) => {
  const title = "Dynamic 600-Cell Projection"
  const date = "July 30, 2022"
  const quoteText = "Perhaps someone devoting their life to it could bring themselves to picture the fourth dimension."
  const quoteAuthor = "Henri Poincaré"
  const quoteLink = "https://www.biodiversitylibrary.org/page/10778548#page/784/mode/1up"


  const config = {
      loader: { load: ["[tex]/html"] },
      tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
      }
  };

  return (
    <div >
      <Cell600 />
    </div>
    // <div class="post">
    //   <Head quoteText={quoteText} quoteLink={quoteLink} quoteAuthor={quoteAuthor} />
    //     <div class="container py-4">
    //       <div class="row">
    //         <div class="col">
    //           <div class="blog-posts single-post">
    //             <MathJaxContext config={config}>
    //               <article class="post post-large blog-single-post border-0 m-0 p-0">
    //                 <div class="post-content ms-0 text-color-grey">
    //                   <h2 class="font-weight-semi-bold text-color-tertiary">{title}</h2>
    //                   <div class="post-meta">
    //                     <span class="text-color-secondary">{date}</span>
    //                   </div>
    //                   <p className="text-color-primary">
    //                     In three dimensions there are five regular convex polyhedra: tetrahedron, octahedron, cube, icosahedron, dodecahedron, known from antiquity as the five Platonic solids. 
    //                     Each Platonic solid has an analogous regular convex polytope in four dimensions: 5-cell, 16-cell, 8-cell, 600-cell, 120-cell, respectively.  But the fourth dimensions holds
    //                     one more regular polytope with no analog in any other dimension, the 24-cell.  In any dimension greater than four there are only three regular convex polyhedra, the 
    //                     analogs of the tetrahedron, octahedron and cube.
    //                     <a href="600cell">
    //                       Dynamic 600-Cell Projection
    //                     </a>
    //                   </p>
    //                   <p className="text-color-primary">
    //                     Let's start with some basic facts about the 24-cell. It has 24 octahedral cells, 96 triangular faces, 96 edges, and 24 vertices. As suggested by this configuration, the 24-cell is self-dual,
    //                     which establishes a projective relationship between vertices and cells, as well as edges and faces. Taken together, the vertices and cells of the 24-cell describe the 48 root vectors of the exceptional 
    //                     Lie group <b>F<sub>4</sub></b>.  If the vertices of the 24-cell are taken to lie on the unit 3-sphere, <MathJax inline>{'$S^3$'}</MathJax>, they can be represented as the elements of 
    //                     the <a className='text-color-tertiary' href='https://people.maths.bris.ac.uk/~matyd/GroupNames/1/SL(2,3).html'>Binary Tetrahedral Group</a>.  Projecting the remaining elements of the 24-cell 
    //                     onto <MathJax inline>{'$S^3$'}</MathJax> gives a regular tessellation of <MathJax inline>{'$S^3$'}</MathJax>,
    //                     and the vertices together with the cells (or dual vertices if you prefer) can be represented by the <a className='text-color-tertiary' href='https://people.maths.bris.ac.uk/~matyd/GroupNames/1/CSU(2,3).html'>Binary Octahedral Group</a>.
    //                   </p>
    //                 </div>
    //               </article>
    //             </MathJaxContext>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    // </div>
  );
}
export default Post2