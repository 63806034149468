import React, { useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls }  from "@react-three/drei";
import { BufferAttribute, DoubleSide } from 'three';
import { add, subtract, multiply, sqrt, pow, cos, sin, pi } from 'mathjs';
import { Leva, useControls } from 'leva';

function Cell24Mesh() {
  let meshRef = useRef()
  let meshWireRef = useRef()
  let positions = useRef()
  let normals = useRef()
  let colors = useRef()

  let verts1 = useRef()
  let verts2 = useRef()
  let verts3 = useRef()

  const { alpha, beta, ring1, ring2, ring3, ring4, showLayer1, showLayer2, showLayer3, opacity } = useControls({
    alpha: { value: 1/2, min: -2, max: 2, step: 0.001 },
    beta:  { value: 1/2, min: -.999, max: 0.999, step: 0.001 },
    ring1: { value: 1/2, min: 0, max: 4, step: 0.01 },
    ring2: { value: 1, min: 0, max: 4, step: 0.01 },
    ring3: { value: 3/2, min: 0, max: 4, step: 0.01 },
    ring4: { value: 2, min: 0, max: 4, step: 0.01 },
    showLayer1: true,
    showLayer2: true,
    showLayer3: true,
    opacity: { value: 0.25, min: 0.0, max: 1.0, step: 0.01 },
    
  });

  // let alpha = 1/2
  // let beta = 1/2
  let p3 = sqrt(1-beta*beta);
  let p1 = cos(2*pi*alpha)/(p3);
  let p2 = sin(2*pi*alpha)/(p3);

  let m=sqrt(p3*p3+p1*p1+p2*p2)
  p1=p1/m
  p2=p2/m
  p3=p3/m

  let rp = sqrt(2+2*p3)
  let rm = sqrt(2-2*p3)
  let r = sqrt(1-pow(p3,2)) // = rp*rm/2
  // let sq2 = sqrt(2)
  // let t = (sqrt(5)+1)/2

  //scaling as proxy for scalar component
  let pw = ring1*2/sqrt(3)
  let pt = ring2
  let pwm = ring3*2/sqrt(3)
  let pim = ring4

/* #region coordinates */
  //p0=1
  let id = [0,0,0]

  //p0=0
  let x1 = [p1/rp, -p2/rp, rp/2]
  let x2 = [-p1/rm, p2/rm, rm/2]
  let x3 = [-p2/r, -p1/r,  0]
  let t1 = multiply(x1,pt)
  let t2 = multiply(x2,pt)
  let t3 = multiply(x3,pt)

  let x1m = multiply(x1,-1)
  let x2m = multiply(x2,-1)
  let x3m = multiply(x3,-1)
  let t1p = multiply(x1m,pt)
  let t2p = multiply(x2m,pt)
  let t3p = multiply(x3m,pt)

  //p0=1/2
  let w1 = multiply(add(x1,add(x2,x3)),pw/2)
  let w2 = multiply(add(x1,add(x2,x3m)),pw/2)
  let w3 = multiply(add(x1,add(x2m,x3)),pw/2)
  let w4 = multiply(add(x1m,add(x2,x3)),pw/2)

  let w1p = multiply(add(x1m,add(x2m,x3m)),pw/2)
  let w2p = multiply(add(x1m,add(x2m,x3)),pw/2)
  let w3p = multiply(add(x1m,add(x2,x3m)),pw/2)
  let w4p = multiply(add(x1,add(x2m,x3m)),pw/2)

  //p0 =-1/2
  let w1m = multiply(w1,-pwm)
  let w2m = multiply(w2,-pwm)
  let w3m = multiply(w3,-pwm)
  let w4m = multiply(w4,-pwm)

  let w1pm = multiply(w1p,-pwm)
  let w2pm = multiply(w2p,-pwm)
  let w3pm = multiply(w3p,-pwm)
  let w4pm = multiply(w4p,-pwm)

  //p0 =-1
  let id1m = multiply(x1,pim)
  let id2m = multiply(x2,pim)
  let id3m = multiply(x3,pim)
  let id1pm = multiply(x1m,pim)
  let id2pm = multiply(x2m,pim)
  let id3pm = multiply(x3m,pim)
  /* #endregion */
/* #region face-normals */
  /* #region i-faces */
  let i_1 = multiply(add(id, add(w1,  w2)),1/3)
  let i_2 = multiply(add(id, add(w3,  w1)),1/3)
  let i_3 = multiply(add(id, add(w4p, w3)),1/3)
  let i_4 = multiply(add(id, add(w2,  w4p)),1/3)
  let i_5 = multiply(add(id, add(w1,  w4)),1/3)
  let i_6 = multiply(add(id, add(w4,  w3p)),1/3)
  let i_7 = multiply(add(id, add(w3p, w2)),1/3)
  let i_8 = multiply(add(id, add(w3,  w2p)),1/3)
  let i_9 = multiply(add(id, add(w2p, w4)),1/3)
  let i_10 =multiply(add(id, add(w4p, w1p)),1/3)
  let i_11 =multiply(add(id, add(w1p, w2p)),1/3)
  let i_12 =multiply(add(id, add(w3p, w1p)),1/3)
  /* #endregion */
  /* #region t1-faces */
  let t1_1 =  multiply(add(t1, add(w2,  w1)),1/3)
  let t1_2 =  multiply(add(t1, add(w1,  w3)),1/3)
  let t1_3 =  multiply(add(t1, add(w3,  w4p)),1/3)
  let t1_4 =  multiply(add(t1, add(w4p, w2)),1/3)
  let t1_5 =  multiply(add(t1, add(w1pm,w1)),1/3)
  let t1_6 =  multiply(add(t1, add(w2pm,w1pm)),1/3)
  let t1_7 =  multiply(add(t1, add(w2,  w2pm)),1/3)
  let t1_8 =  multiply(add(t1, add(w3pm,w3)),1/3)
  let t1_9 =  multiply(add(t1, add(w1pm,w3pm)),1/3)
  let t1_10=  multiply(add(t1, add(w4m, w4p)),1/3)
  let t1_11=  multiply(add(t1, add(w3pm,w4m)),1/3)
  let t1_12=  multiply(add(t1, add(w2pm,w4m)),1/3)
  /* #endregion */
  /* #region t1p-faces */
  let t1p_1 =  multiply(add(t1p, add(w4,  w3p)),1/3)
  let t1p_2 =  multiply(add(t1p, add(w3p, w1p)),1/3)
  let t1p_3 =  multiply(add(t1p, add(w1p, w2p)),1/3)
  let t1p_4 =  multiply(add(t1p, add(w2p, w4)),1/3)
  let t1p_5 =  multiply(add(t1p, add(w4,  w4pm)),1/3)
  let t1p_6 =  multiply(add(t1p, add(w4pm,w3m)),1/3)
  let t1p_7 =  multiply(add(t1p, add(w3m, w3p)),1/3)
  let t1p_8 =  multiply(add(t1p, add(w1p, w1m)),1/3)
  let t1p_9 =  multiply(add(t1p, add(w1m, w2m)),1/3)
  let t1p_10 = multiply(add(t1p, add(w2m, w2p)),1/3)
  let t1p_11 = multiply(add(t1p, add(w3m, w1m)),1/3)
  let t1p_12 = multiply(add(t1p, add(w2m, w4pm)),1/3)
  /* #endregion */
  /* #region t2-faces */
  let t2_1 = multiply(add(t2, add(w1,  w2)),1/3)
  let t2_2 = multiply(add(t2, add(w2,  w3p)),1/3)
  let t2_3 = multiply(add(t2, add(w3p, w4)),1/3)
  let t2_4 = multiply(add(t2, add(w4,  w1)),1/3)
  let t2_5 = multiply(add(t2, add(w1pm,w1)),1/3)
  let t2_6 = multiply(add(t2, add(w2pm,w1pm)),1/3)
  let t2_7 = multiply(add(t2, add(w2,  w2pm)),1/3)
  let t2_8 = multiply(add(t2, add(w4pm,w4)),1/3)
  let t2_9 = multiply(add(t2, add(w3m, w4pm)),1/3)
  let t2_10 =multiply(add(t2, add(w3p, w3m)),1/3)
  let t2_11 =multiply(add(t2, add(w4pm,w1pm)),1/3)
  let t2_12 =multiply(add(t2, add(w2pm,w3m)),1/3)
  /* #endregion */
  /* #region t2p-faces */
  let t2p_1 = multiply(add(t2p,add(w4p, w3)),1/3)
  let t2p_2 = multiply(add(t2p,add(w1p, w4p)),1/3)
  let t2p_3 = multiply(add(t2p,add(w2p, w1p)),1/3)
  let t2p_4 = multiply(add(t2p,add(w3,  w2p)),1/3)
  let t2p_5 = multiply(add(t2p,add(w4p, w4m)),1/3)
  let t2p_6 = multiply(add(t2p,add(w4m, w3pm)),1/3)
  let t2p_7 = multiply(add(t2p,add(w3pm,w3)),1/3)
  let t2p_8 = multiply(add(t2p,add(w1m, w1p)),1/3)
  let t2p_9 = multiply(add(t2p,add(w2m, w1m)),1/3)
  let t2p_10 =multiply(add(t2p,add(w2p, w2m)),1/3)
  let t2p_11 =multiply(add(t2p,add(w1m, w4m)),1/3)
  let t2p_12 =multiply(add(t2p,add(w2m, w3pm)),1/3)
  /* #endregion */
  /* #region t3-faces */
  let t3_1 = multiply(add(t3, add(w3,  w1)),1/3)
  let t3_2 = multiply(add(t3, add(w2p, w3)),1/3)
  let t3_3 = multiply(add(t3, add(w4,  w2p)),1/3)
  let t3_4 = multiply(add(t3, add(w1,  w4)),1/3)
  let t3_5 = multiply(add(t3, add(w3,  w3pm)),1/3)
  let t3_6 = multiply(add(t3, add(w3pm,w1pm)),1/3)
  let t3_7 = multiply(add(t3, add(w1pm,w1)),1/3)
  let t3_8 = multiply(add(t3, add(w2m, w2p)),1/3)
  let t3_9 = multiply(add(t3, add(w4pm,w2m)),1/3)
  let t3_10 =multiply(add(t3, add(w4,  w4pm)),1/3)
  let t3_11 =multiply(add(t3, add(w1pm,w4pm)),1/3)
  let t3_12 =multiply(add(t3, add(w2m,w3pm)),1/3)
  /* #endregion */
  /* #region t3p-faces */
  let t3p_1 = multiply(add(t3p,add(w2,  w4p)),1/3)
  let t3p_2 = multiply(add(t3p,add(w4p, w1p)),1/3)
  let t3p_3 = multiply(add(t3p,add(w1p, w3p)),1/3)
  let t3p_4 = multiply(add(t3p,add(w3p, w2)),1/3)
  let t3p_5 = multiply(add(t3p,add(w2,  w2pm)),1/3)
  let t3p_6 = multiply(add(t3p,add(w2pm,w4m)),1/3)
  let t3p_7 = multiply(add(t3p,add(w4m, w4p)),1/3)
  let t3p_8 = multiply(add(t3p,add(w3m, w3p)),1/3)
  let t3p_9 = multiply(add(t3p,add(w1m, w3m)),1/3)
  let t3p_10 =multiply(add(t3p,add(w1m, w1p)),1/3)
  let t3p_11 =multiply(add(t3p,add(w3m, w2pm)),1/3)
  let t3p_12 =multiply(add(t3p,add(w4m, w1m)),1/3)
  /* #endregion */
  /* #region im-faces */
  let im1_1 = multiply(add(id1m,add(w2pm, w1pm)),1/3)
  let im1_2 = multiply(add(id1m,add(w1pm, w3pm)),1/3)
  let im1_3 = multiply(add(id1m,add(w3pm, w4m)),1/3)
  let im1_4 = multiply(add(id1m,add(w4m,  w2pm)),1/3)

  let im2_1 = multiply(add(id2m,add(w1pm, w2pm)),1/3)
  let im2_2 = multiply(add(id2m,add(w2pm, w3m)),1/3)
  let im2_3 = multiply(add(id2m,add(w3m,  w4pm)),1/3)
  let im2_4 = multiply(add(id2m,add(w4pm, w1pm)),1/3)

  let im3_1 = multiply(add(id3m,add(w3pm, w1pm)),1/3)
  let im3_2 = multiply(add(id3m,add(w2m,  w3pm)),1/3)
  let im3_3 = multiply(add(id3m,add(w4pm, w2m)),1/3)
  let im3_4 = multiply(add(id3m,add(w1pm, w4pm)),1/3)

  let ipm1_1 = multiply(add(id1pm,add(w4pm, w3m)),1/3)
  let ipm1_2 = multiply(add(id1pm,add(w3m,  w1m)),1/3)
  let ipm1_3 = multiply(add(id1pm,add(w1m,  w2m)),1/3)
  let ipm1_4 = multiply(add(id1pm,add(w2m,  w4pm)),1/3)

  let ipm2_1 = multiply(add(id2pm,add(w3pm, w4m)),1/3)
  let ipm2_2 = multiply(add(id2pm,add(w4m,  w1m)),1/3)
  let ipm2_3 = multiply(add(id2pm,add(w1m,  w2m)),1/3)
  let ipm2_4 = multiply(add(id2pm,add(w2m,  w3pm)),1/3)

  let ipm3_1 = multiply(add(id3pm,add(w2pm, w4m)),1/3)
  let ipm3_2 = multiply(add(id3pm,add(w4m,  w1m)),1/3)
  let ipm3_3 = multiply(add(id3pm,add(w1m,  w3m)),1/3)
  let ipm3_4 = multiply(add(id3pm,add(w3m,  w2pm)),1/3)

  /* #endregion */
/* #endregion */
/* #region faces */
  if (showLayer1) {
    verts1 = [
    /* #region cell_i_t1 */
      //face_i_1
      {pos: id, norm: i_1, },
      {pos: w1, norm: i_1, },
      {pos: w2, norm: i_1, },
      //face_i_2
      {pos: id, norm: i_2, },
      {pos: w3, norm: i_2, },
      {pos: w1, norm: i_2, },
      //face_i_3
      {pos: id, norm: i_3, },
      {pos: w4p, norm:i_3, },
      {pos: w3, norm: i_3, },
      //face_i_4
      {pos: id, norm: i_4, },
      {pos: w2, norm: i_4, },
      {pos: w4p, norm:i_4, },

      //face_t1_1
      {pos: t1, norm: t1_1, },
      {pos: w2, norm: t1_1, },
      {pos: w1, norm: t1_1, },
      //face_t1_2
      {pos: t1, norm: t1_2, },
      {pos: w1, norm: t1_2, },
      {pos: w3, norm: t1_2, },
      //face_t1_3
      {pos: t1, norm: t1_3, },
      {pos: w3, norm: t1_3, },
      {pos: w4p, norm:t1_3, },
      //face_t1_4
      {pos: t1, norm: t1_4, },
      {pos: w4p, norm:t1_4, },
      {pos: w2, norm: t1_4, },
    /* #endregion */
    /* #region cell_i_t2 */
      //face_i_1x
      {pos: id, norm: i_1, },
      {pos: w2, norm: i_1, },
      {pos: w1, norm: i_1, },
      //face_i_5
      {pos: id, norm: i_7, },
      {pos: w3p,norm: i_7, },
      {pos: w2, norm: i_7, },
      //face_i_6
      {pos: id, norm: i_6, },
      {pos: w4, norm: i_6, },
      {pos: w3p, norm:i_6, },
      //face_i_7
      {pos: id, norm: i_5, },
      {pos: w1,norm:  i_5, },
      {pos: w4, norm: i_5, },

      //face_t2_1
      {pos: t2, norm: t2_1, },
      {pos: w1, norm: t2_1, },
      {pos: w2, norm: t2_1, },
      //face_t2_2
      {pos: t2, norm: t2_2, },
      {pos: w2, norm: t2_2, },
      {pos: w3p, norm:t2_2, },
      //face_t2_3
      {pos: t2, norm: t2_3, },
      {pos: w3p,norm: t2_3, },
      {pos: w4, norm: t2_3, },
      //face_t2_4
      {pos: t2, norm: t2_4, },
      {pos: w4, norm: t2_4, },
      {pos: w1,norm:  t2_4, },
    /* #endregion */
    /* #region cell_i_t3 */
      //face_i_2x
      {pos: id, norm: i_2, },
      {pos: w1, norm: i_2, },
      {pos: w3, norm: i_2, },
      //face_i_8
      {pos: id, norm: i_8, },
      {pos: w3, norm: i_8, },
      {pos:w2p, norm: i_8, }, 
      //face_i_9
      {pos: id, norm: i_9, },
      {pos: w2p,norm: i_9, },
      {pos: w4, norm: i_9, },
      //face_i_5x
      {pos: id, norm: i_5, },
      {pos: w4, norm: i_5, },
      {pos: w1, norm: i_5, },

      //face_t3_1
      {pos: t3, norm: t3_1, },
      {pos: w3, norm: t3_1, },
      {pos: w1, norm: t3_1, },
      //face_t3_2
      {pos: t3, norm: t3_2, },
      {pos:w2p, norm: t3_2, },
      {pos: w3, norm: t3_2, },
      //face_t3_3
      {pos: t3, norm: t3_3, },
      {pos: w4, norm: t3_3, },
      {pos: w2p,norm: t3_3, },
      //face_t3_4
      {pos: t3, norm: t3_4, },
      {pos: w1, norm: t3_4, },
      {pos: w4, norm: t3_4, },
    /* #endregion */
    /* #region cell_i_t2p */
      //face_i_3x
      {pos: id, norm: i_3, },
      {pos: w3, norm: i_3, },
      {pos: w4p, norm:i_3, },
      //face_i_10
      {pos: id, norm: i_10, },
      {pos: w4p, norm:i_10, },
      {pos: w1p, norm:i_10, },
      //face_i_11
      {pos: id, norm: i_11, },
      {pos: w1p, norm:i_11, },
      {pos: w2p, norm:i_11, },
      //face_i_8x
      {pos: id, norm: i_8, },
      {pos: w2p, norm:i_8, },
      {pos: w3, norm: i_8, },

      //face_t2p_1
      {pos: t2p, norm:t2p_1, },
      {pos: w4p, norm:t2p_1, },
      {pos: w3, norm: t2p_1, },
      //face_t2p_2
      {pos: t2p, norm:t2p_2, },
      {pos: w1p, norm:t2p_2, },
      {pos: w4p, norm:t2p_2, },
      //face_t2p_3
      {pos: t2p, norm:t2p_3, },
      {pos: w2p, norm:t2p_3, },
      {pos: w1p, norm:t2p_3, },
      //face_t2p_4
      {pos: t2p, norm:t2p_4, },
      {pos: w3, norm: t2p_4, },
      {pos: w2p, norm:t2p_4, },
    /* #endregion */
    /* #region cell_i_t3p */
      //face_i_4x
      {pos: id,  norm: i_4, },
      {pos: w4p, norm: i_4, },
      {pos: w2,  norm: i_4, },
      //face_i_10x
      {pos: id,  norm: i_10, },
      {pos: w1p, norm: i_10, },
      {pos: w4p, norm: i_10, },
      //face_i_12
      {pos: id,  norm: i_12, },
      {pos: w3p, norm: i_12, },
      {pos: w1p, norm: i_12, },
      //face_i_7x
      {pos: id,  norm: i_7, },
      {pos: w2,  norm: i_7, },
      {pos: w3p, norm: i_7, },

      //face_t3p_1
      {pos: t3p, norm: t3p_1, },
      {pos: w2,  norm: t3p_1, },
      {pos: w4p, norm: t3p_1, },
      //face_t3p_2
      {pos: t3p, norm: t3p_2, },
      {pos: w4p, norm: t3p_2, },
      {pos: w1p, norm: t3p_2, },
      //face_t3p_3
      {pos: t3p, norm: t3p_3, },
      {pos: w1p, norm: t3p_3, },
      {pos: w3p, norm: t3p_3, },
      //face_t3p_4
      {pos: t3p, norm: t3p_4, },
      {pos: w3p, norm: t3p_4, },
      {pos: w2,  norm: t3p_4, },
      /* #endregion */
    /* #region cell_i_t1p */
      //face_i_6x
      {pos: id, norm: i_6, },
      {pos: w3p,norm: i_6, },
      {pos: w4, norm: i_6, },
      //face_i_12x
      {pos: id, norm: i_12, },
      {pos: w1p,norm: i_12, },
      {pos: w3p,norm: i_12, },
      //face_i_11x
      {pos: id, norm: i_11, },
      {pos: w2p,norm: i_11, },
      {pos: w1p,norm: i_11, },
      //face_i_9x
      {pos: id, norm: i_9, },
      {pos: w4, norm: i_9, },
      {pos: w2p,norm: i_9, },

      //face_t1p_1
      {pos: t1p,norm: t1p_1, },
      {pos: w4, norm: t1p_1, },
      {pos: w3p,norm: t1p_1, },
      //face_t1p_2
      {pos: t1p, norm:t1p_2, },
      {pos: w3p,norm: t1p_2, },
      {pos: w1p,norm: t1p_2, },
      //face_t1p_3
      {pos: t1p, norm:t1p_3, },
      {pos: w1p,norm: t1p_3, },
      {pos: w2p,norm: t1p_3, },
      //face_t1p_4
      {pos: t1p, norm:t1p_4, },
      {pos: w2p,norm: t1p_4, },
      {pos: w4, norm: t1p_4, },
    ]
  } else {
    verts1 = []
  }
  if (showLayer2) {
    verts2 = [
    /* #endregion */
    /* #region cell_t1_t2 */
      //face_t1_1x
      {pos: t1, norm: t1_1, },
      {pos: w1, norm: t1_1, },
      {pos: w2, norm: t1_1, },
      //face_t1_5
      {pos: t1, norm: t1_5, },
      {pos: w1pm,norm:t1_5, },
      {pos: w1, norm: t1_5, },
      //face_t1_6
      {pos: t1, norm: t1_6, },
      {pos: w2pm,norm:t1_6, },
      {pos: w1pm,norm:t1_6, },
      //face_t1_7
      {pos: t1, norm: t1_7, },
      {pos: w2, norm: t1_7, },
      {pos: w2pm,norm:t1_7, },

      //face_t2_1x
      {pos: t2, norm: t2_1, },
      {pos: w2, norm: t2_1, },
      {pos: w1, norm: t2_1, },
      //face_t2_5
      {pos: t2, norm: t2_5, },
      {pos: w1, norm: t2_5, },
      {pos: w1pm,norm:t2_5, },
      //face_t2_6
      {pos: t2, norm: t2_6, },
      {pos: w1pm,norm:t2_6, },
      {pos: w2pm,norm:t2_6, },
      //face_t2_7
      {pos: t2, norm: t2_7, },
      {pos: w2pm,norm:t2_7, },
      {pos: w2, norm: t2_7, },
    /* #endregion */
    /* #region cell_t1_t3 */
      //face_t1_2x
      {pos: t1, norm: t1_2, },
      {pos: w3, norm: t1_2, },
      {pos: w1, norm: t1_2, },
      //face_t1_8
      {pos: t1,  norm: t1_8, },
      {pos: w3pm,norm: t1_8, },
      {pos: w3,  norm: t1_8, },
      //face_t1_9
      {pos: t1,  norm: t1_9, },
      {pos: w1pm,norm: t1_9, },
      {pos: w3pm,norm: t1_9, },
      //face_t1_5x
      {pos: t1,  norm: t1_5, },
      {pos: w1,  norm: t1_5, },
      {pos: w1pm,norm: t1_5, },

      //face_t3_1x
      {pos: t3, norm: t3_1, },
      {pos: w1, norm: t3_1, },
      {pos: w3, norm: t3_1, },
      //face_t3_5
      {pos: t3,  norm: t3_5, },
      {pos: w3,  norm: t3_5, },
      {pos: w3pm,norm: t3_5, },
      //face_t3_6
      {pos: t3,  norm: t3_6, },
      {pos: w3pm,norm: t3_6, },
      {pos: w1pm,norm: t3_6, },
      //face_t3_7
      {pos: t3,  norm: t3_7, },
      {pos: w1pm,norm: t3_7, },
      {pos: w1,  norm: t3_7, },
    /* #endregion */
    /* #region cell_t1_t2p */
      //face_t1_3x
      {pos: t1,  norm: t1_3, },
      {pos: w4p, norm: t1_3, },
      {pos: w3,  norm: t1_3, },
      //face_t1_10
      {pos: t1,  norm: t1_10, },
      {pos: w4m, norm: t1_10, },
      {pos: w4p, norm: t1_10, },
      //face_t1_11
      {pos: t1,  norm: t1_11, },
      {pos: w3pm,norm: t1_11, },
      {pos: w4m, norm: t1_11, },
      //face_t1_8x
      {pos: t1,  norm: t1_8, },
      {pos: w3,  norm: t1_8, },
      {pos: w3pm,norm: t1_8, },
    
      //face_t2p_1x
      {pos: t2p, norm: t2p_1, },
      {pos: w3,  norm: t2p_1, },
      {pos: w4p, norm: t2p_1, },
      //face_t2p_5
      {pos: t2p, norm: t2p_5, },
      {pos: w4p, norm: t2p_5, },
      {pos: w4m, norm: t2p_5, },
      //face_t2p_6
      {pos: t2p, norm: t2p_6, },
      {pos: w4m, norm: t2p_6, },
      {pos: w3pm,norm: t2p_6, },
      //face_t2p_7
      {pos: t2p, norm: t2p_7, },
      {pos: w3pm,norm: t2p_7, },
      {pos: w3,  norm: t2p_7, },
    /* #endregion */
    /* #region cell_t1_t3p */
      //face_t1_4x
      {pos: t1,  norm: t1_4, },
      {pos: w2,  norm: t1_4, },
      {pos: w4p, norm: t1_4, },
      //face_t1_7x
      {pos: t1,  norm: t1_7, },
      {pos: w2pm,norm: t1_7, },
      {pos: w2,  norm: t1_7, },
      //face_t1_12
      {pos: t1,  norm: t1_12, },
      {pos: w4m, norm: t1_12, },
      {pos: w2pm,norm: t1_12, },
      //face_t1_10x
      {pos: t1,  norm: t1_10, },
      {pos: w4p,  norm:t1_10, },
      {pos: w4m, norm: t1_10, },
      
      //face_t3p_1x
      {pos: t3p, norm: t3p_1, },
      {pos: w4p, norm: t3p_1, },
      {pos: w2,  norm: t3p_1, },
      //face_t3p_5
      {pos: t3p, norm: t3p_5, },
      {pos: w2,  norm: t3p_5, },
      {pos: w2pm,norm: t3p_5, },
      //face_t3p_6
      {pos: t3p, norm: t3p_6, },
      {pos: w2pm,norm: t3p_6, },
      {pos: w4m, norm: t3p_6, },
      //face_t3p_7
      {pos: t3p, norm: t3p_7, },
      {pos: w4m, norm: t3p_7, },
      {pos: w4p, norm: t3p_7, },
    /* #endregion */
    /* #region cell_t1p_t2 */
      //face_t1p_1x
      {pos: t1p, norm: t1p_1, },
      {pos: w3p, norm: t1p_1, },
      {pos: w4,  norm: t1p_1, },
      //face_t1p_5
      {pos: t1p, norm: t1p_5, },
      {pos: w4,  norm: t1p_5, },
      {pos: w4pm,norm: t1p_5, },
      //face_t1p_6
      {pos: t1p, norm: t1p_6, },
      {pos: w4pm,norm: t1p_6, },
      {pos: w3m, norm: t1p_6, },
      //face_t1p_7
      {pos: t1p, norm: t1p_7, },
      {pos: w3m, norm: t1p_7, },
      {pos: w3p, norm: t1p_7, },
    
      //face_t2_3x
      {pos: t2,  norm: t2_3, },
      {pos: w4,  norm: t2_3, },
      {pos: w3p, norm: t2_3, },
      //face_t2_8
      {pos: t2,  norm: t2_8, },
      {pos: w4pm,norm: t2_8, },
      {pos: w4,  norm: t2_8, },
      //face_t2_9
      {pos: t2,  norm: t2_9, },
      {pos: w3m, norm: t2_9, },
      {pos: w4pm,norm: t2_9, },
      //face_t2_10
      {pos: t2,  norm: t2_10, },
      {pos: w3p, norm: t2_10, },
      {pos: w3m, norm: t2_10, },  
    /* #endregion */
    /* #region cell_t1p_t2p */
      //face_t1p_3x
      {pos: t1p, norm: t1p_3, },
      {pos: w2p, norm: t1p_3, },
      {pos: w1p, norm: t1p_3, },
      //face_t1p_8
      {pos: t1p, norm: t1p_8, },
      {pos: w1p, norm: t1p_8, },
      {pos: w1m, norm:  t1p_8, },
      //face_t1p_9
      {pos: t1p, norm: t1p_9, },
      {pos: w1m, norm: t1p_9, },
      {pos: w2m, norm: t1p_9, },
      //face_t1p_10
      {pos: t1p, norm: t1p_10, },
      {pos: w2m, norm: t1p_10, },
      {pos: w2p, norm: t1p_10, },

      //face_t2p_3x
      {pos: t2p, norm: t2p_3, },
      {pos: w1p, norm: t2p_3, },
      {pos: w2p, norm: t2p_3, },
      //face_t2p_8
      {pos: t2p, norm: t2p_8, },
      {pos: w1m, norm: t2p_8, },
      {pos: w1p, norm: t2p_8, },
      //face_t2p_9
      {pos: t2p, norm: t2p_9, },
      {pos: w2m, norm: t2p_9, },
      {pos: w1m, norm: t2p_9, },
      //face_t2p_10
      {pos: t2p, norm: t2p_10, },
      {pos: w2p, norm: t2p_10, },
      {pos: w2m, norm: t2p_10, },
    /* #endregion */
    /* #region cell_t1p_t3p */
      //face_t1p_2x
      {pos: t1p, norm: t1p_2, },
      {pos: w1p, norm: t1p_2, },
      {pos: w3p, norm: t1p_2, },
      //face_t1p_7x
      {pos: t1p, norm: t1p_7, },
      {pos: w3p, norm: t1p_7, },
      {pos: w3m, norm: t1p_7, },
      //face_t1p_11
      {pos: t1p, norm: t1p_11, },
      {pos: w3m, norm: t1p_11, },
      {pos: w1m, norm: t1p_11, },
      //face_t1p_8x
      {pos: t1p, norm: t1p_8, },
      {pos: w1m, norm: t1p_8, },
      {pos: w1p, norm: t1p_8, },
      
      //face_t3p_3x
      {pos: t3p, norm: t3p_3, },
      {pos: w3p, norm: t3p_3, },
      {pos: w1p, norm: t3p_3, },
      //face_t3p_8
      {pos: t3p, norm: t3p_8, },
      {pos: w3m, norm: t3p_8, },
      {pos: w3p, norm: t3p_8, },
      //face_t3p_9
      {pos: t3p, norm: t3p_9, },
      {pos: w1m, norm: t3p_9, },
      {pos: w3m, norm: t3p_9, },
      //face_t3p_10
      {pos: t3p, norm: t3p_10, },
      {pos: w1p, norm: t3p_10, },
      {pos: w1m, norm: t3p_10, },
    /* #endregion */
    /* #region cell_t1p_t3 */
      //face_t1p_4x
      {pos: t1p, norm: t1p_4, },
      {pos: w4,  norm: t1p_4, },
      {pos: w2p, norm: t1p_4, },
      //face_t1p_10x
      {pos: t1p, norm: t1p_10, },
      {pos: w2p, norm: t1p_10, },
      {pos: w2m, norm: t1p_10, },
      //face_t1p_12
      {pos: t1p, norm: t1p_12, },
      {pos: w2m, norm: t1p_12, },
      {pos: w4pm,norm: t1p_12, },
      //face_t1p_5x
      {pos: t1p, norm: t1p_5, },
      {pos: w4pm,norm: t1p_5, },
      {pos: w4,  norm: t1p_5, },
      
      //face_t3_3x
      {pos: t3, norm:  t3_3, },
      {pos: w2p, norm: t3_3, },
      {pos: w4,  norm: t3_3, },
      //face_t3_8
      {pos: t3,  norm: t3_8, },
      {pos: w2m, norm: t3_8, },
      {pos: w2p, norm: t3_8, },
      //face_t3_9
      {pos: t3,  norm: t3_9, },
      {pos: w4pm,norm: t3_9, },
      {pos: w2m, norm: t3_9, },
      //face_t3_10
      {pos: t3,  norm: t3_10, },
      {pos: w4,  norm: t3_10, },
      {pos: w4pm,norm: t3_10, },
    /* #endregion */
    /* #region cell_t2_t3 */
      //face_t2_4x
      {pos: t2,  norm: t2_4, },
      {pos: w1,  norm: t2_4, },
      {pos: w4,  norm: t2_4, },
      //face_t2_5x
      {pos: t2,  norm: t2_5, },
      {pos: w1pm,norm: t2_5, },
      {pos: w1,  norm: t2_5, },
      //face_t2_11
      {pos: t2,  norm: t2_11, },
      {pos: w4pm,norm: t2_11, },
      {pos: w1pm,norm: t2_11, },
      //face_t2_8x
      {pos: t2,  norm: t2_8, },
      {pos: w4,  norm: t2_8, },
      {pos: w4pm,norm: t2_8, },

      //face_t3_4x
      {pos: t3,  norm: t3_4, },
      {pos: w4,  norm: t3_4, },
      {pos: w1,  norm: t3_4, },
      //face_t3_7x
      {pos: t3,  norm: t3_7, },
      {pos: w1,  norm: t3_7, },
      {pos: w1pm,norm: t3_7, },
      //face_t3_11
      {pos: t3,  norm: t3_11, },
      {pos: w1pm,norm: t3_11, },
      {pos: w4pm,norm: t3_11, },
      //face_t3_10x
      {pos: t3,  norm: t3_10, },
      {pos: w4pm,norm: t3_10, },
      {pos: w4,  norm: t3_10, },
    /* #endregion */  
    /* #region cell_t2_t3p */
      //face_t2_2x
      {pos: t2,  norm: t2_2, },
      {pos: w3p, norm: t2_2, },
      {pos: w2,  norm: t2_2, },
      //face_t2_10x
      {pos: t2,  norm: t2_10, },
      {pos: w3m, norm: t2_10, },
      {pos: w3p, norm: t2_10, },
      //face_t2_12
      {pos: t2,  norm: t2_12, },
      {pos: w2pm,norm: t2_12, },
      {pos: w3m, norm: t2_12, },
      //face_t2_7x
      {pos: t2,  norm: t2_7, },
      {pos: w2,  norm: t2_7, },
      {pos: w2pm,norm: t2_7, },

      //face_t3p_4x
      {pos: t3p, norm: t3p_4, },
      {pos: w2,  norm: t3p_4, },
      {pos: w3p, norm: t3p_4, },
      //face_t3p_8x
      {pos: t3p, norm: t3p_8, },
      {pos: w3p, norm: t3p_8, },
      {pos: w3m, norm: t3p_8, },
      //face_t3p_11
      {pos: t3p, norm: t3p_11, },
      {pos: w3m, norm: t3p_11, },
      {pos: w2pm,norm: t3p_11, },
      //face_t3p_5x
      {pos: t3p, norm: t3p_5, },
      {pos: w2pm,norm: t3p_5, },
      {pos: w2,  norm: t3p_5, },
    /* #endregion */
    /* #region cell_t2p_t3p */
      //face_t2p_2x
      {pos: t2p, norm: t2p_2, },
      {pos: w4p, norm: t2p_2, },
      {pos: w1p, norm: t2p_2, },
      //face_t2p_8x
      {pos: t2p, norm: t2p_8, },
      {pos: w1p, norm: t2p_8, },
      {pos: w1m, norm: t2p_8, },
      //face_t2p_11
      {pos: t2p, norm: t2p_11, },
      {pos: w1m, norm: t2p_11, },
      {pos: w4m, norm: t2p_11, },
      //face_t2p_5x
      {pos: t2p, norm: t2p_5, },
      {pos: w4m, norm: t2p_5, },
      {pos: w4p, norm: t2p_5, },
      
      //face_t3p_2x
      {pos: t3p, norm: t3p_2, },
      {pos: w1p, norm: t3p_2, },
      {pos: w4p, norm: t3p_2, },
      //face_t3p_10x
      {pos: t3p, norm: t3p_10, },
      {pos: w1m, norm: t3p_10, },
      {pos: w1p, norm: t3p_10, },
      //face_t3p_12
      {pos: t3p, norm: t3p_12, },
      {pos: w4m, norm: t3p_12, },
      {pos: w1m, norm: t3p_12, },
      //face_t3p_7x
      {pos: t3p, norm: t3p_7, },
      {pos: w4p, norm: t3p_7, },
      {pos: w4m, norm: t3p_7, },
    /* #endregion */ 
    /* #region cell_t2p_t3 */
      //face_t2p_4x
      {pos: t2p, norm: t2p_4, },
      {pos: w2p, norm: t2p_4, },
      {pos: w3,  norm: t2p_4, },
      //face_t2p_7x
      {pos: t2p, norm: t2p_7, },
      {pos: w3,  norm: t2p_7, },
      {pos: w3pm,norm: t2p_7, },
      //face_t2p_12
      {pos: t2p, norm: t2p_12, },
      {pos: w3pm,norm: t2p_12, },
      {pos: w2m, norm: t2p_12, },
      //face_t2p_10x
      {pos: t2p, norm: t2p_10, },
      {pos: w2m, norm: t2p_10, },
      {pos: w2p, norm: t2p_10, },
      
      //face_t3_2x
      {pos: t3,  norm: t3_2, },
      {pos: w3,  norm: t3_2, },
      {pos: w2p, norm: t3_2, },
      //face_t3_5x
      {pos: t3,  norm: t3_5, },
      {pos: w3pm,norm: t3_5, },
      {pos: w3,  norm: t3_5, },
      //face_t3_12
      {pos: t3,  norm: t3_12, },
      {pos: w2m, norm: t3_12, },
      {pos: w3pm,norm: t3_12, },
      //face_t3_8x
      {pos: t3,  norm: t3_8, },
      {pos: w2p, norm: t3_8, },
      {pos: w2m, norm: t3_8, },
    ]
  } else {
    verts2 = []
  }
  /* #endregion */
  if (showLayer3) {
    verts3 = [
    /* #region cell_id1m_t1 */
      //face_im1_1
      {pos: id1m, norm: im1_1, },
      {pos: w2pm, norm: im1_1, },
      {pos: w1pm, norm: im1_1, },
      //face_im1_2
      {pos: id1m, norm: im1_2, },
      {pos: w1pm, norm: im1_2, },
      {pos: w3pm, norm: im1_2, },
      //face_im1_3
      {pos: id1m, norm: im1_3, },
      {pos: w3pm, norm: im1_3, },
      {pos: w4m, norm:  im1_3, },
      //face_im1_4
      {pos: id1m, norm: im1_4, },
      {pos: w4m,  norm: im1_4, },
      {pos: w2pm, norm: im1_4, },
      
      //face_t1_6x
      {pos: t1,  norm: t1_6, },
      {pos: w1pm,norm: t1_6, },
      {pos: w2pm,norm: t1_6, },
      //face_t1_9x
      {pos: t1,  norm: t1_9, },
      {pos: w3pm,norm: t1_9, },
      {pos: w1pm,norm: t1_9, },
      //face_t1_11x
      {pos: t1, norm:  t1_11, },
      {pos: w4m, norm: t1_11, },
      {pos: w3pm, norm:t1_11, },
      //face_t1_12x
      {pos: t1,  norm: t1_12, },
      {pos: w2pm,norm: t1_12, },
      {pos: w4m, norm: t1_12, },
    /* #endregion */
    /* #region cell_id2m_t2 */
      //face_im2_1
      {pos: id2m, norm: im2_1, },
      {pos: w1pm, norm: im2_1, },
      {pos: w2pm, norm: im2_1, },
      //face_im2_2
      {pos: id2m, norm: im2_2, },
      {pos: w2pm, norm: im2_2, },
      {pos: w3m,  norm: im2_2, },
      //face_im2_3
      {pos: id2m, norm: im2_3, },
      {pos: w3m,  norm: im2_3, },
      {pos: w4pm, norm: im2_3, },
      //face_im2_4
      {pos: id2m, norm: im2_4, },
      {pos: w4pm, norm: im2_4, },
      {pos: w1pm, norm: im2_4, },
      
      //face_t2_6x
      {pos: t2,  norm: t2_6, },
      {pos: w2pm,norm: t2_6, },
      {pos: w1pm,norm: t2_6, },
      //face_t2_12x
      {pos: t2,  norm: t2_12, },
      {pos: w3m, norm: t2_12, },
      {pos: w2pm,norm: t2_12, },
      //face_t2_9x
      {pos: t2,  norm: t2_9, },
      {pos: w4pm,norm: t2_9, },
      {pos: w3m, norm: t2_9, },
      //face_t2_11x
      {pos: t2,  norm: t2_11, },
      {pos: w1pm,norm: t2_11, },
      {pos: w4pm,norm: t2_11, },
    /* #endregion */
    /* #region cell_id3m_t3 */
      //face_im3_1
      {pos: id3m, norm: im3_1, },
      {pos: w3pm, norm: im3_1, },
      {pos: w1pm, norm: im3_1, },
      //face_im3_2
      {pos: id3m, norm: im3_2, },
      {pos: w2m,  norm: im3_2, }, 
      {pos: w3pm, norm: im3_2, },
      //face_im3_3
      {pos: id3m, norm: im3_3, },
      {pos: w4pm, norm: im3_3, },
      {pos: w2m,  norm: im3_3, },
      //face_im3_4
      {pos: id3m, norm: im3_4, },
      {pos: w1pm, norm: im3_4, },
      {pos: w4pm, norm: im3_4, },
      
      //face_t3_6x
      {pos: t3,  norm: t3_6, },
      {pos: w1pm,norm: t3_6, },
      {pos: w3pm,norm: t3_6, },
      //face_t3_2x
      {pos: t3, norm:  t3_12, },
      {pos: w3pm,norm: t3_12, },
      {pos: w2m,norm:  t3_12, },
      //face_t3_9x
      {pos: t3,  norm: t3_9, },
      {pos: w2m, norm: t3_9, },
      {pos: w4pm,norm: t3_9, },
      //face_t3_11x
      {pos: t3,  norm: t3_11, },
      {pos: w4pm,norm: t3_11, },
      {pos: w1pm,norm: t3_11, },
    /* #endregion */
    /* #region cell_id2pm_t2p */
      //face_ipm2_1
      {pos: id2pm,norm: ipm2_1, },
      {pos: w4m,  norm: ipm2_1, },
      {pos: w3pm, norm: ipm2_1, },
      //face_ipm2_2
      {pos: id2pm,norm: ipm2_2, },
      {pos: w1m,  norm: ipm2_2, },
      {pos: w4m,  norm: ipm2_2, },
      //face_ipm2_3
      {pos: id2pm,norm: ipm2_3, },
      {pos: w2m,  norm: ipm2_3, },
      {pos: w1m,  norm: ipm2_3, },
      //face_ipm2_4
      {pos: id2pm,norm: ipm2_4, },
      {pos: w3pm, norm: ipm2_4, },
      {pos: w2m,  norm: ipm2_4, },
      
      //face_t2p_6x
      {pos: t2p, norm: t2p_6, },
      {pos: w3pm,norm: t2p_6, },
      {pos: w4m, norm: t2p_6, },
      //face_t2p_11x
      {pos: t2p, norm: t2p_11, },
      {pos: w4m, norm: t2p_11, },
      {pos: w1m, norm: t2p_11, },
      //face_t2p_9x
      {pos: t2p, norm: t2p_9, },
      {pos: w1m, norm: t2p_9, },
      {pos: w2m, norm: t2p_9, },
      //face_t2p_4x
      {pos: t2p, norm: t2p_12, },
      {pos: w2m, norm: t2p_12, },
      {pos: w3pm,norm: t2p_12, },
    /* #endregion */
    /* #region cell_id3pm_t3p */
      //face_ipm3_1
      {pos: id3pm,norm: ipm3_1, },
      {pos: w2pm, norm: ipm3_1, },
      {pos: w4m,  norm: ipm3_1, },
      //face_ipm3_2
      {pos: id3pm,norm: ipm3_2, },
      {pos: w4m,  norm: ipm3_2, },
      {pos: w1m,  norm: ipm3_2, },
      //face_ipm3_3
      {pos: id3pm,norm: ipm3_3, },
      {pos: w1m,  norm: ipm3_3, },
      {pos: w3m,  norm: ipm3_3, },
      //face_ipm3_4
      {pos: id3pm,norm: ipm3_4, },
      {pos: w3m,  norm: ipm3_4, },
      {pos: w2pm, norm: ipm3_4, },
      
      //face_t3p_6x
      {pos: t3p, norm: t3p_6, },
      {pos: w4m, norm: t3p_6, },
      {pos: w2pm,norm: t3p_6, },
      //face_t3p_2x
      {pos: t3p, norm: t3p_12, },
      {pos: w1m, norm: t3p_12, },
      {pos: w4m, norm: t3p_12, },
      //face_t3p_9x
      {pos: t3p, norm: t3p_9, },
      {pos: w3m, norm: t3p_9, },
      {pos: w1m, norm: t3p_9, },
      //face_t3p_11x
      {pos: t3p, norm: t3p_11, },
      {pos: w2pm,norm: t3p_11, },
      {pos: w3m, norm: t3p_11, },
      /* #endregion */
    /* #region cell_id1pm_t1p */
      //face_ipm1_1
      {pos: id1pm,norm: ipm1_1, },
      {pos: w4pm, norm: ipm1_1, },
      {pos: w3m,  norm: ipm1_1, },
      //face_ipm1_2
      {pos: id1pm,norm: ipm1_2, },
      {pos: w3m,  norm: ipm1_2, },
      {pos: w1m,  norm: ipm1_2, },
      //face_ipm1_3
      {pos: id1pm,norm: ipm1_3, },
      {pos: w1m,  norm: ipm1_3, },
      {pos: w2m,  norm: ipm1_3, },
      //face_ipm1_4
      {pos: id1pm,norm: ipm1_4, },
      {pos: w2m,  norm: ipm1_4, },
      {pos: w4pm, norm: ipm1_4, },
      
      //face_t1p_
      {pos: t1p, norm: t1p_6, },
      {pos: w3m, norm: t1p_6, },
      {pos: w4pm,norm: t1p_6, },
      //face_t1p_11x
      {pos: t1p, norm: t1p_11, },
      {pos: w1m, norm: t1p_11, },
      {pos: w3m, norm: t1p_11, },
      //face_t1p_
      {pos: t1p, norm: t1p_9, },
      {pos: w2m, norm: t1p_9, },
      {pos: w1m, norm: t1p_9, },
      //face_t1p_
      {pos: t1p, norm: t1p_12, },
      {pos: w4pm,norm: t1p_12, },
      {pos: w2m, norm: t1p_12, },
    ]
  } else {
    verts3 = []
  }
  /* #endregion */
/* #endregion */
  
  let poss = []
  let norms = []
  let rgb1 = []
  for (const vertex of verts1) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(1)
    rgb1.push(0)
    rgb1.push(0)
  }
  for (const vertex of verts2) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(0)
    rgb1.push(1)
    rgb1.push(0)
  }
  for (const vertex of verts3) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(0)
    rgb1.push(0)
    rgb1.push(1)
  }

  positions.current = new Float32Array(poss)
  normals.current = new Float32Array(norms)
  colors.current = new Float32Array(rgb1)

  useEffect (() =>{
    meshRef.current.geometry.setAttribute('position', new BufferAttribute(positions.current, 3))
    meshRef.current.geometry.setAttribute('normal', new BufferAttribute(normals.current, 3))
    meshRef.current.geometry.setAttribute('color', new BufferAttribute(colors.current, 3))
    meshRef.needsUpdate=true
    meshWireRef.current.geometry.setAttribute('position', new BufferAttribute(positions.current, 3))
    meshWireRef.current.geometry.setAttribute('normal', new BufferAttribute(normals.current, 3))
    meshWireRef.current.geometry.setAttribute('color', new BufferAttribute(colors.current, 3))
    meshWireRef.needsUpdate=true
  },[colors.current,showLayer1,showLayer2,showLayer3]);

  return (
    <group>
      <mesh ref={meshRef} colors={colors} >
        <ambientLight />
        <pointLight position={[10,10,10]} />
        <meshPhongMaterial
            vertexColors
            transparent={true}
            opacity ={opacity}
            polygonOffset={true}
            polygonOffsetFactor={1}
            polygonOffsetUnits={1}
            side={DoubleSide}
        />
        <bufferGeometry >
          <bufferAttribute
            attach = "attributes-position" 
            array = {positions.current}
            count = {positions.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
          <bufferAttribute
            attach = "attributes-normal"
            array = {normals.current}
            count = {normals.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
          <bufferAttribute
            attach = "attributes-color"
            array = {colors.current}
            count = {colors.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
        </bufferGeometry >
      </mesh >
      <mesh ref={meshWireRef} colors={colors} >
        <ambientLight />
        <pointLight position={[10,10,10]} />
        <meshPhongMaterial
            vertexColors
            wireframe = {true}
        />
        <bufferGeometry >
          <bufferAttribute
            attach = "attributes-position" 
            array = {positions.current}
            count = {positions.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
          <bufferAttribute
            attach = "attributes-normal"
            array = {normals.current}
            count = {normals.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
          <bufferAttribute
            attach = "attributes-color"
            array = {colors.current}
            count = {colors.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
        </bufferGeometry >
      </mesh >
    </group>
  )
}

function Cell24() {
  return (
    <div style={{width: "100vw", height:"100vh"}} >
      <Leva />
      <Canvas style={{
        position: "fixed",
        width: "100%",
        height: "100%"
      }}>
        <Cell24Mesh />
        <OrbitControls />
      </Canvas>
    </div>
  )
}

export default Cell24