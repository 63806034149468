import React from "react";
import Home from "./Home";
import Footer from "./Footer";
import Post1 from "./Posts/Post1";
import Post2 from "./Posts/Post2";
import Post3 from "./Posts/Post3";
import {BrowserRouter, Route, Routes} from "react-router-dom";

function Main() {
  return (
    <BrowserRouter>
      <div role="main" className="main bg-color-dark-scale-1">
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/post1' element={<Post1 />} />
            <Route exact path='/post2' element={<Post2 />} />
            {/* <Route exact path='/post3' element={<Post3 />} /> */}
        </Routes>  
        <Footer />
      </div>
    </BrowserRouter>
  );
}
export default Main;