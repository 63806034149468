import {useControl, React} from "react";
import Head from "../Head";
import {MathJax, MathJaxContext} from "better-react-mathjax"
import Cell24 from "../Components/Cell24";
import Cell600 from "../Components/Cell600";
import BBox from "../Components/Test"

const Post1 = (props) => {
  const title = "Dynamic 24-Cell Projection"
  const date = "July 30, 2022"
  const quoteText = "Perhaps someone devoting their life to it could bring themselves to picture the fourth dimension."
  const quoteAuthor = "Henri Poincaré"
  const quoteLink = "https://www.biodiversitylibrary.org/page/10778548#page/784/mode/1up"


  const config = {
      loader: { load: ["[tex]/html"] },
      tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
      }
  };

  return (
    // <div className="post">
    //   <Head quoteText={quoteText} quoteLink={quoteLink} quoteAuthor={quoteAuthor} />
    //     <div className="container py-4">
    //       <div className="row">
    //         <div className="col">
    //           <div className="blog-posts single-post">
    //             <article className="post post-large blog-single-post border-0 m-0 p-0">
    //               <div className="post-content ms-0 text-color-grey">
    //                 <h2 className="font-weight-semi-bold text-color-tertiary">{title}</h2>
    //                 <div className="post-meta">
    //                   <span className="text-color-secondary">{date}</span>
    //                 </div>
                    <div >
                        <Cell24 />
                    </div>
    //                 <MathJaxContext config={config}>
    //                 <p className="text-color-primary">
    //                   In three dimensions there are five regular convex polyhedra: tetrahedron, octahedron, cube, icosahedron, dodecahedron, known from antiquity as the five Platonic solids. 
    //                   Each Platonic solid has an analogous regular convex polytope in four dimensions: 5-cell, 16-cell, 8-cell, 600-cell, 120-cell, respectively.  But the fourth dimensions holds
    //                   one more regular polytope with no analog in any other dimension, the 24-cell.  In any dimension greater than four there are only three regular convex polyhedra, the 
    //                   analogs of the tetrahedron, octahedron and cube.
    //                   After reading H.S.M. Coxeter's <i>Regular Polytopes</i> years ago, striving to hold a mental picture of the 24-cell
    //                   in my mind became a regular exercise.
    //                 </p>
    //                 <p className="text-color-primary">
    //                   Let's start with some basic facts about the 24-cell. It has 24 octahedral cells, 96 triangular faces, 96 edges, and 24 vertices. As suggested by this configuration, the 24-cell is self-dual,
    //                   which establishes a projective relationship between vertices and cells, as well as edges and faces. Taken together, the vertices and cells of the 24-cell describe the 48 root vectors of the exceptional 
    //                   Lie group <b>F<sub>4</sub></b>.  If the vertices of the 24-cell are taken to lie on the unit 3-sphere, <MathJax inline>{'$S^3$'}</MathJax>, they can be represented as the elements of 
    //                   the <a className='text-color-tertiary' href='https://people.maths.bris.ac.uk/~matyd/GroupNames/1/SL(2,3).html'>Binary Tetrahedral Group</a>.  Projecting the remaining elements of the 24-cell 
    //                   onto <MathJax inline>{'$S^3$'}</MathJax> gives a regular tessellation of <MathJax inline>{'$S^3$'}</MathJax>,
    //                   and the vertices together with the cells (or dual vertices if you prefer) can be represented by the <a className='text-color-tertiary' href='https://people.maths.bris.ac.uk/~matyd/GroupNames/1/CSU(2,3).html'>Binary Octahedral Group</a>.
    //                 </p>
    //                 <p className="text-color-primary">
    //                   Coherent indexing, only regular 4d polytope in which this is possible (Coxeter 151)
    //                   mod out the eigenvalue, so(4)=su(2)l+su(2)r
    //                   so(4)/gl(2,F3) rotations, su(2)/csu(2,F3) (Z8) spinor transformations
    //                   2d petrie polygon, i.e. dodecagonal and triacontagonal, projections of 24-cell and 600-cell (project such that T3 (1DOF) is on the boarder).
    //                   cube vertex figure of 24-cell easy to see, Schlegel diagram
    //                 </p>
    //                 </MathJaxContext>
    //               </div>
    //             </article>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    // </div>
  );
}
export default Post1