import React from 'react';
import { createRoot } from 'react-dom/client';
import Blog from './js/Blog';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/custom.css"
import "./css/skins/default.css"
import "./css/theme-blog.css"
import "./css/theme-elements.css"
import "./css/theme.css"

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Blog />
)
/* render(
    <Blog />,
    document.getElementById('root')
); */
