import React, { useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls }  from "@react-three/drei";
import { BufferAttribute, MaterialParameters, TwoPassDoubleSide, DoubleSide, Vector3 } from 'three';
import { add, subtract, multiply, sqrt, pow, cos, sin, pi } from 'mathjs';
import { folder, Leva, useControls } from 'leva';

function Cell600Mesh() {
  let meshRef = useRef()
  let meshWireRef = useRef()
  let positions = useRef()
  let normals = useRef()
  let colors = useRef()

  let verts1 = useRef()
  let verts2 = useRef()
  let verts3 = useRef()
  let verts4 = useRef()
  let verts5 = useRef()
  let verts6 = useRef()
  let verts7 = useRef()
  let verts8 = useRef()
  let verts9 = useRef()
  let verts10 = useRef()
  let verts11 = useRef()
  let verts12 = useRef()
  let verts13 = useRef()
  let verts14 = useRef()
  let verts15 = useRef()
  const t = (sqrt(5)+1)/2

  const { alpha, beta, ring1, ring2, ring3, ring4, ring5, ring6, 
    ring7, ring8 } = useControls('Projection Parameters', {
    alpha: { value: 1/2, min: -2, max: 2, step: 0.001 },
    beta:  { value: 1/2, min: -0.999, max: 0.999, step: 0.001 },
    ring1: { value: 0.25, min: 0.01, max: 4.0, step: 0.01 },
    ring2: { value: 0.5, min: 0.0, max: 4.0, step: 0.01 },
    ring3: { value: 0.75, min: 0.0, max: 4.0, step: 0.01 },
    ring4: { value: 1.0, min: 0.0, max: 4.0, step: 0.01 },
    ring5: { value: 1.25, min: 0.0, max: 4.0, step: 0.01 },
    ring6: { value: 1.5, min: 0.0, max: 4.0, step: 0.01 },
    ring7: { value: 1.75, min: 0.0, max: 4.0, step: 0.01 },
    ring8: { value: 2.0, min: 0.0, max: 4.0, step: 0.01 },
  },
  { collapsed: true });

  const { layer1, layer2, layer3, layer4, layer5, layer6,
    layer7, layer8, layer9, layer10, layer11, layer12,
    layer13, layer14, layer15} = useControls('Show/Hide Layers', {
    layer1: true,
    layer2: true,
    layer3: true,
    layer4: true,
    layer5: true,
    layer6: true,
    layer7: true,
    layer8: true,
    layer9: true,
    layer10: true,
    layer11: true,
    layer12: true,
    layer13: true,
    layer14: true,
    layer15: true,
  },
  { collapsed: true });

  const { opacity, linewidth, color1, color2, color3, color4, color5, color6, color7,
    color8, color9, color10, color11, color12, color13, color14, color15 
    } = useControls('Layer Colors', {
    opacity: { value: 0.15, min: 0.0, max: 1.0, step: 0.01 },
    linewidth: { value: 1.2, min: 1.0, max: 5.0, step: 0.1 },
    color1: { r: 255, g: 0, b: 0, a: 1.0 },
    color2: { r: 0, g: 255, b: 0, a: 1.0 },
    color3: { r: 0, g: 0, b: 255, a: 1.0 },
    color4: { r: 255, g: 0, b: 0, a: 1.0 },
    color5: { r: 0, g: 255, b: 0, a: 1.0 },
    color6: { r: 0, g: 0, b: 255, a: 1.0 },
    color7: { r: 255, g: 0, b: 0, a: 1.0 },
    color8: { r: 0, g: 255, b: 0, a: 1.0 },
    color9: { r: 0, g: 0, b: 255, a: 1.0 },
    color10: { r: 255, g: 0, b: 0, a: 1.0 },
    color11: { r: 0, g: 255, b: 0, a: 1.0 },
    color12: { r: 0, g: 0, b: 255, a: 1.0 },
    color13: { r: 255, g: 0, b: 0, a: 1.0 },
    color14: { r: 0, g: 255, b: 0, a: 1.0 },
    color15: { r: 0, g: 0, b: 255, a: 1.0 },
  },
  { collapsed: true });

  // let alpha = 1/3
  // let beta = 1/2

  let p3 = sqrt(1-beta*beta);
  let p1 = cos(2*pi*alpha)/(p3);
  let p2 = sin(2*pi*alpha)/(p3);

  let m=sqrt(p3*p3+p1*p1+p2*p2)
  p1=p1/m
  p2=p2/m
  p3=p3/m

  let rp = sqrt(2+2*p3)
  let rm = sqrt(2-2*p3)
  let r = sqrt(1-pow(p3,2)) // = rp*rm/2
  // let sq2 = sqrt(2)

  //scaling as proxy for scalar component
  let r1 = ring1/sqrt(1-t*t/4)
  let r2 = ring2*2/sqrt(3)
  let r3 = ring3/sqrt(1-1/(4*t*t))
  let r4 = ring4
  let r5 = ring5/sqrt(1-1/(4*t*t))
  let r6 = ring6*2/sqrt(3)
  let r7 = ring7/sqrt(1-t*t/4)
  let r8 = ring8*2/sqrt(3)

/* #region coordinates */
  //p0=1
  let id = [0,0,0]

  //p0=0
  let T1 = [p1/rp, -p2/rp, rp/2]
  let T2 = [-p1/rm, p2/rm, rm/2]
  let T3 = [-p2/r, -p1/r,  0]
  let T1m = multiply(T1,-1)
  let T2m = multiply(T2,-1)
  let T3m = multiply(T3,-1)

  let T4 = multiply(add(T1, add(multiply(T2, t),multiply(T3, 1/t))),1/2)
  let T5 = multiply(add(T2m,add(multiply(T3, t),multiply(T1, 1/t))),1/2)
  let T6 = multiply(add(T3m,add(multiply(T1, t),multiply(T2m,1/t))),1/2)

  let T7 = multiply(add(T1, add(multiply(T2m,t),multiply(T3, 1/t))),1/2)
  let T8 = multiply(add(T2, add(multiply(T3, t),multiply(T1, 1/t))),1/2)
  let T9 = multiply(add(T3, add(multiply(T1m,t),multiply(T2m,1/t))),1/2)

  let T10= multiply(add(T1, add(multiply(T2m,t),multiply(T3m,1/t))),1/2)
  let T11= multiply(add(T2, add(multiply(T3m,t),multiply(T1, 1/t))),1/2)
  let T12= multiply(add(T3, add(multiply(T1, t),multiply(T2, 1/t))),1/2)

  let T13= multiply(add(T1, add(multiply(T2, t),multiply(T3m,1/t))),1/2)
  let T14= multiply(add(T2, add(multiply(T3, t),multiply(T1m,1/t))),1/2)
  let T15= multiply(add(T3, add(multiply(T1, t),multiply(T2m,1/t))),1/2)

  let W1 = multiply(add(T1,add(T2,T3)),1/2)
  let W2 = multiply(add(T1,add(T2,T3m)),1/2)
  let W3 = multiply(add(T1,add(T2m,T3)),1/2)
  let W4 = multiply(add(T1,add(T2m,T3m)),1/2)

  let W5 = multiply(add(multiply(T1,t),multiply(T3,1/t)),1/2)
  let W6 = multiply(add(multiply(T1,t),multiply(T3m,1/t)),1/2)
  let W7 = multiply(add(multiply(T2,t),multiply(T1,1/t)),1/2)
  let W8 = multiply(add(multiply(T2,t),multiply(T1m,1/t)),1/2)
  let W9 = multiply(add(multiply(T3,t),multiply(T2,1/t)),1/2)
  let W10 = multiply(add(multiply(T3,t),multiply(T2m,1/t)),1/2)

  let Q1 = multiply(add(T1,multiply(T2,1/t)),1/2)
  let Q2 = multiply(add(T2,multiply(T3,1/t)),1/2)
  let Q3 = multiply(add(T3,multiply(T1,1/t)),1/2)
  let Q4 = multiply(add(T1,multiply(T2m,1/t)),1/2)
  let Q5 = multiply(add(T2,multiply(T3m,1/t)),1/2)
  let Q6 = multiply(add(T3,multiply(T1m,1/t)),1/2)
/* #endregion */
/* #region vertices */
  //p0=t/2
  let q1 = multiply(Q1,r1)
  let q2 = multiply(Q2,r1)
  let q3 = multiply(Q3,r1)
  let q4 = multiply(Q4,r1)
  let q5 = multiply(Q5,r1)
  let q6 = multiply(Q6,r1)

  let q1p = multiply(Q1,-r1)
  let q2p = multiply(Q2,-r1)
  let q3p = multiply(Q3,-r1)
  let q4p = multiply(Q4,-r1)
  let q5p = multiply(Q5,-r1)
  let q6p = multiply(Q6,-r1)

  //p0=1/2
  let w1 = multiply(W1,r2)
  let w2 = multiply(W2,r2)
  let w3 = multiply(W3,r2)
  let w4 = multiply(W4,r2)
  let w5 = multiply(W5,r2)
  let w6 = multiply(W6,r2)
  let w7 = multiply(W7,r2)
  let w8 = multiply(W8,r2)
  let w9 = multiply(W9,r2)
  let w10= multiply(W10,r2)

  let w1p = multiply(W1,-r2)
  let w2p = multiply(W2,-r2)
  let w3p = multiply(W3,-r2)
  let w4p = multiply(W4,-r2)
  let w5p = multiply(W5,-r2)
  let w6p = multiply(W6,-r2)
  let w7p = multiply(W7,-r2)
  let w8p = multiply(W8,-r2)
  let w9p = multiply(W9,-r2)
  let w10p= multiply(W10,-r2)


  //p0=1/(2t)
  let v1 = multiply(Q1,r3)
  let v2 = multiply(Q2,r3)
  let v3 = multiply(Q3,r3)
  let v4 = multiply(Q4,r3)
  let v5 = multiply(Q5,r3)
  let v6 = multiply(Q6,r3)

  let v1p = multiply(Q1,-r3)
  let v2p = multiply(Q2,-r3)
  let v3p = multiply(Q3,-r3)
  let v4p = multiply(Q4,-r3)
  let v5p = multiply(Q5,-r3)
  let v6p = multiply(Q6,-r3)

  //p0=0
  let t1 = multiply(T1, r4)
  let t2 = multiply(T2, r4)
  let t3 = multiply(T3, r4)
  let t4 = multiply(T4, r4)
  let t5 = multiply(T5, r4)
  let t6 = multiply(T6, r4)
  let t7 = multiply(T7, r4)
  let t8 = multiply(T8, r4)
  let t9 = multiply(T9, r4)
  let t10= multiply(T10,r4)
  let t11= multiply(T11,r4)
  let t12= multiply(T12,r4)
  let t13= multiply(T13,r4)
  let t14= multiply(T14,r4)
  let t15= multiply(T15,r4)

  let t1p = multiply(T1, -r4)
  let t2p = multiply(T2, -r4)
  let t3p = multiply(T3, -r4)
  let t4p = multiply(T4, -r4)
  let t5p = multiply(T5, -r4)
  let t6p = multiply(T6, -r4)
  let t7p = multiply(T7, -r4)
  let t8p = multiply(T8, -r4)
  let t9p = multiply(T9, -r4)
  let t10p= multiply(T10,-r4)
  let t11p= multiply(T11,-r4)
  let t12p= multiply(T12,-r4)
  let t13p= multiply(T13,-r4)
  let t14p= multiply(T14,-r4)
  let t15p= multiply(T15,-r4)

  //p0=-1/(2t)
  let v1pm = multiply(Q1,r5)
  let v2pm = multiply(Q2,r5)
  let v3pm = multiply(Q3,r5)
  let v4pm = multiply(Q4,r5)
  let v5pm = multiply(Q5,r5)
  let v6pm = multiply(Q6,r5)

  let v1m = multiply(Q1,-r5)
  let v2m = multiply(Q2,-r5)
  let v3m = multiply(Q3,-r5)
  let v4m = multiply(Q4,-r5)
  let v5m = multiply(Q5,-r5)
  let v6m = multiply(Q6,-r5)

  //p0=-1/2
  let w1pm = multiply(W1,r6)
  let w2pm = multiply(W2,r6)
  let w3pm = multiply(W3,r6)
  let w4pm = multiply(W4,r6)
  let w5pm = multiply(W5,r6)
  let w6pm = multiply(W6,r6)
  let w7pm = multiply(W7,r6)
  let w8pm = multiply(W8,r6)
  let w9pm = multiply(W9,r6)
  let w10pm= multiply(W10,r6)

  let w1m = multiply(W1,-r6)
  let w2m = multiply(W2,-r6)
  let w3m = multiply(W3,-r6)
  let w4m = multiply(W4,-r6)
  let w5m = multiply(W5,-r6)
  let w6m = multiply(W6,-r6)
  let w7m = multiply(W7,-r6)
  let w8m = multiply(W8,-r6)
  let w9m = multiply(W9,-r6)
  let w10m= multiply(W10,-r6)

  //p0=-t/2
  let q1pm = multiply(Q1,r7)
  let q2pm = multiply(Q2,r7)
  let q3pm = multiply(Q3,r7)
  let q4pm = multiply(Q4,r7)
  let q5pm = multiply(Q5,r7)
  let q6pm = multiply(Q6,r7)

  let q1m = multiply(Q1,-r7)
  let q2m = multiply(Q2,-r7)
  let q3m = multiply(Q3,-r7)
  let q4m = multiply(Q4,-r7)
  let q5m = multiply(Q5,-r7)
  let q6m = multiply(Q6,-r7)

  //p0=-1
  let iw1pm = multiply(W1,r8)
  let iw2pm = multiply(W2,r8)
  let iw3pm = multiply(W3,r8)
  let iw4pm = multiply(W4,r8)
  let iw5pm = multiply(W5,r8)
  let iw6pm = multiply(W6,r8)
  let iw7pm = multiply(W7,r8)
  let iw8pm = multiply(W8,r8)
  let iw9pm = multiply(W9,r8)
  let iw10pm= multiply(W10,r8)

  let iw1m = multiply(W1,-r8)
  let iw2m = multiply(W2,-r8)
  let iw3m = multiply(W3,-r8)
  let iw4m = multiply(W4,-r8)
  let iw5m = multiply(W5,-r8)
  let iw6m = multiply(W6,-r8)
  let iw7m = multiply(W7,-r8)
  let iw8m = multiply(W8,-r8)
  let iw9m = multiply(W9,-r8)
  let iw10m= multiply(W10,-r8)

/* #endregion */
/* #region face-normals */
  // generic normal
  let gn = v2n(id,q1,q2)

  /* #region iqq-faces */
  let iqq_1 = v2n(id,q1,q2)
  let iqq_2 = v2n(id,q1,q3)
  let iqq_3 = v2n(id,q1,q4)
  let iqq_4 = v2n(id,q1,q5)
  let iqq_5 = v2n(id,q1,q6p)

  let iqq_6 = v2n(id,q2,q3)
  let iqq_7 = v2n(id,q2,q4p)
  let iqq_8 = v2n(id,q2,q5)
  let iqq_9 = v2n(id,q2,q6)

  let iqq_10 = v2n(id,q3,q4)
  let iqq_11 = v2n(id,q3,q5p)
  let iqq_12 = v2n(id,q3,q6)

  let iqq_13 = v2n(id,q4,q2p)
  let iqq_14 = v2n(id,q4,q5p)
  let iqq_15 = v2n(id,q4,q6p)

  let iqq_16 = v2n(id,q5,q3p)
  let iqq_17 = v2n(id,q5,q4p)
  let iqq_18 = v2n(id,q5,q6p)

  let iqq_19 = v2n(id,q6,q1p)
  let iqq_20 = v2n(id,q6,q4p)
  let iqq_21 = v2n(id,q6,q5p)

  let iqq_22 = v2n(id,q1p,q2p)
  let iqq_23 = v2n(id,q1p,q3p)
  let iqq_24 = v2n(id,q1p,q4p)
  let iqq_25 = v2n(id,q1p,q5p)

  let iqq_26 = v2n(id,q2p,q3p)
  let iqq_27 = v2n(id,q2p,q5p)
  let iqq_28 = v2n(id,q2p,q6p)

  let iqq_29 = v2n(id,q3p,q4p)
  let iqq_30 = v2n(id,q3p,q6p)
  /* #endregion */
  /* #region qqq-faces */
  let qqq_1 = v2n(q1,q2,q3)
  let qqq_2 = v2n(q1,q2,q5)
  let qqq_3 = v2n(q1,q3,q4)
  let qqq_4 = v2n(q1,q4,q6p)
  let qqq_5 = v2n(q1,q5,q6p)

  let qqq_6 = v2n(q2,q3,q6)
  let qqq_7 = v2n(q2,q5,q4p)
  let qqq_8 = v2n(q2,q6,q4p)

  let qqq_9 = v2n(q3,q4,q5p)
  let qqq_10 = v2n(q3,q6,q5p)

  let qqq_11 = v2n(q4,q2p,q5p)
  let qqq_12 = v2n(q4,q2p,q6p)

  let qqq_13 = v2n(q5,q3p,q4p)
  let qqq_14 = v2n(q5,q3p,q6p)

  let qqq_15 = v2n(q6,q1p,q4p)
  let qqq_16 = v2n(q6,q1p,q5p)

  let qqq_17 = v2n(q1p,q3p,q4p)
  let qqq_18 = v2n(q1p,q3p,q2p)
  let qqq_19 = v2n(q1p,q2p,q5p)

  let qqq_20 = v2n(q2p,q3p,q6p)
  /* #endregion */
  /* #region qqw-faces */
  let qqw_1 = v2n(w1,q1,q2)
  let qqw_2 = v2n(w1,q2,q3)
  let qqw_3 = v2n(w1,q3,q1)

  let qqw_4 = v2n(w2,q1,q5)
  let qqw_5 = v2n(w2,q5,q6p)
  let qqw_6 = v2n(w2,q6p,q1)

  let qqw_7 = v2n(w3,q3,q4)
  let qqw_8 = v2n(w3,q4,q5p)
  let qqw_9 = v2n(w3,q5p,q3)

  let qqw_10 = v2n(w4,q4,q2p)
  let qqw_11 = v2n(w4,q2p,q6p)
  let qqw_12 = v2n(w4,q6p,q4)

  let qqw_13 = v2n(w5,q1,q3)
  let qqw_14 = v2n(w5,q3,q4)
  let qqw_15 = v2n(w5,q4,q1)

  let qqw_16 = v2n(w6,q1,q4)
  let qqw_17 = v2n(w6,q4,q6p)
  let qqw_18 = v2n(w6,q6p,q1)

  let qqw_19 = v2n(w7,q1,q2)
  let qqw_20 = v2n(w7,q2,q5)
  let qqw_21 = v2n(w7,q5,q1)

  let qqw_22 = v2n(w8,q2,q5)
  let qqw_23 = v2n(w8,q5,q4p)
  let qqw_24 = v2n(w8,q4p,q2)

  let qqw_25 = v2n(w9,q2,q3)
  let qqw_26 = v2n(w9,q3,q6)
  let qqw_27 = v2n(w9,q6,q2)

  let qqw_28 = v2n(w10,q3,q6)
  let qqw_29 = v2n(w10,q6,q5p)
  let qqw_30 = v2n(w10,q5p,q3)

  let qqw_31 = v2n(w1p,q1p,q2p)
  let qqw_32 = v2n(w1p,q2p,q3p)
  let qqw_33 = v2n(w1p,q3p,q1p)

  let qqw_34 = v2n(w2p,q6,q1p)
  let qqw_35 = v2n(w2p,q1p,q5p)
  let qqw_36 = v2n(w2p,q5p,q6)

  let qqw_37 = v2n(w3p,q5,q3p)
  let qqw_38 = v2n(w3p,q3p,q4p)
  let qqw_39 = v2n(w3p,q4p,q5)

  let qqw_40 = v2n(w4p,q2,q6)
  let qqw_41 = v2n(w4p,q6,q4p)
  let qqw_42 = v2n(w4p,q4p,q2)

  let qqw_43 = v2n(w5p,q1p,q3p)
  let qqw_44 = v2n(w5p,q3p,q4p)
  let qqw_45 = v2n(w5p,q4p,q1p)

  let qqw_46 = v2n(w6p,q6,q1p)
  let qqw_47 = v2n(w6p,q1p,q4p)
  let qqw_48 = v2n(w6p,q4p,q6)

  let qqw_49 = v2n(w7p,q1p,q2p)
  let qqw_50 = v2n(w7p,q2p,q5p)
  let qqw_51 = v2n(w7p,q5p,q1p)

  let qqw_52 = v2n(w8p,q4,q2p)
  let qqw_53 = v2n(w8p,q2p,q5p)
  let qqw_54 = v2n(w8p,q5p,q4)

  let qqw_55 = v2n(w9p,q2p,q3p)
  let qqw_56 = v2n(w9p,q3p,q6p)
  let qqw_57 = v2n(w9p,q6p,q2p)

  let qqw_58 = v2n(w10p,q5,q3p)
  let qqw_59 = v2n(w10p,q3p,q6p)
  let qqw_60 = v2n(w10p,q6p,q5)
  /* #endregion */
  /* #region qww-faces */
  let qww_1 = v2n(w1,w7,q1)
  let qww_2 = v2n(w1,w7,q2)
  let qww_3 = v2n(w1,w9,q2)
  let qww_4 = v2n(w1,w9,q3)
  let qww_5 = v2n(w1,w5,q3)
  let qww_6 = v2n(w1,w5,q1)
  
  let qww_7 = v2n(w2,w7,q1)
  let qww_8 = v2n(w2,w7,q5)
  let qww_9 = v2n(w2,w10p,q5)
  let qww_10 = v2n(w2,w10p,q6p)
  let qww_11 = v2n(w2,w6,q6p)
  let qww_12 = v2n(w2,w6,q1)

  let qww_13 = v2n(w3,w5,q3)
  let qww_14 = v2n(w3,w5,q4)
  let qww_15 = v2n(w3,w8p,q4)
  let qww_16 = v2n(w3,w8p,q5p)
  let qww_17 = v2n(w3,w10,q5p)
  let qww_18 = v2n(w3,w10,q3)

  let qww_19 = v2n(w4,w8p,q4)
  let qww_20 = v2n(w4,w8p,q2p)
  let qww_21 = v2n(w4,w9p,q2p)
  let qww_22 = v2n(w4,w9p,q6p)
  let qww_23 = v2n(w4,w6,q6p)
  let qww_24 = v2n(w4,w6,q4)

  let qww_25 = v2n(w5,w6,q4)
  let qww_26 = v2n(w5,w6,q1)

  let qww_27 = v2n(w7,w8,q2)
  let qww_28 = v2n(w7,w8,q5)

  let qww_29 = v2n(w8,w3p,q5)
  let qww_30 = v2n(w8,w3p,q4p)
  let qww_31 = v2n(w8,w4p,q4p)
  let qww_32 = v2n(w8,w4p,q2)

  let qww_33 = v2n(w9,w10,q3)
  let qww_34 = v2n(w9,w10,q6)
  let qww_35 = v2n(w9,w4p,q6)
  let qww_36 = v2n(w9,w4p,q2)

  let qww_37 = v2n(w10,w2p,q6)
  let qww_38 = v2n(w10,w2p,q5p)

  let qww_39 = v2n(w1p,w7p,q1p)
  let qww_40 = v2n(w1p,w7p,q2p)
  let qww_41 = v2n(w1p,w9p,q2p)
  let qww_42 = v2n(w1p,w9p,q3p)
  let qww_43 = v2n(w1p,w5p,q3p)
  let qww_44 = v2n(w1p,w5p,q1p)

  let qww_45 = v2n(w2p,w6p,q6)
  let qww_46 = v2n(w2p,w6p,q1p)
  let qww_47 = v2n(w2p,w7p,q1p)
  let qww_48 = v2n(w2p,w7p,q5p)

  let qww_49 = v2n(w3p,w10p,q5)
  let qww_50 = v2n(w3p,w10p,q3p)
  let qww_51 = v2n(w3p,w5p,q3p)
  let qww_52 = v2n(w3p,w5p,q4p)

  let qww_53 = v2n(w4p,w6p,q6)
  let qww_54 = v2n(w4p,w6p,q4p)

  let qww_55 = v2n(w5p,w6p,q4p)
  let qww_56 = v2n(w5p,w6p,q1p)

  let qww_57 = v2n(w7p,w8p,q2p)
  let qww_58 = v2n(w7p,w8p,q5p)

  let qww_59 = v2n(w9p,w10p,q3p)
  let qww_60 = v2n(w9p,w10p,q6p)
  /* #endregion */
  /* #region qwv-faces */
  let qwv_1 = v2n(q1,v1,w1)
  let qwv_2 = v2n(q1,v1,w2)
  let qwv_3 = v2n(q1,v1,w5)
  let qwv_4 = v2n(q1,v1,w6)
  let qwv_5 = v2n(q1,v1,w7)

  let qwv_6 = v2n(q2,v2,w1)
  let qwv_7 = v2n(q2,v2,w7)
  let qwv_8 = v2n(q2,v2,w8)
  let qwv_9 = v2n(q2,v2,w9)
  let qwv_10 = v2n(q2,v2,w4p)

  let qwv_11 = v2n(q3,v3,w1)
  let qwv_12 = v2n(q3,v3,w3)
  let qwv_13 = v2n(q3,v3,w5)
  let qwv_14 = v2n(q3,v3,w9)
  let qwv_15 = v2n(q3,v3,w10)

  let qwv_16 = v2n(q4,v4,w3)
  let qwv_17 = v2n(q4,v4,w4)
  let qwv_18 = v2n(q4,v4,w5)
  let qwv_19 = v2n(q4,v4,w6)
  let qwv_20 = v2n(q4,v4,w8p)

  let qwv_21 = v2n(q5,v5,w2)
  let qwv_22 = v2n(q5,v5,w7)
  let qwv_23 = v2n(q5,v5,w8)
  let qwv_24 = v2n(q5,v5,w3p)
  let qwv_25 = v2n(q5,v5,w10p)

  let qwv_26 = v2n(q6,v6,w9)
  let qwv_27 = v2n(q6,v6,w10)
  let qwv_28 = v2n(q6,v6,w2p)
  let qwv_29 = v2n(q6,v6,w4p)
  let qwv_30 = v2n(q6,v6,w6p)

  let qwv_31 = v2n(q1p,v1p,w1p)
  let qwv_32 = v2n(q1p,v1p,w2p)
  let qwv_33 = v2n(q1p,v1p,w5p)
  let qwv_34 = v2n(q1p,v1p,w6p)
  let qwv_35 = v2n(q1p,v1p,w7p)

  let qwv_36 = v2n(q2p,v2p,w4)
  let qwv_37 = v2n(q2p,v2p,w1p)
  let qwv_38 = v2n(q2p,v2p,w7p)
  let qwv_39 = v2n(q2p,v2p,w8p)
  let qwv_40 = v2n(q2p,v2p,w9p)

  let qwv_41 = v2n(q3p,v3p,w1p)
  let qwv_42 = v2n(q3p,v3p,w3p)
  let qwv_43 = v2n(q3p,v3p,w5p)
  let qwv_44 = v2n(q3p,v3p,w9p)
  let qwv_45 = v2n(q3p,v3p,w10p)

  let qwv_46 = v2n(q4p,v4p,w8)
  let qwv_47 = v2n(q4p,v4p,w3p)
  let qwv_48 = v2n(q4p,v4p,w4p)
  let qwv_49 = v2n(q4p,v4p,w5p)
  let qwv_50 = v2n(q4p,v4p,w6p)

  let qwv_51 = v2n(q5p,v5p,w3)
  let qwv_52 = v2n(q5p,v5p,w10)
  let qwv_53 = v2n(q5p,v5p,w2p)
  let qwv_54 = v2n(q5p,v5p,w7p)
  let qwv_55 = v2n(q5p,v5p,w8p)

  let qwv_56 = v2n(q6p,v6p,w2)
  let qwv_57 = v2n(q6p,v6p,w4)
  let qwv_58 = v2n(q6p,v6p,w6)
  let qwv_59 = v2n(q6p,v6p,w9p)
  let qwv_60 = v2n(q6p,v6p,w10p)
  /* #endregion */
  /* #region wwv-faces */
  let wwv_1 = v2n(w1,w7,v1)
  let wwv_2 = v2n(w1,w7,v2)
  let wwv_3 = v2n(w1,w9,v2)
  let wwv_4 = v2n(w1,w9,v3)
  let wwv_5 = v2n(w1,w5,v3)
  let wwv_6 = v2n(w1,w5,v1)
  
  let wwv_7 = v2n(w2,w7,v1)
  let wwv_8 = v2n(w2,w7,v5)
  let wwv_9 = v2n(w2,w10p,v5)
  let wwv_10 = v2n(w2,w10p,v6p)
  let wwv_11 = v2n(w2,w6,v6p)
  let wwv_12 = v2n(w2,w6,v1)

  let wwv_13 = v2n(w3,w5,v3)
  let wwv_14 = v2n(w3,w5,v4)
  let wwv_15 = v2n(w3,w8p,v4)
  let wwv_16 = v2n(w3,w8p,v5p)
  let wwv_17 = v2n(w3,w10,v5p)
  let wwv_18 = v2n(w3,w10,v3)

  let wwv_19 = v2n(w4,w8p,v4)
  let wwv_20 = v2n(w4,w8p,v2p)
  let wwv_21 = v2n(w4,w9p,v2p)
  let wwv_22 = v2n(w4,w9p,v6p)
  let wwv_23 = v2n(w4,w6,v6p)
  let wwv_24 = v2n(w4,w6,v4)

  let wwv_25 = v2n(w5,w6,v4)
  let wwv_26 = v2n(w5,w6,v1)

  let wwv_27 = v2n(w7,w8,v2)
  let wwv_28 = v2n(w7,w8,v5)

  let wwv_29 = v2n(w8,w3p,v5)
  let wwv_30 = v2n(w8,w3p,v4p)
  let wwv_31 = v2n(w8,w4p,v4p)
  let wwv_32 = v2n(w8,w4p,v2)

  let wwv_33 = v2n(w9,w10,v3)
  let wwv_34 = v2n(w9,w10,v6)
  let wwv_35 = v2n(w9,w4p,v6)
  let wwv_36 = v2n(w9,w4p,v2)

  let wwv_37 = v2n(w10,w2p,v6)
  let wwv_38 = v2n(w10,w2p,v5p)

  let wwv_39 = v2n(w1p,w7p,v1p)
  let wwv_40 = v2n(w1p,w7p,v2p)
  let wwv_41 = v2n(w1p,w9p,v2p)
  let wwv_42 = v2n(w1p,w9p,v3p)
  let wwv_43 = v2n(w1p,w5p,v3p)
  let wwv_44 = v2n(w1p,w5p,v1p)

  let wwv_45 = v2n(w2p,w6p,v6)
  let wwv_46 = v2n(w2p,w6p,v1p)
  let wwv_47 = v2n(w2p,w7p,v1p)
  let wwv_48 = v2n(w2p,w7p,v5p)

  let wwv_49 = v2n(w3p,w10p,v5)
  let wwv_50 = v2n(w3p,w10p,v3p)
  let wwv_51 = v2n(w3p,w5p,v3p)
  let wwv_52 = v2n(w3p,w5p,v4p)

  let wwv_53 = v2n(w4p,w6p,v6)
  let wwv_54 = v2n(w4p,w6p,v4p)

  let wwv_55 = v2n(w5p,w6p,v4p)
  let wwv_56 = v2n(w5p,w6p,v1p)

  let wwv_57 = v2n(w7p,w8p,v2p)
  let wwv_58 = v2n(w7p,w8p,v5p)

  let wwv_59 = v2n(w9p,w10p,v3p)
  let wwv_60 = v2n(w9p,w10p,v6p)
  /* #endregion */
  /* #region wwt-faces */
  let wwt_1 = v2n(w1,w5,t12)
  let wwt_2 = v2n(w1,w7,t4)
  let wwt_3 = v2n(w2,w6,t9p)
  let wwt_4 = v2n(w2,w7,t13)
  let wwt_5 = v2n(w5,w6,t1)
  /* #endregion */
  /* #region wvt-faces */
  let wvt_1 = v2n(v1,w1,t4)
  let wvt_2 = v2n(v1,w1,t12)
  let wvt_3 = v2n(v1,w2,t13)
  let wvt_4 = v2n(v1,w2,t9p)
  let wvt_5 = v2n(v1,w5,t1)
  let wvt_6 = v2n(v1,w5,t12)
  let wvt_7 = v2n(v1,w6,t1)
  let wvt_8 = v2n(v1,w6,t9p)
  let wvt_9 = v2n(v1,w7,t4)
  let wvt_10 = v2n(v1,w7,t13)
  /* #endregion */
  /* #region wtt-faces */
  let wtt_1 = v2n(t1,t12,w5)
  let wtt_2 = v2n(t1,t9p,w6)
  let wtt_3 = v2n(t4,t12,w1)
  let wtt_4 = v2n(t4,t13,w7)
  let wtt_5 = v2n(t13,t9p,w2)
  /* #endregion */
  /* #region vtt-faces */
  let vtt_1 = v2n(v1,t1,t12)
  let vtt_2 = v2n(v1,t1,t9p)
  let vtt_3 = v2n(v1,t4,t12)
  let vtt_4 = v2n(v1,t4,t13)
  let vtt_5 = v2n(v1,t13,t9p)
  /* #endregion */
/* #endregion */
/* #region cells */
  /* #region iqqq cells (20) */
  if (layer1) {
    verts1 = [
    /* #region cell_i-qqq_1 */
      {pos: id, norm: iqq_1, },
      {pos: q2, norm: iqq_1, },
      {pos: q1, norm: iqq_1, },

      {pos: id, norm: iqq_2, },
      {pos: q3, norm: iqq_2, },
      {pos: q2, norm: iqq_2, },

      {pos: id, norm: iqq_6, },
      {pos: q1, norm: iqq_6, },
      {pos: q3, norm: iqq_6, },

      {pos: q1, norm: qqq_1, },
      {pos: q2, norm: qqq_1, },
      {pos: q3, norm: qqq_1, },
    /* #endregion */
    /* #region cell_i-qqq_2 */
      {pos: id, norm: iqq_1, },
      {pos: q1, norm: iqq_1, },
      {pos: q2, norm: iqq_1, },

      {pos: id, norm: iqq_8, },
      {pos: q2, norm: iqq_8, },
      {pos: q5, norm: iqq_8, },

      {pos: id, norm: iqq_4, },
      {pos: q5, norm: iqq_4, },
      {pos: q1, norm: iqq_4, },

      {pos: q2, norm: qqq_2, },
      {pos: q1, norm: qqq_2, },
      {pos: q5, norm: qqq_2, },
    /* #endregion */
    /* #region cell_i-qqq_3 */
      {pos: id, norm: iqq_2, },
      {pos: q3, norm: iqq_2, },
      {pos: q1, norm: iqq_2, },

      {pos: id, norm: iqq_10, },
      {pos: q4, norm: iqq_10, },
      {pos: q3, norm: iqq_10, },

      {pos: id, norm: iqq_3, },
      {pos: q1, norm: iqq_3, },
      {pos: q4, norm: iqq_3, },

      {pos: q1, norm: qqq_3, },
      {pos: q3, norm: qqq_3, },
      {pos: q4, norm: qqq_3, },
    /* #endregion */
    /* #region cell_i-qqq_4 */
      {pos: id, norm: iqq_3, },
      {pos: q4, norm: iqq_3, },
      {pos: q1, norm: iqq_3, },

      {pos: id, norm: iqq_15, },
      {pos: q6p, norm: iqq_15, },
      {pos: q4, norm: iqq_15, },

      {pos: id, norm: iqq_5, },
      {pos: q1, norm: iqq_5, },
      {pos: q6p, norm: iqq_5, },

      {pos: q1, norm: qqq_4, },
      {pos: q4, norm: qqq_4, },
      {pos: q6p, norm: qqq_4, },
    /* #endregion */
    /* #region cell_i-qqq_5 */
      {pos: id, norm: iqq_5, },
      {pos: q6p, norm: iqq_5, },
      {pos: q1, norm: iqq_5, },

      {pos: id, norm: iqq_18, },
      {pos: q5, norm: iqq_18, },
      {pos: q6p, norm: iqq_18, },

      {pos: id, norm: iqq_4, },
      {pos: q1, norm: iqq_4, },
      {pos: q5, norm: iqq_4, },

      {pos: q1, norm: qqq_5, },
      {pos: q6p, norm: qqq_5, },
      {pos: q5, norm: qqq_5, },
    /* #endregion */
    /* #region cell_i-qqq_6 */
      {pos: id, norm: iqq_6, },
      {pos: q2, norm: iqq_6, },
      {pos: q3, norm: iqq_6, },

      {pos: id, norm: iqq_9, },
      {pos: q6, norm: iqq_9, },
      {pos: q2, norm: iqq_9, },

      {pos: id, norm: iqq_12, },
      {pos: q3, norm: iqq_12, },
      {pos: q6, norm: iqq_12, },

      {pos: q3, norm: qqq_6, },
      {pos: q2, norm: qqq_6, },
      {pos: q6, norm: qqq_6, },
    /* #endregion */
    /* #region cell_i-qqq_7 */
      {pos: id, norm: iqq_8, },
      {pos: q5, norm: iqq_8, },
      {pos: q2, norm: iqq_8, },

      {pos: id, norm: iqq_7, },
      {pos: q2, norm: iqq_7, },
      {pos: q4p, norm: iqq_7, },

      {pos: id, norm: iqq_17, },
      {pos: q4p, norm: iqq_17, },
      {pos: q5, norm: iqq_17, },

      {pos: q5, norm: qqq_7, },
      {pos: q4p, norm: qqq_7, },
      {pos: q2, norm: qqq_7, },
    /* #endregion */
    /* #region cell_i-qqq_8 */
      {pos: id, norm: iqq_9, },
      {pos: q2, norm: iqq_9, },
      {pos: q6, norm: iqq_9, },

      {pos: id, norm: iqq_20, },
      {pos: q6, norm: iqq_20, },
      {pos: q4p, norm: iqq_20, },

      {pos: id, norm: iqq_7, },
      {pos: q4p, norm: iqq_7, },
      {pos: q2, norm: iqq_7, },

      {pos: q2, norm: qqq_8, },
      {pos: q4p, norm: qqq_8, },
      {pos: q6, norm: qqq_8, },
    /* #endregion */
    /* #region cell_i-qqq_9 */
      {pos: id, norm: iqq_10, },
      {pos: q3, norm: iqq_10, },
      {pos: q4, norm: iqq_10, },

      {pos: id, norm: iqq_14, },
      {pos: q4, norm: iqq_14, },
      {pos: q5p, norm: iqq_14, },

      {pos: id, norm: iqq_11, },
      {pos: q5p, norm: iqq_11, },
      {pos: q3, norm: iqq_11, },

      {pos: q4, norm: qqq_9, },
      {pos: q3, norm: qqq_9, },
      {pos: q5p, norm: qqq_9, },
    /* #endregion */
    /* #region cell_i-qqq_10 */
      {pos: id, norm: iqq_12, },
      {pos: q6, norm: iqq_12, },
      {pos: q3, norm: iqq_12, },

      {pos: id, norm: iqq_11, },
      {pos: q3, norm: iqq_11, },
      {pos: q5p, norm: iqq_11, },

      {pos: id, norm: iqq_21, },
      {pos: q5p, norm: iqq_21, },
      {pos: q6, norm: iqq_21, },

      {pos: q3, norm: qqq_10, },
      {pos: q6, norm: qqq_10, },
      {pos: q5p, norm: qqq_10, },
    /* #endregion */
    /* #region cell_i-qqq_11 */
      {pos: id, norm: iqq_14, },
      {pos: q5p, norm: iqq_14, },
      {pos: q4, norm: iqq_14, },

      {pos: id, norm: iqq_13, },
      {pos: q4, norm: iqq_13, },
      {pos: q2p, norm: iqq_13, },

      {pos: id, norm: iqq_27, },
      {pos: q2p, norm: iqq_27, },
      {pos: q5p, norm: iqq_27, },

      {pos: q4, norm: qqq_11, },
      {pos: q5p, norm: qqq_11, },
      {pos: q2p, norm: qqq_11, },
    /* #endregion */
    /* #region cell_i-qqq_12 */
      {pos: id, norm: iqq_15, },
      {pos: q4, norm: iqq_15, },
      {pos: q6p, norm: iqq_15, },

      {pos: id, norm: iqq_28, },
      {pos: q6p, norm: iqq_28, },
      {pos: q2p, norm: iqq_28, },

      {pos: id, norm: iqq_13, },
      {pos: q2p, norm: iqq_13, },
      {pos: q4, norm: iqq_13, },

      {pos: q4, norm: qqq_12, },
      {pos: q2p, norm: qqq_12, },
      {pos: q6p, norm: qqq_12, },
    /* #endregion */
    /* #region cell_i-qqq_13 */
      {pos: id, norm: iqq_17, },
      {pos: q5, norm: iqq_17, },
      {pos: q4p, norm: iqq_17, },

      {pos: id, norm: iqq_29, },
      {pos: q4p, norm: iqq_29, },
      {pos: q3p, norm: iqq_29, },

      {pos: id, norm: iqq_16, },
      {pos: q3p, norm: iqq_16, },
      {pos: q5, norm: iqq_16, },

      {pos: q5, norm: qqq_13, },
      {pos: q3p, norm: qqq_13, },
      {pos: q4p, norm: qqq_13, },
    /* #endregion */
    /* #region cell_i-qqq_14 */
      {pos: id, norm: iqq_18, },
      {pos: q6p, norm: iqq_18, },
      {pos: q5, norm: iqq_18, },

      {pos: id, norm: iqq_16, },
      {pos: q5, norm: iqq_16, },
      {pos: q3p, norm: iqq_16, },

      {pos: id, norm: iqq_30, },
      {pos: q3p, norm: iqq_30, },
      {pos: q6p, norm: iqq_30, },

      {pos: q5, norm: qqq_14, },
      {pos: q6p, norm: qqq_14, },
      {pos: q3p, norm: qqq_14, },
    /* #endregion */
    /* #region cell_i-qqq_15 */
      {pos: id, norm: iqq_20, },
      {pos: q4p, norm: iqq_20, },
      {pos: q6, norm: iqq_20, },

      {pos: id, norm: iqq_19, },
      {pos: q6, norm: iqq_19, },
      {pos: q1p, norm: iqq_19, },

      {pos: id, norm: iqq_24, },
      {pos: q1p, norm: iqq_24, },
      {pos: q4p, norm: iqq_24, },

      {pos: q6, norm: qqq_15, },
      {pos: q4p, norm: qqq_15, },
      {pos: q1p, norm: qqq_15, },
    /* #endregion */
    /* #region cell_i-qqq_16 */
      {pos: id, norm: iqq_19, },
      {pos: q1p, norm: iqq_19, },
      {pos: q6, norm: iqq_19, },

      {pos: id, norm: iqq_21, },
      {pos: q6, norm: iqq_21, },
      {pos: q5p, norm: iqq_21, },

      {pos: id, norm: iqq_25, },
      {pos: q5p, norm: iqq_25, },
      {pos: q1p, norm: iqq_25, },

      {pos: q6, norm: qqq_16, },
      {pos: q1p, norm: qqq_16, },
      {pos: q5p, norm: qqq_16, },
    /* #endregion */
    /* #region cell_i-qqq_17 */
      {pos: id, norm: iqq_24, },
      {pos: q4p, norm: iqq_24, },
      {pos: q1p, norm: iqq_24, },

      {pos: id, norm: iqq_23, },
      {pos: q1p, norm: iqq_23, },
      {pos: q3p, norm: iqq_23, },

      {pos: id, norm: iqq_29, },
      {pos: q3p, norm: iqq_29, },
      {pos: q4p, norm: iqq_29, },

      {pos: q1p, norm: qqq_17, },
      {pos: q4p, norm: qqq_17, },
      {pos: q3p, norm: qqq_17, },
    /* #endregion */
    /* #region cell_i-qqq_18 */
      {pos: id, norm: iqq_23, },
      {pos: q3p, norm: iqq_23, },
      {pos: q1p, norm: iqq_23, },

      {pos: id, norm: iqq_22, },
      {pos: q1p, norm: iqq_22, },
      {pos: q2p, norm: iqq_22, },

      {pos: id, norm: iqq_26, },
      {pos: q2p, norm: iqq_26, },
      {pos: q3p, norm: iqq_26, },

      {pos: q1p, norm: qqq_18, },
      {pos: q3p, norm: qqq_18, },
      {pos: q2p, norm: qqq_18, },
    /* #endregion */
    /* #region cell_i-qqq_19 */
      {pos: id, norm: iqq_22, },
      {pos: q2p, norm: iqq_22, },
      {pos: q1p, norm: iqq_22, },

      {pos: id, norm: iqq_25, },
      {pos: q1p, norm: iqq_25, },
      {pos: q5p, norm: iqq_25, },

      {pos: id, norm: iqq_27, },
      {pos: q5p, norm: iqq_27, },
      {pos: q2p, norm: iqq_27, },

      {pos: q1p, norm: qqq_19, },
      {pos: q2p, norm: qqq_19, },
      {pos: q5p, norm: qqq_19, },
    /* #endregion */
    /* #region cell_i-qqq_20 */
      {pos: id, norm: iqq_26, },
      {pos: q3p, norm: iqq_26, },
      {pos: q2p, norm: iqq_26, },

      {pos: id, norm: iqq_28, },
      {pos: q2p, norm: iqq_28, },
      {pos: q6p, norm: iqq_28, },

      {pos: id, norm: iqq_30, },
      {pos: q6p, norm: iqq_30, },
      {pos: q3p, norm: iqq_30, },

      {pos: q2p, norm: qqq_20, },
      {pos: q3p, norm: qqq_20, },
      {pos: q6p, norm: qqq_20, },
    /* #endregion */
    ]} else {
      verts1 = []
  }
    /* #endregion */
  /* #region qqqw cells (20) */
  if (layer2) {  
    verts2 = [
    /* #region cell_qqq-w_1 */
      {pos: w1, norm: qqw_1, },
      {pos: q1, norm: qqw_1, },
      {pos: q2, norm: qqw_1, },

      {pos: w1, norm: qqw_2, },
      {pos: q2, norm: qqw_2, },
      {pos: q3, norm: qqw_2, },

      {pos: w1, norm: qqw_3, },
      {pos: q3, norm: qqw_3, },
      {pos: q1, norm: qqw_3, },

      {pos: q1, norm: qqq_1, },
      {pos: q3, norm: qqq_1, },
      {pos: q2, norm: qqq_1, },
    /* #endregion */
    /* #region cell_qqq-w_2 */
      {pos: w2, norm: qqw_4, },
      {pos: q5, norm: qqw_4, },
      {pos: q1, norm: qqw_4, },

      {pos: w2, norm: qqw_5, },
      {pos: q6p, norm: qqw_5, },
      {pos: q5, norm: qqw_5, },

      {pos: w2, norm: qqw_6, },
      {pos: q1, norm: qqw_6, },
      {pos: q6p, norm: qqw_6, },

      {pos: q1, norm: qqq_5, },
      {pos: q5, norm: qqq_5, },
      {pos: q6p, norm: qqq_5, },
    /* #endregion */
    /* #region cell_qqq-w_3 */
      {pos: w3, norm: qqw_7, },
      {pos: q4, norm: qqw_7, },
      {pos: q3, norm: qqw_7, },

      {pos: w3, norm: qqw_8, },
      {pos: q5p, norm: qqw_8, },
      {pos: q4, norm: qqw_8, },

      {pos: w3, norm: qqw_9, },
      {pos: q3, norm: qqw_9, },
      {pos: q5p, norm: qqw_9, },

      {pos: q4, norm: qqq_9, },
      {pos: q5p, norm: qqq_9, },
      {pos: q3, norm: qqq_9, },
    /* #endregion */
    /* #region cell_qqq-w_4 */
      {pos: w4, norm: qqw_10, },
      {pos: q4, norm: qqw_10, },
      {pos: q2p, norm: qqw_10, },

      {pos: w4, norm: qqw_11, },
      {pos: q2p, norm: qqw_11, },
      {pos: q6p, norm: qqw_11, },

      {pos: w4, norm: qqw_12, },
      {pos: q6p, norm: qqw_12, },
      {pos: q4, norm: qqw_12, },

      {pos: q4, norm: qqq_12, },
      {pos: q6p, norm: qqq_12, },
      {pos: q2p, norm: qqq_12, },
    /* #endregion */
    /* #region cell_qqq-w_5 */
      {pos: w5, norm: qqw_13, },
      {pos: q1, norm: qqw_13, },
      {pos: q3, norm: qqw_13, },

      {pos: w5, norm: qqw_14, },
      {pos: q3, norm: qqw_14, },
      {pos: q4, norm: qqw_14, },

      {pos: w5, norm: qqw_15, },
      {pos: q4, norm: qqw_15, },
      {pos: q1, norm: qqw_15, },

      {pos: q1, norm: qqq_3, },
      {pos: q4, norm: qqq_3, },
      {pos: q3, norm: qqq_3, },
    /* #endregion */
    /* #region cell_qqq-w_6 */
      {pos: w6, norm: qqw_16, },
      {pos: q1, norm: qqw_16, },
      {pos: q4, norm: qqw_16, },

      {pos: w6, norm: qqw_17, },
      {pos: q4, norm: qqw_17, },
      {pos: q6p, norm: qqw_17, },

      {pos: w6, norm: qqw_18, },
      {pos: q6p, norm: qqw_18, },
      {pos: q1, norm: qqw_18, },

      {pos: q1, norm: qqq_4, },
      {pos: q6p, norm: qqq_4, },
      {pos: q4, norm: qqq_4, },
    /* #endregion */
    /* #region cell_qqq-w_7 */
      {pos: w7, norm: qqw_19, },
      {pos: q2, norm: qqw_19, },
      {pos: q1, norm: qqw_19, },

      {pos: w7, norm: qqw_20, },
      {pos: q5, norm: qqw_20, },
      {pos: q2, norm: qqw_20, },

      {pos: w7, norm: qqw_21, },
      {pos: q1, norm: qqw_21, },
      {pos: q5, norm: qqw_21, },

      {pos: q2, norm: qqq_2, },
      {pos: q5, norm: qqq_2, },
      {pos: q1, norm: qqq_2, },
    /* #endregion */
    /* #region cell_qqq-w_8 */
      {pos: w8, norm: qqw_22, },
      {pos: q2, norm: qqw_22, },
      {pos: q5, norm: qqw_22, },

      {pos: w8, norm: qqw_23, },
      {pos: q5, norm: qqw_23, },
      {pos: q4p, norm: qqw_23, },

      {pos: w8, norm: qqw_24, },
      {pos: q4p, norm: qqw_24, },
      {pos: q2, norm: qqw_24, },

      {pos: q5, norm: qqq_7, },
      {pos: q2, norm: qqq_7, },
      {pos: q4p, norm: qqq_7, },
    /* #endregion */
    /* #region cell_qqq-w_9 */
      {pos: w9, norm: qqw_25, },
      {pos: q3, norm: qqw_25, },
      {pos: q2, norm: qqw_25, },

      {pos: w9, norm: qqw_26, },
      {pos: q6, norm: qqw_26, },
      {pos: q3, norm: qqw_26, },

      {pos: w9, norm: qqw_27, },
      {pos: q2, norm: qqw_27, },
      {pos: q6, norm: qqw_27, },

      {pos: q3, norm: qqq_6, },
      {pos: q6, norm: qqq_6, },
      {pos: q2, norm: qqq_6, },
    /* #endregion */
    /* #region cell_qqq-w_10 */
      {pos: w10, norm: qqw_28, },
      {pos: q3, norm: qqw_28, },
      {pos: q6, norm: qqw_28, },

      {pos: w10, norm: qqw_29, },
      {pos: q6, norm: qqw_29, },
      {pos: q5p, norm: qqw_29, },

      {pos: w10, norm: qqw_30, },
      {pos: q5p, norm: qqw_30, },
      {pos: q3, norm: qqw_30, },

      {pos: q3, norm: qqq_10, },
      {pos: q5p, norm: qqq_10, },
      {pos: q6, norm: qqq_10, },
    /* #endregion */
    /* #region cell_qqq-w_11 */
      {pos: w1p, norm: qqw_31, },
      {pos: q2p, norm: qqw_31, },
      {pos: q1p, norm: qqw_31, },

      {pos: w1p, norm: qqw_32, },
      {pos: q3p, norm: qqw_32, },
      {pos: q2p, norm: qqw_32, },

      {pos: w1p, norm: qqw_33, },
      {pos: q1p, norm: qqw_33, },
      {pos: q3p, norm: qqw_33, },

      {pos: q1p, norm: qqq_18, },
      {pos: q2p, norm: qqq_18, },
      {pos: q3p, norm: qqq_18, },
    /* #endregion */
    /* #region cell_qqq-w_12 */
      {pos: w2p, norm: qqw_34, },
      {pos: q6, norm: qqw_34, },
      {pos: q1p, norm: qqw_34, },

      {pos: w2p, norm: qqw_35, },
      {pos: q1p, norm: qqw_35, },
      {pos: q5p, norm: qqw_35, },

      {pos: w2p, norm: qqw_36, },
      {pos: q5p, norm: qqw_36, },
      {pos: q6, norm: qqw_36, },

      {pos: q6, norm: qqq_16, },
      {pos: q5p, norm: qqq_16, },
      {pos: q1p, norm: qqq_16, },
    /* #endregion */
    /* #region cell_qqq-w_13 */
      {pos: w3p, norm: qqw_37, },
      {pos: q5, norm: qqw_37, },
      {pos: q3p, norm: qqw_37, },

      {pos: w3p, norm: qqw_38, },
      {pos: q3p, norm: qqw_38, },
      {pos: q4p, norm: qqw_38, },

      {pos: w3p, norm: qqw_39, },
      {pos: q4p, norm: qqw_39, },
      {pos: q5, norm: qqw_39, },

      {pos: q5, norm: qqq_13, },
      {pos: q4p, norm: qqq_13, },
      {pos: q3p, norm: qqq_13, },
    /* #endregion */
    /* #region cell_qqq-w_14 */
      {pos: w4p, norm: qqw_40, },
      {pos: q6, norm: qqw_40, },
      {pos: q2, norm: qqw_40, },

      {pos: w4p, norm: qqw_41, },
      {pos: q4p, norm: qqw_41, },
      {pos: q6, norm: qqw_41, },

      {pos: w4p, norm: qqw_42, },
      {pos: q2, norm: qqw_42, },
      {pos: q4p, norm: qqw_42, },

      {pos: q2, norm: qqq_8, },
      {pos: q6, norm: qqq_8, },
      {pos: q4p, norm: qqq_8, },
    /* #endregion */
    /* #region cell_qqq-w_15 */
      {pos: w5p, norm: qqw_43, },
      {pos: q3p, norm: qqw_43, },
      {pos: q1p, norm: qqw_43, },

      {pos: w5p, norm: qqw_44, },
      {pos: q4p, norm: qqw_44, },
      {pos: q3p, norm: qqw_44, },

      {pos: w5p, norm: qqw_45, },
      {pos: q1p, norm: qqw_45, },
      {pos: q4p, norm: qqw_45, },

      {pos: q1p, norm: qqq_17, },
      {pos: q3p, norm: qqq_17, },
      {pos: q4p, norm: qqq_17, },
    /* #endregion */
    /* #region cell_qqq-w_16 */
      {pos: w6p, norm: qqw_46, },
      {pos: q1p, norm: qqw_46, },
      {pos: q6, norm: qqw_46, },

      {pos: w6p, norm: qqw_47, },
      {pos: q4p, norm: qqw_47, },
      {pos: q1p, norm: qqw_47, },

      {pos: w6p, norm: qqw_48, },
      {pos: q6, norm: qqw_48, },
      {pos: q4p, norm: qqw_48, },

      {pos: q6, norm: qqq_15, },
      {pos: q1p, norm: qqq_15, },
      {pos: q4p, norm: qqq_15, },
    /* #endregion */
    /* #region cell_qqq-w_17 */
      {pos: w7p, norm: qqw_49, },
      {pos: q1p, norm: qqw_49, },
      {pos: q2p, norm: qqw_49, },

      {pos: w7p, norm: qqw_50, },
      {pos: q2p, norm: qqw_50, },
      {pos: q5p, norm: qqw_50, },

      {pos: w7p, norm: qqw_51, },
      {pos: q5p, norm: qqw_51, },
      {pos: q1p, norm: qqw_51, },

      {pos: q1p, norm: qqq_19, },
      {pos: q5p, norm: qqq_19, },
      {pos: q2p, norm: qqq_19, },
    /* #endregion */
    /* #region cell_qqq-w_18 */
      {pos: w8p, norm: qqw_52, },
      {pos: q2p, norm: qqw_52, },
      {pos: q4, norm: qqw_52, },

      {pos: w8p, norm: qqw_53, },
      {pos: q5p, norm: qqw_53, },
      {pos: q2p, norm: qqw_53, },

      {pos: w8p, norm: qqw_54, },
      {pos: q4, norm: qqw_54, },
      {pos: q5p, norm: qqw_54, },

      {pos: q4, norm: qqq_11, },
      {pos: q2p, norm: qqq_11, },
      {pos: q5p, norm: qqq_11, },
    /* #endregion */
    /* #region cell_qqq-w_19 */
      {pos: w9p, norm: qqw_55, },
      {pos: q2p, norm: qqw_55, },
      {pos: q3p, norm: qqw_55, },

      {pos: w9p, norm: qqw_56, },
      {pos: q3p, norm: qqw_56, },
      {pos: q6p, norm: qqw_56, },

      {pos: w9p, norm: qqw_57, },
      {pos: q6p, norm: qqw_57, },
      {pos: q2p, norm: qqw_57, },

      {pos: q2p, norm: qqq_20, },
      {pos: q6p, norm: qqq_20, },
      {pos: q3p, norm: qqq_20, },
    /* #endregion */
    /* #region cell_qqq-w_20 */
      {pos: w10p, norm: qqw_58, },
      {pos: q3p, norm: qqw_58, },
      {pos: q5, norm: qqw_58, },

      {pos: w10p, norm: qqw_59, },
      {pos: q6p, norm: qqw_59, },
      {pos: q3p, norm: qqw_59, },

      {pos: w10p, norm: qqw_60, },
      {pos: q5, norm: qqw_60, },
      {pos: q6p, norm: qqw_60, },

      {pos: q5, norm: qqq_14, },
      {pos: q3p, norm: qqq_14, },
      {pos: q6p, norm: qqq_14, },
    /* #endregion */
    ]} else {
      verts2 = []
    }
  /* #endregion */
  /* #region qqww cells (30) */
  if (layer3) {
    verts3 = [
    /* #region cell_qqww_1 */
      {pos: w1, norm: qqw_1, },
      {pos: q2, norm: qqw_1, },
      {pos: q1, norm: qqw_1, },

      {pos: w7, norm: qqw_19, },
      {pos: q1, norm: qqw_19, },
      {pos: q2, norm: qqw_19, },

      {pos: w7, norm: qww_1, },
      {pos: w1, norm: qww_1, },
      {pos: q1, norm: qww_1, },
      
      {pos: w1, norm: qww_2, },
      {pos: w7, norm: qww_2, },
      {pos: q2, norm: qww_2, },
    /* #endregion */
    /* #region cell_qqww_2 */
      {pos: w1, norm: qqw_2, },
      {pos: q3, norm: qqw_2, },
      {pos: q2, norm: qqw_2, },

      {pos: w9, norm: qqw_25, },
      {pos: q2, norm: qqw_25, },
      {pos: q3, norm: qqw_25, },

      {pos: w1, norm: qww_3, },
      {pos: w9, norm: qww_3, },
      {pos: q2, norm: qww_3, },

      {pos: w9, norm: qww_4, },
      {pos: w1, norm: qww_4, },
      {pos: q3, norm: qww_4, },
    /* #endregion */
    /* #region cell_qqww_3 */
      {pos: w1, norm: qqw_3, },
      {pos: q3, norm: qqw_3, },
      {pos: q1, norm: qqw_3, },

      {pos: w5, norm: qqw_13, },
      {pos: q1, norm: qqw_13, },
      {pos: q3, norm: qqw_13, },

      {pos: w1, norm: qww_5, },
      {pos: w5, norm: qww_5, },
      {pos: q3, norm: qww_5, },

      {pos: w5, norm: qww_6, },
      {pos: w1, norm: qww_6, },
      {pos: q1, norm: qww_6, },
    /* #endregion */
    /* #region cell_qqww_4 */
      {pos: w2, norm: qqw_4, },
      {pos: q1, norm: qqw_4, },
      {pos: q5, norm: qqw_4, },

      {pos: w7, norm: qqw_21, },
      {pos: q5, norm: qqw_21, },
      {pos: q1, norm: qqw_21, },

      {pos: w2, norm: qww_7, },
      {pos: w7, norm: qww_7, },
      {pos: q1, norm: qww_7, },

      {pos: w7, norm: qww_8, },
      {pos: w2, norm: qww_8, },
      {pos: q5, norm: qww_8, },
    /* #endregion */
    /* #region cell_qqww_5 */
      {pos: w2, norm: qqw_5, },
      {pos: q5, norm: qqw_5, },
      {pos: q6p, norm: qqw_5, },

      {pos: w10p, norm: qqw_60, },
      {pos: q6p, norm: qqw_60, },
      {pos: q5, norm: qqw_60, },

      {pos: w2, norm: qww_9, },
      {pos: w10p, norm: qww_9, },
      {pos: q5, norm: qww_9, },

      {pos: w10p, norm: qww_10, },
      {pos: w2, norm: qww_10, },
      {pos: q6p, norm: qww_10, },
    /* #endregion */
    /* #region cell_qqww_6 */
      {pos: w2, norm: qqw_6, },
      {pos: q6p, norm: qqw_6, },
      {pos: q1, norm: qqw_6, },

      {pos: w6, norm: qqw_18, },
      {pos: q1, norm: qqw_18, },
      {pos: q6p, norm: qqw_18, },

      {pos: w2, norm: qww_11, },
      {pos: w6, norm: qww_11, },
      {pos: q6p, norm: qww_11, },

      {pos: w6, norm: qww_12, },
      {pos: w2, norm: qww_12, },
      {pos: q1, norm: qww_12, },
    /* #endregion */
    /* #region cell_qqww_7 */
      {pos: w3, norm: qqw_7, },
      {pos: q3, norm: qqw_7, },
      {pos: q4, norm: qqw_7, },

      {pos: w5, norm: qqw_14, },
      {pos: q4, norm: qqw_14, },
      {pos: q3, norm: qqw_14, },

      {pos: w3, norm: qww_13, },
      {pos: w5, norm: qww_13, },
      {pos: q3, norm: qww_13, },

      {pos: w5, norm: qww_14, },
      {pos: w3, norm: qww_14, },
      {pos: q4, norm: qww_14, },
    /* #endregion */
    /* #region cell_qqww_8 */
      {pos: w3, norm: qqw_8, },
      {pos: q4, norm: qqw_8, },
      {pos: q5p, norm: qqw_8, },

      {pos: w8p, norm: qqw_54, },
      {pos: q5p, norm: qqw_54, },
      {pos: q4, norm: qqw_54, },

      {pos: w3, norm: qww_15, },
      {pos: w8p, norm: qww_15, },
      {pos: q4, norm: qww_15, },

      {pos: w8p, norm: qww_16, },
      {pos: w3, norm: qww_16, },
      {pos: q5p, norm: qww_16, },
    /* #endregion */
    /* #region cell_qqww_9 */
      {pos: w3, norm: qqw_9, },
      {pos: q5p, norm: qqw_9, },
      {pos: q3, norm: qqw_9, },

      {pos: w10, norm: qqw_30, },
      {pos: q3, norm: qqw_30, },
      {pos: q5p, norm: qqw_30, },

      {pos: w3, norm: qww_17, },
      {pos: w10, norm: qww_17, },
      {pos: q5p, norm: qww_17, },

      {pos: w10, norm: qww_18, },
      {pos: w3, norm: qww_18, },
      {pos: q3, norm: qww_18, },
    /* #endregion */
    /* #region cell_qqww_10 */
      {pos: w4, norm: qqw_10, },
      {pos: q4, norm: qqw_10, },
      {pos: q2p, norm: qqw_10, },

      {pos: w8p, norm: qqw_52, },
      {pos: q2p, norm: qqw_52, },
      {pos: q4, norm: qqw_52, },

      {pos: w4, norm: qww_19, },
      {pos: w8p, norm: qww_19, },
      {pos: q4, norm: qww_19, },

      {pos: w8p, norm: qww_20, },
      {pos: w4, norm: qww_20, },
      {pos: q2p, norm: qww_20, },
    /* #endregion */
    /* #region cell_qqww_11 */
      {pos: w4, norm: qqw_11, },
      {pos: q2p, norm: qqw_11, },
      {pos: q6p, norm: qqw_11, },

      {pos: w9p, norm: qqw_57, },
      {pos: q6p, norm: qqw_57, },
      {pos: q2p, norm: qqw_57, },

      {pos: w4, norm: qww_21, },
      {pos: w9p, norm: qww_21, },
      {pos: q2p, norm: qww_21, },

      {pos: w9p, norm: qww_22, },
      {pos: w4, norm: qww_22, },
      {pos: q6p, norm: qww_22, },
    /* #endregion */
    /* #region cell_qqww_12 */
      {pos: w4, norm: qqw_12, },
      {pos: q6p, norm: qqw_12, },
      {pos: q4, norm: qqw_12, },

      {pos: w6, norm: qqw_17, },
      {pos: q4, norm: qqw_17, },
      {pos: q6p, norm: qqw_17, },

      {pos: w4, norm: qww_23, },
      {pos: w6, norm: qww_23, },
      {pos: q6p, norm: qww_23, },

      {pos: w6, norm: qww_24, },
      {pos: w4, norm: qww_24, },
      {pos: q4, norm: qww_24, },
    /* #endregion */
    /* #region cell_qqww_13 */
      {pos: w5, norm: qqw_15, },
      {pos: q4, norm: qqw_15, },
      {pos: q1, norm: qqw_15, },

      {pos: w6, norm: qqw_16, },
      {pos: q1, norm: qqw_16, },
      {pos: q4, norm: qqw_16, },

      {pos: w5, norm: qww_25, },
      {pos: w6, norm: qww_25, },
      {pos: q4, norm: qww_25, },

      {pos: w6, norm: qww_26, },
      {pos: w5, norm: qww_26, },
      {pos: q1, norm: qww_26, },
    /* #endregion */
    /* #region cell_qqww_14 */
      {pos: w7, norm: qqw_20, },
      {pos: q2, norm: qqw_20, },
      {pos: q5, norm: qqw_20, },

      {pos: w8, norm: qqw_22, },
      {pos: q5, norm: qqw_22, },
      {pos: q2, norm: qqw_22, },

      {pos: w7, norm: qww_27, },
      {pos: w8, norm: qww_27, },
      {pos: q2, norm: qww_27, },

      {pos: w8, norm: qww_28, },
      {pos: w7, norm: qww_28, },
      {pos: q5, norm: qww_28, },
    /* #endregion */
    /* #region cell_qqww_15 */
      {pos: w8, norm: qqw_23, },
      {pos: q5, norm: qqw_23, },
      {pos: q4p, norm: qqw_23, },

      {pos: w3p, norm: qqw_39, },
      {pos: q4p, norm: qqw_39, },
      {pos: q5, norm: qqw_39, },

      {pos: w8, norm: qww_29, },
      {pos: w3p, norm: qww_29, },
      {pos: q5, norm: qww_29, },

      {pos: w3p, norm: qww_30, },
      {pos: w8, norm: qww_30, },
      {pos: q4p, norm: qww_30, },
    /* #endregion */
    /* #region cell_qqww_16 */
      {pos: w8, norm: qqw_24, },
      {pos: q4p, norm: qqw_24, },
      {pos: q2, norm: qqw_24, },

      {pos: w4p, norm: qqw_42, },
      {pos: q2, norm: qqw_42, },
      {pos: q4p, norm: qqw_42, },

      {pos: w8, norm: qww_31, },
      {pos: w4p, norm: qww_31, },
      {pos: q4p, norm: qww_31, },

      {pos: w4p, norm: qww_32, },
      {pos: w8, norm: qww_32, },
      {pos: q2, norm: qww_32, },
    /* #endregion */
    /* #region cell_qqww_17 */
      {pos: w9, norm: qqw_26, },
      {pos: q3, norm: qqw_26, },
      {pos: q6, norm: qqw_26, },

      {pos: w10, norm: qqw_28, },
      {pos: q6, norm: qqw_28, },
      {pos: q3, norm: qqw_28, },

      {pos: w9, norm: qww_33, },
      {pos: w10, norm: qww_33, },
      {pos: q3, norm: qww_33, },

      {pos: w10, norm: qww_34, },
      {pos: w9, norm: qww_34, },
      {pos: q6, norm: qww_34, },
    /* #endregion */
    /* #region cell_qqww_18 */
      {pos: w9, norm: qqw_27, },
      {pos: q6, norm: qqw_27, },
      {pos: q2, norm: qqw_27, },

      {pos: w4p, norm: qqw_40, },
      {pos: q2, norm: qqw_40, },
      {pos: q6, norm: qqw_40, },

      {pos: w9, norm: qww_35, },
      {pos: w4p, norm: qww_35, },
      {pos: q6, norm: qww_35, },

      {pos: w4p, norm: qww_36, },
      {pos: w9, norm: qww_36, },
      {pos: q2, norm: qww_36, },
    /* #endregion */
    /* #region cell_qqww_19 */
      {pos: w10, norm: qqw_29, },
      {pos: q6, norm: qqw_29, },
      {pos: q5p, norm: qqw_29, },

      {pos: w2p, norm: qqw_36, },
      {pos: q5p, norm: qqw_36, },
      {pos: q6, norm: qqw_36, },

      {pos: w10, norm: qww_37, },
      {pos: w2p, norm: qww_37, },
      {pos: q6, norm: qww_37, },

      {pos: w2p, norm: qww_38, },
      {pos: w10, norm: qww_38, },
      {pos: q5p, norm: qww_38, },
    /* #endregion */
    /* #region cell_qqww_20 */
      {pos: w1p, norm: qqw_31, },
      {pos: q1p, norm: qqw_31, },
      {pos: q2p, norm: qqw_31, },

      {pos: w7p, norm: qqw_49, },
      {pos: q2p, norm: qqw_49, },
      {pos: q1p, norm: qqw_49, },

      {pos: w1p, norm: qww_39, },
      {pos: w7p, norm: qww_39, },
      {pos: q1p, norm: qww_39, },

      {pos: w7p, norm: qww_40, },
      {pos: w1p, norm: qww_40, },
      {pos: q2p, norm: qww_40, },
    /* #endregion */
    /* #region cell_qqww_21 */
      {pos: w1p, norm: qqw_32, },
      {pos: q2p, norm: qqw_32, },
      {pos: q3p, norm: qqw_32, },

      {pos: w9p, norm: qqw_55, },
      {pos: q3p, norm: qqw_55, },
      {pos: q2p, norm: qqw_55, },

      {pos: w1p, norm: qww_41, },
      {pos: w9p, norm: qww_41, },
      {pos: q2p, norm: qww_41, },

      {pos: w9p, norm: qww_42, },
      {pos: w1p, norm: qww_42, },
      {pos: q3p, norm: qww_42, },
    /* #endregion */
    /* #region cell_qqww_22 */
      {pos: w1p, norm: qqw_33, },
      {pos: q3p, norm: qqw_33, },
      {pos: q1p, norm: qqw_33, },

      {pos: w5p, norm: qqw_43, },
      {pos: q1p, norm: qqw_43, },
      {pos: q3p, norm: qqw_43, },

      {pos: w1p, norm: qww_43, },
      {pos: w5p, norm: qww_43, },
      {pos: q3p, norm: qww_43, },

      {pos: w5p, norm: qww_44, },
      {pos: w1p, norm: qww_44, },
      {pos: q1p, norm: qww_44, },
    /* #endregion */
    /* #region cell_qqww_23 */
      {pos: w2p, norm: qqw_34, },
      {pos: q6, norm: qqw_34, },
      {pos: q1p, norm: qqw_34, },

      {pos: w6p, norm: qqw_46, },
      {pos: q1p, norm: qqw_46, },
      {pos: q6, norm: qqw_46, },

      {pos: w2p, norm: qww_45, },
      {pos: w6p, norm: qww_45, },
      {pos: q6, norm: qww_45, },

      {pos: w6p, norm: qww_46, },
      {pos: w2p, norm: qww_46, },
      {pos: q1p, norm: qww_46, },
    /* #endregion */
    /* #region cell_qqww_24 */
      {pos: w2p, norm: qqw_35, },
      {pos: q1p, norm: qqw_35, },
      {pos: q5p, norm: qqw_35, },

      {pos: w7p, norm: qqw_51, },
      {pos: q5p, norm: qqw_51, },
      {pos: q1p, norm: qqw_51, },

      {pos: w2p, norm: qww_47, },
      {pos: w7p, norm: qww_47, },
      {pos: q1p, norm: qww_47, },

      {pos: w7p, norm: qww_48, },
      {pos: w2p, norm: qww_48, },
      {pos: q5p, norm: qww_48, },
    /* #endregion */
    /* #region cell_qqww_25 */
      {pos: w3p, norm: qqw_37, },
      {pos: q5, norm: qqw_37, },
      {pos: q3p, norm: qqw_37, },

      {pos: w10p, norm: qqw_58, },
      {pos: q3p, norm: qqw_58, },
      {pos: q5, norm: qqw_58, },

      {pos: w3p, norm: qww_49, },
      {pos: w10p, norm: qww_49, },
      {pos: q5, norm: qww_49, },

      {pos: w10p, norm: qww_50, },
      {pos: w3p, norm: qww_50, },
      {pos: q3p, norm: qww_50, },
    /* #endregion */
    /* #region cell_qqww_26 */
      {pos: w3p, norm: qqw_38, },
      {pos: q3p, norm: qqw_38, },
      {pos: q4p, norm: qqw_38, },

      {pos: w5p, norm: qqw_44, },
      {pos: q4p, norm: qqw_44, },
      {pos: q3p, norm: qqw_44, },

      {pos: w3p, norm: qww_51, },
      {pos: w5p, norm: qww_51, },
      {pos: q3p, norm: qww_51, },

      {pos: w5p, norm: qww_52, },
      {pos: w3p, norm: qww_52, },
      {pos: q4p, norm: qww_52, },
    /* #endregion */
    /* #region cell_qqww_27 */
      {pos: w4p, norm: qqw_41, },
      {pos: q6, norm: qqw_41, },
      {pos: q4p, norm: qqw_41, },

      {pos: w6p, norm: qqw_48, },
      {pos: q4p, norm: qqw_48, },
      {pos: q6, norm: qqw_48, },

      {pos: w4p, norm: qww_53, },
      {pos: w6p, norm: qww_53, },
      {pos: q6, norm: qww_53, },

      {pos: w6p, norm: qww_54, },
      {pos: w4p, norm: qww_54, },
      {pos: q4p, norm: qww_54, },
    /* #endregion */
    /* #region cell_qqww_28 */
      {pos: w5p, norm: qqw_45, },
      {pos: q4p, norm: qqw_45, },
      {pos: q1p, norm: qqw_45, },

      {pos: w6p, norm: qqw_47, },
      {pos: q1p, norm: qqw_47, },
      {pos: q4p, norm: qqw_47, },

      {pos: w5p, norm: qww_55, },
      {pos: w6p, norm: qww_55, },
      {pos: q4p, norm: qww_55, },

      {pos: w6p, norm: qww_56, },
      {pos: w5p, norm: qww_56, },
      {pos: q1p, norm: qww_56, },
    /* #endregion */
    /* #region cell_qqww_29 */
      {pos: w7p, norm: qqw_50, },
      {pos: q2p, norm: qqw_50, },
      {pos: q5p, norm: qqw_50, },

      {pos: w8p, norm: qqw_53, },
      {pos: q5p, norm: qqw_53, },
      {pos: q2p, norm: qqw_53, },

      {pos: w7p, norm: qww_57, },
      {pos: w8p, norm: qww_57, },
      {pos: q2p, norm: qww_57, },

      {pos: w8p, norm: qww_58, },
      {pos: w7p, norm: qww_58, },
      {pos: q5p, norm: qww_58, },
    /* #endregion */
    /* #region cell_qqww_30 */
      {pos: w9p, norm: qqw_56, },
      {pos: q3p, norm: qqw_56, },
      {pos: q6p, norm: qqw_56, },

      {pos: w10p, norm: qqw_59, },
      {pos: q6p, norm: qqw_59, },
      {pos: q3p, norm: qqw_59, },

      {pos: w9p, norm: qww_59, },
      {pos: w10p, norm: qww_59, },
      {pos: q3p, norm: qww_59, },

      {pos: w10p, norm: qww_60, },
      {pos: w9p, norm: qww_60, },
      {pos: q6p, norm: qww_60, },
    /* #endregion */
    ]} else {
    verts3 = []
  }
  /* #endregion */
  /* #region qwwv cells (60) */
  if (layer4) {
    verts4 = [
    /* #region cell_qwwv_1 */
      {pos: q1, norm: qwv_1, },
      {pos: v1, norm: qwv_1, },
      {pos: w1, norm: qwv_1, },

      {pos: v1, norm: qwv_3, },
      {pos: q1, norm: qwv_3, },
      {pos: w5, norm: qwv_3, },

      {pos: w1, norm: wwv_6, },
      {pos: v1, norm: wwv_6, },
      {pos: w5, norm: wwv_6, },

      {pos: w5, norm: qww_6, },
      {pos: q1, norm: qww_6, },
      {pos: w1, norm: qww_6, },
    /* #endregion */
    /* #region cell_qwwv_2 */
      {pos: q1, norm: qwv_3, },
      {pos: v1, norm: qwv_3, },
      {pos: w5, norm: qwv_3, },

      {pos: v1, norm: qwv_4, },
      {pos: q1, norm: qwv_4, },
      {pos: w6, norm: qwv_4, },

      {pos: w5, norm: wwv_26, },
      {pos: v1, norm: wwv_26, },
      {pos: w6, norm: wwv_26, },

      {pos: w6, norm: qww_26, },
      {pos: q1, norm: qww_26, },
      {pos: w5, norm: qww_26, },
    /* #endregion */
    /* #region cell_qwwv_3 */
      {pos: q1, norm: qwv_4, },
      {pos: v1, norm: qwv_4, },
      {pos: w6, norm: qwv_4, },

      {pos: v1, norm: qwv_2, },
      {pos: q1, norm: qwv_2, },
      {pos: w2, norm: qwv_2, },

      {pos: w6, norm: wwv_12, },
      {pos: v1, norm: wwv_12, },
      {pos: w2, norm: wwv_12, },

      {pos: w2, norm: qww_12, },
      {pos: q1, norm: qww_12, },
      {pos: w6, norm: qww_12, },
    /* #endregion */
    /* #region cell_qwwv_4 */
      {pos: q1, norm: qwv_2, },
      {pos: v1, norm: qwv_2, },
      {pos: w2, norm: qwv_2, },

      {pos: v1, norm: qwv_5, },
      {pos: q1, norm: qwv_5, },
      {pos: w7, norm: qwv_5, },

      {pos: w2, norm: wwv_7, },
      {pos: v1, norm: wwv_7, },
      {pos: w7, norm: wwv_7, },

      {pos: w7, norm: qww_7, },
      {pos: q1, norm: qww_7, },
      {pos: w2, norm: qww_7, },
    /* #endregion */
    /* #region cell_qwwv_5 */
      {pos: q1, norm: qwv_5, },
      {pos: v1, norm: qwv_5, },
      {pos: w7, norm: qwv_5, },

      {pos: v1, norm: qwv_1, },
      {pos: q1, norm: qwv_1, },
      {pos: w1, norm: qwv_1, },

      {pos: w7, norm: wwv_1, },
      {pos: v1, norm: wwv_1, },
      {pos: w1, norm: wwv_1, },

      {pos: w1, norm: qww_1, },
      {pos: q1, norm: qww_1, },
      {pos: w7, norm: qww_1, },
    /* #endregion */
    /* #region cell_qwwv_6 */
      {pos: q2, norm: qwv_7, },
      {pos: v2, norm: qwv_7, },
      {pos: w7, norm: qwv_7, },

      {pos: v2, norm: qwv_6, },
      {pos: q2, norm: qwv_6, },
      {pos: w1, norm: qwv_6, },

      {pos: w7, norm: wwv_2, },
      {pos: v2, norm: wwv_2, },
      {pos: w1, norm: wwv_2, },

      {pos: w1, norm: qww_2, },
      {pos: q2, norm: qww_2, },
      {pos: w7, norm: qww_2, },
    /* #endregion */
    /* #region cell_qwwv_7 */
      {pos: q2, norm: qwv_6, },
      {pos: v2, norm: qwv_6, },
      {pos: w1, norm: qwv_6, },

      {pos: v2, norm: qwv_9, },
      {pos: q2, norm: qwv_9, },
      {pos: w9, norm: qwv_9, },

      {pos: w1, norm: wwv_3, },
      {pos: v2, norm: wwv_3, },
      {pos: w9, norm: wwv_3, },

      {pos: w9, norm: qww_3, },
      {pos: q2, norm: qww_3, },
      {pos: w1, norm: qww_3, },
    /* #endregion */
    /* #region cell_qwwv_8 */
      {pos: q2, norm: qwv_9, },
      {pos: v2, norm: qwv_9, },
      {pos: w9, norm: qwv_9, },

      {pos: v2, norm: qwv_10, },
      {pos: q2, norm: qwv_10, },
      {pos: w4p, norm: qwv_10, },

      {pos: w9, norm: wwv_36, },
      {pos: v2, norm: wwv_36, },
      {pos: w4p, norm: wwv_36, },

      {pos: w4p, norm: qww_36, },
      {pos: q2, norm: qww_36, },
      {pos: w9, norm: qww_36, },
    /* #endregion */
    /* #region cell_qwwv_9 */
      {pos: q2, norm: qwv_10, },
      {pos: v2, norm: qwv_10, },
      {pos: w4p, norm: qwv_10, },

      {pos: v2, norm: qwv_8, },
      {pos: q2, norm: qwv_8, },
      {pos: w8, norm: qwv_8, },

      {pos: w4p, norm: wwv_32, },
      {pos: v2, norm: wwv_32, },
      {pos: w8, norm: wwv_32, },

      {pos: w8, norm: qww_32, },
      {pos: q2, norm: qww_32, },
      {pos: w4p, norm: qww_32, },
    /* #endregion */
    /* #region cell_qwwv_10 */
      {pos: q2, norm: qwv_8, },
      {pos: v2, norm: qwv_8, },
      {pos: w8, norm: qwv_8, },

      {pos: v2, norm: qwv_7, },
      {pos: q2, norm: qwv_7, },
      {pos: w7, norm: qwv_7, },

      {pos: w8, norm: wwv_27, },
      {pos: v2, norm: wwv_27, },
      {pos: w7, norm: wwv_27, },

      {pos: w7, norm: qww_27, },
      {pos: q2, norm: qww_27, },
      {pos: w8, norm: qww_27, },
    /* #endregion */
    /* #region cell_qwwv_11 */
      {pos: q3, norm: qwv_11, },
      {pos: v3, norm: qwv_11, },
      {pos: w1, norm: qwv_11, },

      {pos: v3, norm: qwv_13, },
      {pos: q3, norm: qwv_13, },
      {pos: w5, norm: qwv_13, },

      {pos: w1, norm: wwv_5, },
      {pos: v3, norm: wwv_5, },
      {pos: w5, norm: wwv_5, },

      {pos: w5, norm: qww_5, },
      {pos: q3, norm: qww_5, },
      {pos: w1, norm: qww_5, },
    /* #endregion */
    /* #region cell_qwwv_12 */
      {pos: q3, norm: qwv_13, },
      {pos: v3, norm: qwv_13, },
      {pos: w5, norm: qwv_13, },

      {pos: v3, norm: qwv_12, },
      {pos: q3, norm: qwv_12, },
      {pos: w3, norm: qwv_12, },

      {pos: w5, norm: wwv_13, },
      {pos: v3, norm: wwv_13, },
      {pos: w3, norm: wwv_13, },

      {pos: w3, norm: qww_13, },
      {pos: q3, norm: qww_13, },
      {pos: w5, norm: qww_13, },
    /* #endregion */
    /* #region cell_qwwv_13 */
      {pos: q3, norm: qwv_12, },
      {pos: v3, norm: qwv_12, },
      {pos: w3, norm: qwv_12, },

      {pos: v3, norm: qwv_15, },
      {pos: q3, norm: qwv_15, },
      {pos: w10, norm: qwv_15, },

      {pos: w3, norm: wwv_18, },
      {pos: v3, norm: wwv_18, },
      {pos: w10, norm: wwv_18, },

      {pos: w10, norm: qww_18, },
      {pos: q3, norm: qww_18, },
      {pos: w3, norm: qww_18, },
    /* #endregion */
    /* #region cell_qwwv_14 */
      {pos: q3, norm: qwv_15, },
      {pos: v3, norm: qwv_15, },
      {pos: w10, norm: qwv_15, },

      {pos: v3, norm: qwv_14, },
      {pos: q3, norm: qwv_14, },
      {pos: w9, norm: qwv_14, },

      {pos: w10, norm: wwv_33, },
      {pos: v3, norm: wwv_33, },
      {pos: w9, norm: wwv_33, },

      {pos: w9, norm: qww_33, },
      {pos: q3, norm: qww_33, },
      {pos: w10, norm: qww_33, },
    /* #endregion */
    /* #region cell_qwwv_15 */
      {pos: q3, norm: qwv_14, },
      {pos: v3, norm: qwv_14, },
      {pos: w9, norm: qwv_14, },

      {pos: v3, norm: qwv_11, },
      {pos: q3, norm: qwv_11, },
      {pos: w1, norm: qwv_11, },

      {pos: w9, norm: wwv_4, },
      {pos: v3, norm: wwv_4, },
      {pos: w1, norm: wwv_4, },

      {pos: w1, norm: qww_4, },
      {pos: q3, norm: qww_4, },
      {pos: w9, norm: qww_4, },
    /* #endregion */
    /* #region cell_qwwv_16 */
      {pos: q4, norm: qwv_16, },
      {pos: v4, norm: qwv_16, },
      {pos: w3, norm: qwv_16, },

      {pos: v4, norm: qwv_18, },
      {pos: q4, norm: qwv_18, },
      {pos: w5, norm: qwv_18, },

      {pos: w3, norm: wwv_14, },
      {pos: v4, norm: wwv_14, },
      {pos: w5, norm: wwv_14, },

      {pos: w5, norm: qww_14, },
      {pos: q4, norm: qww_14, },
      {pos: w3, norm: qww_14, },
    /* #endregion */
    /* #region cell_qwwv_17 */
      {pos: q4, norm: qwv_18, },
      {pos: v4, norm: qwv_18, },
      {pos: w5, norm: qwv_18, },

      {pos: v4, norm: qwv_19, },
      {pos: q4, norm: qwv_19, },
      {pos: w6, norm: qwv_19, },

      {pos: w5, norm: wwv_25, },
      {pos: v4, norm: wwv_25, },
      {pos: w6, norm: wwv_25, },

      {pos: w6, norm: qww_25, },
      {pos: q4, norm: qww_25, },
      {pos: w5, norm: qww_25, },
    /* #endregion */
    /* #region cell_qwwv_18 */
      {pos: q4, norm: qwv_19, },
      {pos: v4, norm: qwv_19, },
      {pos: w6, norm: qwv_19, },

      {pos: v4, norm: qwv_17, },
      {pos: q4, norm: qwv_17, },
      {pos: w4, norm: qwv_17, },

      {pos: w6, norm: wwv_24, },
      {pos: v4, norm: wwv_24, },
      {pos: w4, norm: wwv_24, },

      {pos: w4, norm: qww_24, },
      {pos: q4, norm: qww_24, },
      {pos: w6, norm: qww_24, },
    /* #endregion */
    /* #region cell_qwwv_19 */
      {pos: q4, norm: qwv_17, },
      {pos: v4, norm: qwv_17, },
      {pos: w4, norm: qwv_17, },

      {pos: v4, norm: qwv_20, },
      {pos: q4, norm: qwv_20, },
      {pos: w8p, norm: qwv_20, },

      {pos: w4, norm: wwv_19, },
      {pos: v4, norm: wwv_19, },
      {pos: w8p, norm: wwv_19, },

      {pos: w8p, norm: qww_19, },
      {pos: q4, norm: qww_19, },
      {pos: w4, norm: qww_19, },
    /* #endregion */
    /* #region cell_qwwv_20 */
      {pos: q4, norm: qwv_20, },
      {pos: v4, norm: qwv_20, },
      {pos: w8p, norm: qwv_20, },

      {pos: v4, norm: qwv_16, },
      {pos: q4, norm: qwv_16, },
      {pos: w3, norm: qwv_16, },

      {pos: w8p, norm: wwv_15, },
      {pos: v4, norm: wwv_15, },
      {pos: w3, norm: wwv_15, },

      {pos: w3, norm: qww_15, },
      {pos: q4, norm: qww_15, },
      {pos: w8p, norm: qww_15, },
    /* #endregion */
    /* #region cell_qwwv_21 */
      {pos: q5, norm: qwv_21, },
      {pos: v5, norm: qwv_21, },
      {pos: w2, norm: qwv_21, },

      {pos: v5, norm: qwv_22, },
      {pos: q5, norm: qwv_22, },
      {pos: w7, norm: qwv_22, },

      {pos: w2, norm: wwv_8, },
      {pos: v5, norm: wwv_8, },
      {pos: w7, norm: wwv_8, },

      {pos: w7, norm: qww_8, },
      {pos: q5, norm: qww_8, },
      {pos: w2, norm: qww_8, },
    /* #endregion */
    /* #region cell_qwwv_22 */
      {pos: q5, norm: qwv_22, },
      {pos: v5, norm: qwv_22, },
      {pos: w7, norm: qwv_22, },

      {pos: v5, norm: qwv_23, },
      {pos: q5, norm: qwv_23, },
      {pos: w8, norm: qwv_23, },

      {pos: w7, norm: wwv_28, },
      {pos: v5, norm: wwv_28, },
      {pos: w8, norm: wwv_28, },

      {pos: w8, norm: qww_28, },
      {pos: q5, norm: qww_28, },
      {pos: w7, norm: qww_28, },
    /* #endregion */
    /* #region cell_qwwv_23 */
      {pos: q5, norm: qwv_23, },
      {pos: v5, norm: qwv_23, },
      {pos: w8, norm: qwv_23, },

      {pos: v5, norm: qwv_24, },
      {pos: q5, norm: qwv_24, },
      {pos: w3p, norm: qwv_24, },

      {pos: w8, norm: wwv_29, },
      {pos: v5, norm: wwv_29, },
      {pos: w3p, norm: wwv_29, },

      {pos: w3p, norm: qww_29, },
      {pos: q5, norm: qww_29, },
      {pos: w8, norm: qww_29, },
    /* #endregion */
    /* #region cell_qwwv_24 */
      {pos: q5, norm: qwv_24, },
      {pos: v5, norm: qwv_24, },
      {pos: w3p, norm: qwv_24, },

      {pos: v5, norm: qwv_25, },
      {pos: q5, norm: qwv_25, },
      {pos: w10p, norm: qwv_25, },

      {pos: w3p, norm: wwv_49, },
      {pos: v5, norm: wwv_49, },
      {pos: w10p, norm: wwv_49, },

      {pos: w10p, norm: qww_49, },
      {pos: q5, norm: qww_49, },
      {pos: w3p, norm: qww_49, },
    /* #endregion */
    /* #region cell_qwwv_25 */
      {pos: q5, norm: qwv_25, },
      {pos: v5, norm: qwv_25, },
      {pos: w10p, norm: qwv_25, },

      {pos: v5, norm: qwv_21, },
      {pos: q5, norm: qwv_21, },
      {pos: w2, norm: qwv_21, },

      {pos: w10p, norm: wwv_9, },
      {pos: v5, norm: wwv_9, },
      {pos: w2, norm: wwv_9, },

      {pos: w2, norm: qww_9, },
      {pos: q5, norm: qww_9, },
      {pos: w10p, norm: qww_9, },
    /* #endregion */
    /* #region cell_qwwv_26 */
      {pos: q6, norm: qwv_26, },
      {pos: v6, norm: qwv_26, },
      {pos: w9, norm: qwv_26, },

      {pos: v6, norm: qwv_27, },
      {pos: q6, norm: qwv_27, },
      {pos: w10, norm: qwv_27, },

      {pos: w9, norm: wwv_34, },
      {pos: v6, norm: wwv_34, },
      {pos: w10, norm: wwv_34, },

      {pos: w10, norm: qww_34, },
      {pos: q6, norm: qww_34, },
      {pos: w9, norm: qww_34, },
    /* #endregion */
    /* #region cell_qwwv_27 */
      {pos: q6, norm: qwv_27, },
      {pos: v6, norm: qwv_27, },
      {pos: w10, norm: qwv_27, },

      {pos: v6, norm: qwv_28, },
      {pos: q6, norm: qwv_28, },
      {pos: w2p, norm: qwv_28, },

      {pos: w10, norm: wwv_37, },
      {pos: v6, norm: wwv_37, },
      {pos: w2p, norm: wwv_37, },

      {pos: w2p, norm: qww_37, },
      {pos: q6, norm: qww_37, },
      {pos: w10, norm: qww_37, },
    /* #endregion */
    /* #region cell_qwwv_28 */
      {pos: q6, norm: qwv_28, },
      {pos: v6, norm: qwv_28, },
      {pos: w2p, norm: qwv_28, },

      {pos: v6, norm: qwv_30, },
      {pos: q6, norm: qwv_30, },
      {pos: w6p, norm: qwv_30, },

      {pos: w2p, norm: wwv_45, },
      {pos: v6, norm: wwv_45, },
      {pos: w6p, norm: wwv_45, },

      {pos: w6p, norm: qww_45, },
      {pos: q6, norm: qww_45, },
      {pos: w2p, norm: qww_45, },
    /* #endregion */
    /* #region cell_qwwv_29 */
      {pos: q6, norm: qwv_30, },
      {pos: v6, norm: qwv_30, },
      {pos: w6p, norm: qwv_30, },

      {pos: v6, norm: qwv_29, },
      {pos: q6, norm: qwv_29, },
      {pos: w4p, norm: qwv_29, },

      {pos: w6p, norm: wwv_53, },
      {pos: v6, norm: wwv_53, },
      {pos: w4p, norm: wwv_53, },

      {pos: w4p, norm: qww_53, },
      {pos: q6, norm: qww_53, },
      {pos: w6p, norm: qww_53, },
    /* #endregion */
    /* #region cell_qwwv_30 */
      {pos: q6, norm: qwv_29, },
      {pos: v6, norm: qwv_29, },
      {pos: w4p, norm: qwv_29, },

      {pos: v6, norm: qwv_26, },
      {pos: q6, norm: qwv_26, },
      {pos: w9, norm: qwv_26, },

      {pos: w4p, norm: wwv_35, },
      {pos: v6, norm: wwv_35, },
      {pos: w9, norm: wwv_35, },

      {pos: w9, norm: qww_35, },
      {pos: q6, norm: qww_35, },
      {pos: w4p, norm: qww_35, },
    /* #endregion */
    /* #region cell_qwwv_31 */
      {pos: q1p, norm: qwv_31, },
      {pos: v1p, norm: qwv_31, },
      {pos: w1p, norm: qwv_31, },

      {pos: v1p, norm: qwv_33, },
      {pos: q1p, norm: qwv_33, },
      {pos: w5p, norm: qwv_33, },

      {pos: w1p, norm: wwv_44, },
      {pos: v1p, norm: wwv_44, },
      {pos: w5p, norm: wwv_44, },

      {pos: w5p, norm: qww_44, },
      {pos: q1p, norm: qww_44, },
      {pos: w1p, norm: qww_44, },
    /* #endregion */
    /* #region cell_qwwv_32 */
      {pos: q1p, norm: qwv_33, },
      {pos: v1p, norm: qwv_33, },
      {pos: w5p, norm: qwv_33, },

      {pos: v1p, norm: qwv_34, },
      {pos: q1p, norm: qwv_34, },
      {pos: w6p, norm: qwv_34, },

      {pos: w5p, norm: wwv_56, },
      {pos: v1p, norm: wwv_56, },
      {pos: w6p, norm: wwv_56, },

      {pos: w6p, norm: qww_56, },
      {pos: q1p, norm: qww_56, },
      {pos: w5p, norm: qww_56, },
    /* #endregion */
    /* #region cell_qwwv_33 */
      {pos: q1p, norm: qwv_34, },
      {pos: v1p, norm: qwv_34, },
      {pos: w6p, norm: qwv_34, },

      {pos: v1p, norm: qwv_32, },
      {pos: q1p, norm: qwv_32, },
      {pos: w2p, norm: qwv_32, },

      {pos: w6p, norm: wwv_46, },
      {pos: v1p, norm: wwv_46, },
      {pos: w2p, norm: wwv_46, },

      {pos: w2p, norm: qww_46, },
      {pos: q1p, norm: qww_46, },
      {pos: w6p, norm: qww_46, },
    /* #endregion */
    /* #region cell_qwwv_34 */
      {pos: q1p, norm: qwv_32, },
      {pos: v1p, norm: qwv_32, },
      {pos: w2p, norm: qwv_32, },

      {pos: v1p, norm: qwv_35, },
      {pos: q1p, norm: qwv_35, },
      {pos: w7p, norm: qwv_35, },

      {pos: w2p, norm: wwv_47, },
      {pos: v1p, norm: wwv_47, },
      {pos: w7p, norm: wwv_47, },

      {pos: w7p, norm: qww_47, },
      {pos: q1p, norm: qww_47, },
      {pos: w2p, norm: qww_47, },
    /* #endregion */
    /* #region cell_qwwv_35 */
      {pos: q1p, norm: qwv_35, },
      {pos: v1p, norm: qwv_35, },
      {pos: w7p, norm: qwv_35, },

      {pos: v1p, norm: qwv_31, },
      {pos: q1p, norm: qwv_31, },
      {pos: w1p, norm: qwv_31, },

      {pos: w7p, norm: wwv_39, },
      {pos: v1p, norm: wwv_39, },
      {pos: w1p, norm: wwv_39, },

      {pos: w1p, norm: qww_39, },
      {pos: q1p, norm: qww_39, },
      {pos: w7p, norm: qww_39, },
    /* #endregion */
    /* #region cell_qwwv_36 */
      {pos: q2p, norm: qwv_38, },
      {pos: v2p, norm: qwv_38, },
      {pos: w7p, norm: qwv_38, },

      {pos: v2p, norm: qwv_37, },
      {pos: q2p, norm: qwv_37, },
      {pos: w1p, norm: qwv_37, },

      {pos: w7p, norm: wwv_40, },
      {pos: v2p, norm: wwv_40, },
      {pos: w1p, norm: wwv_40, },

      {pos: w1p, norm: qww_40, },
      {pos: q2p, norm: qww_40, },
      {pos: w7p, norm: qww_40, },
    /* #endregion */
    /* #region cell_qwwv_37 */
      {pos: q2p, norm: qwv_37, },
      {pos: v2p, norm: qwv_37, },
      {pos: w1p, norm: qwv_37, },

      {pos: v2p, norm: qwv_40, },
      {pos: q2p, norm: qwv_40, },
      {pos: w9p, norm: qwv_40, },

      {pos: w1p, norm: wwv_41, },
      {pos: v2p, norm: wwv_41, },
      {pos: w9p, norm: wwv_41, },

      {pos: w9p, norm: qww_41, },
      {pos: q2p, norm: qww_41, },
      {pos: w1p, norm: qww_41, },
    /* #endregion */
    /* #region cell_qwwv_38 */
      {pos: q2p, norm: qwv_40, },
      {pos: v2p, norm: qwv_40, },
      {pos: w9p, norm: qwv_40, },

      {pos: v2p, norm: qwv_36, },
      {pos: q2p, norm: qwv_36, },
      {pos: w4, norm: qwv_36, },

      {pos: w9p, norm: wwv_21, },
      {pos: v2p, norm: wwv_21, },
      {pos: w4, norm: wwv_21, },

      {pos: w4, norm: qww_21, },
      {pos: q2p, norm: qww_21, },
      {pos: w9p, norm: qww_21, },
    /* #endregion */
    /* #region cell_qwwv_39 */
      {pos: q2p, norm: qwv_36, },
      {pos: v2p, norm: qwv_36, },
      {pos: w4, norm: qwv_36, },

      {pos: v2p, norm: qwv_39, },
      {pos: q2p, norm: qwv_39, },
      {pos: w8p, norm: qwv_39, },

      {pos: w4, norm: wwv_20, },
      {pos: v2p, norm: wwv_20, },
      {pos: w8p, norm: wwv_20, },

      {pos: w8p, norm: qww_20, },
      {pos: q2p, norm: qww_20, },
      {pos: w4, norm: qww_20, },
    /* #endregion */
    /* #region cell_qwwv_40 */
      {pos: q2p, norm: qwv_39, },
      {pos: v2p, norm: qwv_39, },
      {pos: w8p, norm: qwv_39, },

      {pos: v2p, norm: qwv_38, },
      {pos: q2p, norm: qwv_38, },
      {pos: w7p, norm: qwv_38, },

      {pos: w8p, norm: wwv_57, },
      {pos: v2p, norm: wwv_57, },
      {pos: w7p, norm: wwv_57, },

      {pos: w7p, norm: qww_57, },
      {pos: q2p, norm: qww_57, },
      {pos: w8p, norm: qww_57, },
    /* #endregion */
    /* #region cell_qwwv_41 */
      {pos: q3p, norm: qwv_41, },
      {pos: v3p, norm: qwv_41, },
      {pos: w1p, norm: qwv_41, },

      {pos: v3p, norm: qwv_43, },
      {pos: q3p, norm: qwv_43, },
      {pos: w5p, norm: qwv_43, },

      {pos: w1p, norm: wwv_43, },
      {pos: v3p, norm: wwv_43, },
      {pos: w5p, norm: wwv_43, },

      {pos: w5p, norm: qww_43, },
      {pos: q3p, norm: qww_43, },
      {pos: w1p, norm: qww_43, },
    /* #endregion */
    /* #region cell_qwwv_42 */
      {pos: q3p, norm: qwv_43, },
      {pos: v3p, norm: qwv_43, },
      {pos: w5p, norm: qwv_43, },

      {pos: v3p, norm: qwv_42, },
      {pos: q3p, norm: qwv_42, },
      {pos: w3p, norm: qwv_42, },

      {pos: w5p, norm: wwv_51, },
      {pos: v3p, norm: wwv_51, },
      {pos: w3p, norm: wwv_51, },

      {pos: w3p, norm: qww_51, },
      {pos: q3p, norm: qww_51, },
      {pos: w5p, norm: qww_51, },
    /* #endregion */
    /* #region cell_qwwv_43 */
      {pos: q3p, norm: qwv_42, },
      {pos: v3p, norm: qwv_42, },
      {pos: w3p, norm: qwv_42, },

      {pos: v3p, norm: qwv_45, },
      {pos: q3p, norm: qwv_45, },
      {pos: w10p, norm: qwv_45, },

      {pos: w3p, norm: wwv_50, },
      {pos: v3p, norm: wwv_50, },
      {pos: w10p, norm: wwv_50, },

      {pos: w10p, norm: qww_50, },
      {pos: q3p, norm: qww_50, },
      {pos: w3p, norm: qww_50, },
    /* #endregion */
    /* #region cell_qwwv_44 */
      {pos: q3p, norm: qwv_45, },
      {pos: v3p, norm: qwv_45, },
      {pos: w10p, norm: qwv_45, },

      {pos: v3p, norm: qwv_44, },
      {pos: q3p, norm: qwv_44, },
      {pos: w9p, norm: qwv_44, },

      {pos: w10p, norm: wwv_59, },
      {pos: v3p, norm: wwv_59, },
      {pos: w9p, norm: wwv_59, },

      {pos: w9p, norm: qww_59, },
      {pos: q3p, norm: qww_59, },
      {pos: w10p, norm: qww_59, },
    /* #endregion */
    /* #region cell_qwwv_45 */
      {pos: q3p, norm: qwv_44, },
      {pos: v3p, norm: qwv_44, },
      {pos: w9p, norm: qwv_44, },

      {pos: v3p, norm: qwv_41, },
      {pos: q3p, norm: qwv_41, },
      {pos: w1p, norm: qwv_41, },

      {pos: w9p, norm: wwv_42, },
      {pos: v3p, norm: wwv_42, },
      {pos: w1p, norm: wwv_42, },

      {pos: w1p, norm: qww_42, },
      {pos: q3p, norm: qww_42, },
      {pos: w9p, norm: qww_42, },
    /* #endregion */
    /* #region cell_qwwv_46 */
      {pos: q4p, norm: qwv_47, },
      {pos: v4p, norm: qwv_47, },
      {pos: w3p, norm: qwv_47, },

      {pos: v4p, norm: qwv_49, },
      {pos: q4p, norm: qwv_49, },
      {pos: w5p, norm: qwv_49, },

      {pos: w3p, norm: wwv_52, },
      {pos: v4p, norm: wwv_52, },
      {pos: w5p, norm: wwv_52, },

      {pos: w5p, norm: qww_52, },
      {pos: q4p, norm: qww_52, },
      {pos: w3p, norm: qww_52, },
    /* #endregion */
    /* #region cell_qwwv_47 */
      {pos: q4p, norm: qwv_49, },
      {pos: v4p, norm: qwv_49, },
      {pos: w5p, norm: qwv_49, },

      {pos: v4p, norm: qwv_50, },
      {pos: q4p, norm: qwv_50, },
      {pos: w6p, norm: qwv_50, },

      {pos: w5p, norm: wwv_55, },
      {pos: v4p, norm: wwv_55, },
      {pos: w6p, norm: wwv_55, },

      {pos: w6p, norm: qww_55, },
      {pos: q4p, norm: qww_55, },
      {pos: w5p, norm: qww_55, },
    /* #endregion */
    /* #region cell_qwwv_48 */
      {pos: q4p, norm: qwv_50, },
      {pos: v4p, norm: qwv_50, },
      {pos: w6p, norm: qwv_50, },

      {pos: v4p, norm: qwv_48, },
      {pos: q4p, norm: qwv_48, },
      {pos: w4p, norm: qwv_48, },

      {pos: w6p, norm: wwv_54, },
      {pos: v4p, norm: wwv_54, },
      {pos: w4p, norm: wwv_54, },

      {pos: w4p, norm: qww_54, },
      {pos: q4p, norm: qww_54, },
      {pos: w6p, norm: qww_54, },
    /* #endregion */
    /* #region cell_qwwv_49 */
      {pos: q4p, norm: qwv_48, },
      {pos: v4p, norm: qwv_48, },
      {pos: w4p, norm: qwv_48, },

      {pos: v4p, norm: qwv_46, },
      {pos: q4p, norm: qwv_46, },
      {pos: w8, norm: qwv_46, },

      {pos: w4p, norm: wwv_31, },
      {pos: v4p, norm: wwv_31, },
      {pos: w8, norm: wwv_31, },

      {pos: w8, norm: qww_31, },
      {pos: q4p, norm: qww_31, },
      {pos: w4p, norm: qww_31, },
    /* #endregion */
    /* #region cell_qwwv_50 */
      {pos: q4p, norm: qwv_46, },
      {pos: v4p, norm: qwv_46, },
      {pos: w8, norm: qwv_46, },

      {pos: v4p, norm: qwv_47, },
      {pos: q4p, norm: qwv_47, },
      {pos: w3p, norm: qwv_47, },

      {pos: w8, norm: wwv_30, },
      {pos: v4p, norm: wwv_30, },
      {pos: w3p, norm: wwv_30, },

      {pos: w3p, norm: qww_30, },
      {pos: q4p, norm: qww_30, },
      {pos: w8, norm: qww_30, },
    /* #endregion */
    /* #region cell_qwwv_51 */
      {pos: q5p, norm: qwv_53, },
      {pos: v5p, norm: qwv_53, },
      {pos: w2p, norm: qwv_53, },

      {pos: v5p, norm: qwv_54, },
      {pos: q5p, norm: qwv_54, },
      {pos: w7p, norm: qwv_54, },

      {pos: w2p, norm: wwv_48, },
      {pos: v5p, norm: wwv_48, },
      {pos: w7p, norm: wwv_48, },

      {pos: w7p, norm: qww_48, },
      {pos: q5p, norm: qww_48, },
      {pos: w2p, norm: qww_48, },
    /* #endregion */
    /* #region cell_qwwv_52 */
      {pos: q5p, norm: qwv_54, },
      {pos: v5p, norm: qwv_54, },
      {pos: w7p, norm: qwv_54, },

      {pos: v5p, norm: qwv_55, },
      {pos: q5p, norm: qwv_55, },
      {pos: w8p, norm: qwv_55, },

      {pos: w7p, norm: wwv_58, },
      {pos: v5p, norm: wwv_58, },
      {pos: w8p, norm: wwv_58, },

      {pos: w8p, norm: qww_58, },
      {pos: q5p, norm: qww_58, },
      {pos: w7p, norm: qww_58, },
    /* #endregion */
    /* #region cell_qwwv_53 */
      {pos: q5p, norm: qwv_55, },
      {pos: v5p, norm: qwv_55, },
      {pos: w8p, norm: qwv_55, },

      {pos: v5p, norm: qwv_51, },
      {pos: q5p, norm: qwv_51, },
      {pos: w3, norm: qwv_51, },

      {pos: w8p, norm: wwv_16, },
      {pos: v5p, norm: wwv_16, },
      {pos: w3, norm: wwv_16, },

      {pos: w3, norm: qww_16, },
      {pos: q5p, norm: qww_16, },
      {pos: w8p, norm: qww_16, },
    /* #endregion */
    /* #region cell_qwwv_54 */
      {pos: q5p, norm: qwv_51, },
      {pos: v5p, norm: qwv_51, },
      {pos: w3, norm: qwv_51, },

      {pos: v5p, norm: qwv_52, },
      {pos: q5p, norm: qwv_52, },
      {pos: w10, norm: qwv_52, },

      {pos: w3, norm: wwv_17, },
      {pos: v5p, norm: wwv_17, },
      {pos: w10, norm: wwv_17, },

      {pos: w10, norm: qww_17, },
      {pos: q5p, norm: qww_17, },
      {pos: w3, norm: qww_17, },
    /* #endregion */
    /* #region cell_qwwv_55 */
      {pos: q5p, norm: qwv_52, },
      {pos: v5p, norm: qwv_52, },
      {pos: w10, norm: qwv_52, },

      {pos: v5p, norm: qwv_53, },
      {pos: q5p, norm: qwv_53, },
      {pos: w2p, norm: qwv_53, },

      {pos: w10, norm: wwv_38, },
      {pos: v5p, norm: wwv_38, },
      {pos: w2p, norm: wwv_38, },

      {pos: w2p, norm: qww_38, },
      {pos: q5p, norm: qww_38, },
      {pos: w10, norm: qww_38, },
    /* #endregion */
    /* #region cell_qwwv_56 */
      {pos: q6p, norm: qwv_59, },
      {pos: v6p, norm: qwv_59, },
      {pos: w9p, norm: qwv_59, },

      {pos: v6p, norm: qwv_60, },
      {pos: q6p, norm: qwv_60, },
      {pos: w10p, norm: qwv_60, },

      {pos: w9p, norm: wwv_60, },
      {pos: v6p, norm: wwv_60, },
      {pos: w10p, norm: wwv_60, },

      {pos: w10p, norm: qww_60, },
      {pos: q6p, norm: qww_60, },
      {pos: w9p, norm: qww_60, },
    /* #endregion */
    /* #region cell_qwwv_57 */
      {pos: q6p, norm: qwv_60, },
      {pos: v6p, norm: qwv_60, },
      {pos: w10p, norm: qwv_60, },

      {pos: v6p, norm: qwv_56, },
      {pos: q6p, norm: qwv_56, },
      {pos: w2, norm: qwv_56, },

      {pos: w10p, norm: wwv_10, },
      {pos: v6p, norm: wwv_10, },
      {pos: w2, norm: wwv_10, },

      {pos: w2, norm: qww_10, },
      {pos: q6p, norm: qww_10, },
      {pos: w10p, norm: qww_10, },
    /* #endregion */
    /* #region cell_qwwv_58 */
      {pos: q6p, norm: qwv_56, },
      {pos: v6p, norm: qwv_56, },
      {pos: w2, norm: qwv_56, },

      {pos: v6p, norm: qwv_58, },
      {pos: q6p, norm: qwv_58, },
      {pos: w6, norm: qwv_58, },

      {pos: w2, norm: wwv_11, },
      {pos: v6p, norm: wwv_11, },
      {pos: w6, norm: wwv_11, },

      {pos: w6, norm: qww_11, },
      {pos: q6p, norm: qww_11, },
      {pos: w2, norm: qww_11, },
    /* #endregion */
    /* #region cell_qwwv_59 */
      {pos: q6p, norm: qwv_58, },
      {pos: v6p, norm: qwv_58, },
      {pos: w6, norm: qwv_58, },

      {pos: v6p, norm: qwv_57, },
      {pos: q6p, norm: qwv_57, },
      {pos: w4, norm: qwv_57, },

      {pos: w6, norm: wwv_23, },
      {pos: v6p, norm: wwv_23, },
      {pos: w4, norm: wwv_23, },

      {pos: w4, norm: qww_23, },
      {pos: q6p, norm: qww_23, },
      {pos: w6, norm: qww_23, },
    /* #endregion */
    /* #region cell_qwwv_60 */
      {pos: q6p, norm: qwv_57, },
      {pos: v6p, norm: qwv_57, },
      {pos: w4, norm: qwv_57, },

      {pos: v6p, norm: qwv_59, },
      {pos: q6p, norm: qwv_59, },
      {pos: w9p, norm: qwv_59, },

      {pos: w4, norm: wwv_22, },
      {pos: v6p, norm: wwv_22, },
      {pos: w9p, norm: wwv_22, },

      {pos: w9p, norm: qww_22, },
      {pos: q6p, norm: qww_22, },
      {pos: w4, norm: qww_22, },
    /* #endregion */
    ]} else {
    verts4 = []
  }
  /* #endregion */
  /* #region wwvt cells (60) */
  if (layer5) {
    verts5 = [
    /* #region cell_wwvt_1 */
      {pos: v1, norm: wwv_6, },
      {pos: w1, norm: wwv_6, },
      {pos: w5, norm: wwv_6, },

      {pos: w1, norm: wwt_1, },
      {pos: w5, norm: wwt_1, },
      {pos: t12, norm: wwt_1, },

      {pos: w5, norm: wvt_6, },
      {pos: t12, norm: wvt_6, },
      {pos: v1, norm: wvt_6, },
      
      {pos: t12, norm: wvt_2, },
      {pos: v1, norm: wvt_2, },
      {pos: w1, norm: wvt_2, },
    /* #endregion */
    /* #region cell_wwvt_2 */
      {pos: v1, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: w1, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: t4, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: w7, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_3 */
      {pos: v1, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: w6, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_4 */
      {pos: v1, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: w2, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_5 */
      {pos: v1, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: w5, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: t1, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: w5, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_6 */
      {pos: v2, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: w1, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: t4, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: w1, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_7 */
      {pos: v2, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: w1, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: t8, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: w9, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_8 */
      {pos: v2, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: w4p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_9 */
      {pos: v2, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: w8, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_10 */
      {pos: v2, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: t2, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: w7, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_11 */
      {pos: v3, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: w5, norm: gn, },

      {pos: w1, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: w5, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: t12, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: w1, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_12 */
      {pos: v3, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: w5, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: w5, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_13 */
      {pos: v3, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: t5, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: w3, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_14 */
      {pos: v3, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: t3, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: w10, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_15 */
      {pos: v3, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: w1, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: t8, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: w9, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_16 */
      {pos: v4, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: w5, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: w5, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: w3, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_17 */
      {pos: v4, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: w5, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: t1, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: w5, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_18 */
      {pos: v4, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: t6, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: w6, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_19 */
      {pos: v4, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: w4, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_20 */
      {pos: v4, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: t7, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: w8p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_21 */
      {pos: v5, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: w2, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_22 */
      {pos: v5, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: w7, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: t2, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: w7, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_23 */
      {pos: v5, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: t7p, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: w8, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_24 */
      {pos: v5, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: t5p, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: w3p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_25 */
      {pos: v5, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: w10p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_26 */
      {pos: v6, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: t3, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: w9, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_27 */
      {pos: v6, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: w10, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_28 */
      {pos: v6, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: w2p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_29 */
      {pos: v6, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: t6p, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: w6p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_30 */
      {pos: v6, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: w9, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: w4p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_31 */
      {pos: v1p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: w5p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: t12p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: w1p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_32 */
      {pos: v1p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: t4p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: w7p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_33 */
      {pos: v1p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: w6p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_34 */
      {pos: v1p, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: w2p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_35 */
      {pos: v1p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: t1p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: w5p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_36 */
      {pos: v2p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: t4p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: w1p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_37 */
      {pos: v2p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: t8p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: w9p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_38 */
      {pos: v2p, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: w4, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_39 */
      {pos: v2p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: w8p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_40 */
      {pos: v2p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: t2p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: w7p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_41 */
      {pos: v3p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: w5p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: t12p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: w1p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_42 */
      {pos: v3p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: w5p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_43 */
      {pos: v3p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: t5p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: w3p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_44 */
      {pos: v3p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: t3p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: w10p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_45 */
      {pos: v3p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: w1p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: t8p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: w9p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_46 */
      {pos: v4p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: w5p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: w3p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_47 */
      {pos: v4p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: w5p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: t1p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: w5p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_48 */
      {pos: v4p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: w6p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: t6p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: w6p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_49 */
      {pos: v4p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: w4p, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: w4p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_50 */
      {pos: v4p, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: w8, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: w3p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: t7p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: w8, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_51 */
      {pos: v5p, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: w2p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_52 */
      {pos: v5p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: w7p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: t2p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: w7p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_53 */
      {pos: v5p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: w8p, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: t7, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: w8p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_54 */
      {pos: v5p, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: w3, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: t5, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: w3, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_55 */
      {pos: v5p, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: w10, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: w10, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_56 */
      {pos: v6p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: t3p, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: w9p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_57 */
      {pos: v6p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: w10p, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: w10p, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_58 */
      {pos: v6p, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: w2, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: w2, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_59 */
      {pos: v6p, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: w6, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: t6, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: w6, norm: gn, },
    /* #endregion */
    /* #region cell_wwvt_60 */
      {pos: v6p, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: w4, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: w9p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: w4, norm: gn, },
    /* #endregion */
    ]} else {
    verts5 = []
  }
  /* #endregion */
  /* #region wvtt cells (60) */
  if (layer6) {
    verts6 = [
    /* #region cell_wvtt_1 */
      {pos: v1, norm: vtt_1, },
      {pos: t1, norm: vtt_1, },
      {pos: t12, norm: vtt_1, },

      {pos: t1, norm: wtt_1, },
      {pos: t12, norm: wtt_1, },
      {pos: w5, norm: wtt_1, },

      {pos: t12, norm: wvt_6, },
      {pos: w5, norm: wvt_6, },
      {pos: v1, norm: wvt_6, },
      
      {pos: w5, norm: wvt_2, },
      {pos: v1, norm: wvt_2, },
      {pos: t1, norm: wvt_2, },
    /* #endregion */
    /* #region cell_wvtt_2 */
      {pos: v1, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: w6, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_3 */
      {pos: v1, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: w1, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_4 */
      {pos: v1, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: w7, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_5 */
      {pos: v1, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: w2, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_6 */
      {pos: v2, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: w7, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_7 */
      {pos: v2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: w1, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_8 */
      {pos: v2, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: w9, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_9 */
      {pos: v2, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: w4p, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_10 */
      {pos: v2, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: w8, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_11 */
      {pos: v3, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: w10, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_12 */
      {pos: v3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: w3, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_13 */
      {pos: v3, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: w5, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: w5, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_14 */
      {pos: v3, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: w1, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: w1, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_15 */
      {pos: v3, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: w9, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_16 */
      {pos: v4, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: w6, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_17 */
      {pos: v4, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: w4, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_18 */
      {pos: v4, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: w8p, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_19 */
      {pos: v4, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: w3, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_20 */
      {pos: v4, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: w5, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: w5, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_21 */
      {pos: v5, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: w7, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_22 */
      {pos: v5, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: w2, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_23 */
      {pos: v5, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: w10p, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_24 */
      {pos: v5, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: w3p, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_25 */
      {pos: v5, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: w8, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_26 */
      {pos: v6, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: w9, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_27 */
      {pos: v6, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: w4p, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_28 */
      {pos: v6, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: w6p, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_29 */
      {pos: v6, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: w2p, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_30 */
      {pos: v6, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: w10, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_31 */
      {pos: v1p, norm: vtt_1, },
      {pos: t1p, norm: vtt_1, },
      {pos: t12p, norm: vtt_1, },

      {pos: t1p, norm: wtt_1, },
      {pos: t12p, norm: wtt_1, },
      {pos: w5p, norm: wtt_1, },

      {pos: t12p, norm: wvt_6, },
      {pos: w5p, norm: wvt_6, },
      {pos: v1p, norm: wvt_6, },
      
      {pos: w5p, norm: wvt_2, },
      {pos: v1p, norm: wvt_2, },
      {pos: t1p, norm: wvt_2, },
    /* #endregion */
    /* #region cell_wvtt_32 */
      {pos: v1p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: w6p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_33 */
      {pos: v1p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: w1p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_34 */
      {pos: v1p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: w7p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_35 */
      {pos: v1p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: w2p, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_36 */
      {pos: v2p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: w7p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_37 */
      {pos: v2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: w1p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_38 */
      {pos: v2p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: w9p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_39 */
      {pos: v2p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: w4, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_40 */
      {pos: v2p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: w8p, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_41 */
      {pos: v3p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: w10p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_42 */
      {pos: v3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: w3p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_43 */
      {pos: v3p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: w5p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: w5p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_44 */
      {pos: v3p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: w1p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: w1p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_45 */
      {pos: v3p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: w9p, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_46 */
      {pos: v4p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: w6p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: w6p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_47 */
      {pos: v4p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w4p, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: w4p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_48 */
      {pos: v4p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: w8, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: w8, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_49 */
      {pos: v4p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: w3p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_50 */
      {pos: v4p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: w5p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: w5p, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_51 */
      {pos: v5p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: w7p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_52 */
      {pos: v5p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w2p, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: w2p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_53 */
      {pos: v5p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: w10, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: w10, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_54 */
      {pos: v5p, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: w3, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: w3, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_55 */
      {pos: v5p, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: w8p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: w8p, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_56 */
      {pos: v6p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: w9p, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_57 */
      {pos: v6p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: w4, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: w4, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_58 */
      {pos: v6p, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w6, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: w6, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_59 */
      {pos: v6p, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w2, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: w2, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_wvtt_60 */
      {pos: v6p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: w10p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: w10p, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    ]} else {
    verts6 = []
  }
  /* #endregion */
  /* #region wttt cells (20) */
  if (layer7) {
    verts7 = [
    /* #region cell_wttt_1 */
      {pos: w1, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: w1, norm: gn, },
      
      {pos: t12, norm: gn, },
      {pos: w1, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_2 */
      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w2, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: w2, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_3 */
      {pos: w3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: w3, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_4 */
      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w4, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: w4, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_5 */
      {pos: w5, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w5, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: w5, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_6 */
      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w6, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: w6, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_7 */
      {pos: w7, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: w7, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_8 */
      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w8, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: w8, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_9 */
      {pos: w9, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: w9, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_10 */
      {pos: w10, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w10, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: w10, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_11 */
      {pos: w1p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: w1p, norm: gn, },
      
      {pos: t12p, norm: gn, },
      {pos: w1p, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_12 */
      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w2p, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: w2p, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_13 */
      {pos: w3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: w3p, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_14 */
      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w4p, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: w4p, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_15 */
      {pos: w5p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w5p, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: w5p, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_16 */
      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w6p, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: w6p, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_17 */
      {pos: w7p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: w7p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_18 */
      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w8p, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: w8p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_19 */
      {pos: w9p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: w9p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_wttt_20 */
      {pos: w10p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w10p, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: w10p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    ]} else {
    verts7 = []
  }
  /* #endregion */
  /* #region vttv cells (60) */
  if (layer8) {
    verts8 = [
    /* #region cell_vttv_1 */
      {pos: v1, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: v1pm, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_2 */
      {pos: v1, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v1pm, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_3 */
      {pos: v1, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v1pm, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_4 */
      {pos: v1, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v1pm, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_5 */
      {pos: v1, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v1pm, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      
      {pos: v1pm, norm: gn, },
      {pos: v1, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_6 */
      {pos: v2, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v2pm, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_7 */
      {pos: v2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v2pm, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_8 */
      {pos: v2, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v2pm, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_9 */
      {pos: v2, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v2pm, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_10 */
      {pos: v2, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v2pm, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      
      {pos: v2pm, norm: gn, },
      {pos: v2, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_11 */
      {pos: v3, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v3pm, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_12 */
      {pos: v3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v3pm, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_13 */
      {pos: v3, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: v3pm, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_14 */
      {pos: v3, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v3pm, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_15 */
      {pos: v3, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v3pm, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      
      {pos: v3pm, norm: gn, },
      {pos: v3, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_16 */
      {pos: v4, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v4pm, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_17 */
      {pos: v4, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v4pm, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_18 */
      {pos: v4, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v4pm, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_19 */
      {pos: v4, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v4pm, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_20 */
      {pos: v4, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v4pm, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      
      {pos: v4pm, norm: gn, },
      {pos: v4, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_21 */
      {pos: v5, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v5pm, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_22 */
      {pos: v5, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v5pm, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_23 */
      {pos: v5, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v5pm, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_24 */
      {pos: v5, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v5pm, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_25 */
      {pos: v5, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v5pm, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      
      {pos: v5pm, norm: gn, },
      {pos: v5, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_26 */
      {pos: v6, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v6pm, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_27 */
      {pos: v6, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v6pm, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_28 */
      {pos: v6, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v6pm, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_29 */
      {pos: v6, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v6pm, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_30 */
      {pos: v6, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v6pm, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      
      {pos: v6pm, norm: gn, },
      {pos: v6, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_31 */
      {pos: v1p, norm: vtt_1, },
      {pos: t1p, norm: vtt_1, },
      {pos: t12p, norm: vtt_1, },

      {pos: t1p, norm: wtt_1, },
      {pos: t12p, norm: wtt_1, },
      {pos: v1m, norm: wtt_1, },

      {pos: t12p, norm: wvt_6, },
      {pos: v1m, norm: wvt_6, },
      {pos: v1p, norm: wvt_6, },
      
      {pos: v1m, norm: wvt_2, },
      {pos: v1p, norm: wvt_2, },
      {pos: t1p, norm: wvt_2, },
    /* #endregion */
    /* #region cell_vttv_32 */
      {pos: v1p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v1m, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_33 */
      {pos: v1p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v1m, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_34 */
      {pos: v1p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v1m, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_35 */
      {pos: v1p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v1m, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      
      {pos: v1m, norm: gn, },
      {pos: v1p, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_36 */
      {pos: v2p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v2m, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_37 */
      {pos: v2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v2m, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_38 */
      {pos: v2p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v2m, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_39 */
      {pos: v2p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v2m, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_40 */
      {pos: v2p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v2m, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      
      {pos: v2m, norm: gn, },
      {pos: v2p, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_41 */
      {pos: v3p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v3m, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_42 */
      {pos: v3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v3m, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_43 */
      {pos: v3p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: v3m, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_44 */
      {pos: v3p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v3m, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_45 */
      {pos: v3p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v3m, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      
      {pos: v3m, norm: gn, },
      {pos: v3p, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_46 */
      {pos: v4p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v4m, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_47 */
      {pos: v4p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v4m, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_48 */
      {pos: v4p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v4m, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_49 */
      {pos: v4p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v4m, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_50 */
      {pos: v4p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v4m, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      
      {pos: v4m, norm: gn, },
      {pos: v4p, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_51 */
      {pos: v5p, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v5m, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_52 */
      {pos: v5p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v5m, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_53 */
      {pos: v5p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v5m, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_54 */
      {pos: v5p, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v5m, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_55 */
      {pos: v5p, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v5m, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      
      {pos: v5m, norm: gn, },
      {pos: v5p, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_56 */
      {pos: v6p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v6m, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_57 */
      {pos: v6p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v6m, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_58 */
      {pos: v6p, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v6m, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_59 */
      {pos: v6p, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v6m, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_vttv_60 */
      {pos: v6p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v6m, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      
      {pos: v6m, norm: gn, },
      {pos: v6p, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    ]} else {
    verts8 = []
  }
  /* #endregion */
  /* #region tttw cells (20) */
  if (layer9) {
    verts9 = [
    /* #region cell_tttw_1 */
      {pos: w1m, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: w1m, norm: gn, },
      
      {pos: t12p, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_2 */
      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w2m, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_3 */
      {pos: w3m, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_4 */
      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w4m, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_5 */
      {pos: w5m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w5m, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_6 */
      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w6m, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_7 */
      {pos: w7m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_8 */
      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w8m, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_9 */
      {pos: w9m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_10 */
      {pos: w10m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w10m, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_11 */
      {pos: w1pm, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: w1pm, norm: gn, },
      
      {pos: t12, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_12 */
      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w2pm, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_13 */
      {pos: w3pm, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_14 */
      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w4pm, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_15 */
      {pos: w5pm, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w5pm, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_16 */
      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w6pm, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_17 */
      {pos: w7pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_18 */
      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w8pm, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_19 */
      {pos: w9pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_tttw_20 */
      {pos: w10pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w10pm, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    ]} else {
    verts9 = []
  }
/* #endregion */
  /* #region ttvw cells (60) */
  if (layer10) {
    verts10 = [
    /* #region cell_ttvw_1 */
      {pos: v1m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: w5m, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: w5m, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_2 */
      {pos: v1m, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: w6m, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_3 */
      {pos: v1m, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: w1m, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_4 */
      {pos: v1m, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: w7m, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_5 */
      {pos: v1m, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: w2p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_6 */
      {pos: v2m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: w7m, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_7 */
      {pos: v2m, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t4p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: w1m, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: t4p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_8 */
      {pos: v2m, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: w9m, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_9 */
      {pos: v2m, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: w4pm, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_10 */
      {pos: v2m, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: w8m, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_11 */
      {pos: v3m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: w10m, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_12 */
      {pos: v3m, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: w3m, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_13 */
      {pos: v3m, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: w5m, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: w5m, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_14 */
      {pos: v3m, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: t12p, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: w1m, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: t12p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_15 */
      {pos: v3m, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t8p, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: w9m, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: t8p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_16 */
      {pos: v4m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: w6m, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: t1p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_17 */
      {pos: v4m, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: w4m, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_18 */
      {pos: v4m, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: w8pm, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_19 */
      {pos: v4m, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: w3m, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_20 */
      {pos: v4m, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: t15p, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: w5m, norm: gn, },

      {pos: t1p, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: w5m, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: t15p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_21 */
      {pos: v5m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: w7m, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: t2p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_22 */
      {pos: v5m, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t13p, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: w2m, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: t13p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_23 */
      {pos: v5m, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: w10pm, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_24 */
      {pos: v5m, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: w3pm, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_25 */
      {pos: v5m, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: t2p, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: w8m, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_26 */
      {pos: v6m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: w9m, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: t3p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_27 */
      {pos: v6m, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t14p, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: w4pm, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: t14p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_28 */
      {pos: v6m, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: w6pm, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_29 */
      {pos: v6m, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: w2pm, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_30 */
      {pos: v6m, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: t3p, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: w10m, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_31 */
      {pos: v1pm, norm: vtt_1, },
      {pos: t1, norm: vtt_1, },
      {pos: t12, norm: vtt_1, },

      {pos: t1, norm: wtt_1, },
      {pos: t12, norm: wtt_1, },
      {pos: w5pm, norm: wtt_1, },

      {pos: t12, norm: wvt_6, },
      {pos: w5pm, norm: wvt_6, },
      {pos: v1pm, norm: wvt_6, },
      
      {pos: w5pm, norm: wvt_2, },
      {pos: v1pm, norm: wvt_2, },
      {pos: t1, norm: wvt_2, },
    /* #endregion */
    /* #region cell_ttvw_32 */
      {pos: v1pm, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: w6pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: t9p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_33 */
      {pos: v1pm, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: w1pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_34 */
      {pos: v1pm, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: w7pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_35 */
      {pos: v1pm, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: t9p, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: w2pm, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_36 */
      {pos: v2pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: w7pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_37 */
      {pos: v2pm, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t4, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: w1pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: t4, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_38 */
      {pos: v2pm, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: w9pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_39 */
      {pos: v2pm, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: w4m, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_40 */
      {pos: v2pm, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t10p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: w8pm, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: t10p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_41 */
      {pos: v3pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: w10pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_42 */
      {pos: v3pm, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t5, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: w3pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: t5, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_43 */
      {pos: v3pm, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: w5pm, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: w5pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_44 */
      {pos: v3pm, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: t12, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: w1pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: t12, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_45 */
      {pos: v3pm, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t8, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: w9pm, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: t8, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_46 */
      {pos: v4pm, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: w6pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: t1, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_47 */
      {pos: v4pm, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: t6, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: w4pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: t6, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_48 */
      {pos: v4pm, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: t10, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: w8m, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: t10, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_49 */
      {pos: v4pm, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: t7, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: w3pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: t7, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_50 */
      {pos: v4pm, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: t15, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: w5pm, norm: gn, },

      {pos: t1, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: w5pm, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: t15, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_51 */
      {pos: v5pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: w7pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: t2, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_52 */
      {pos: v5pm, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: t13, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: w2pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: t13, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_53 */
      {pos: v5pm, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: t11, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: w10m, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: t11, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_54 */
      {pos: v5pm, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: t5p, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: w3m, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: t5p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_55 */
      {pos: v5pm, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: t7p, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: t2, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: w8pm, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: t7p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_56 */
      {pos: v6pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: w9pm, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: t3, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_57 */
      {pos: v6pm, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: t14, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: w4m, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: t14, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_58 */
      {pos: v6pm, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: t6p, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: w6m, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: t6p, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_59 */
      {pos: v6pm, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: t9, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: w2m, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: t9, norm: gn, },
    /* #endregion */
    /* #region cell_ttvw_60 */
      {pos: v6pm, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: t11p, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: t3, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: w10pm, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: t11p, norm: gn, },
    /* #endregion */
    ]} else {
    verts10 = []
  }
  /* #endregion */  
  /* #region tvww cells (60) */
  if (layer11) {
    verts11 = [
    /* #region cell_tvww_1 */
      {pos: v1m, norm: wwv_6, },
      {pos: w1m, norm: wwv_6, },
      {pos: w5m, norm: wwv_6, },

      {pos: w1m, norm: wwt_1, },
      {pos: w5m, norm: wwt_1, },
      {pos: t12p, norm: wwt_1, },

      {pos: w5m, norm: wvt_6, },
      {pos: t12p, norm: wvt_6, },
      {pos: v1m, norm: wvt_6, },
      
      {pos: t12p, norm: wvt_2, },
      {pos: v1m, norm: wvt_2, },
      {pos: w1m, norm: wvt_2, },
    /* #endregion */
    /* #region cell_tvww_2 */
      {pos: v1m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: w1m, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: t4p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: w7m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_3 */
      {pos: v1m, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: w6m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_4 */
      {pos: v1m, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: w2m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_5 */
      {pos: v1m, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: w5m, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v1m, norm: gn, },
      
      {pos: t1p, norm: gn, },
      {pos: v1m, norm: gn, },
      {pos: w5m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_6 */
      {pos: v2m, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: w1m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: t4p, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: t4p, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: t4p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: w1m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_7 */
      {pos: v2m, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: w1m, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: t8p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: w9m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_8 */
      {pos: v2m, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: w4pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_9 */
      {pos: v2m, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: w8m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_10 */
      {pos: v2m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v2m, norm: gn, },
      
      {pos: t2p, norm: gn, },
      {pos: v2m, norm: gn, },
      {pos: w7m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_11 */
      {pos: v3m, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: w5m, norm: gn, },

      {pos: w1m, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: t12p, norm: gn, },

      {pos: w5m, norm: gn, },
      {pos: t12p, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: t12p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: w1m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_12 */
      {pos: v3m, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: w5m, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: w5m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_13 */
      {pos: v3m, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: t5p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: w3m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_14 */
      {pos: v3m, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: t3p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: w10m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_15 */
      {pos: v3m, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: w1m, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: w1m, norm: gn, },
      {pos: t8p, norm: gn, },

      {pos: w1m, norm: gn, },
      {pos: t8p, norm: gn, },
      {pos: v3m, norm: gn, },
      
      {pos: t8p, norm: gn, },
      {pos: v3m, norm: gn, },
      {pos: w9m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_16 */
      {pos: v4m, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: w5m, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: t15p, norm: gn, },

      {pos: w5m, norm: gn, },
      {pos: t15p, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: t15p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: w3m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_17 */
      {pos: v4m, norm: gn, },
      {pos: w5m, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: w5m, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: t1p, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: t1p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: w5m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_18 */
      {pos: v4m, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: t6p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: w6m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_19 */
      {pos: v4m, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: w4m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_20 */
      {pos: v4m, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v4m, norm: gn, },
      
      {pos: t7p, norm: gn, },
      {pos: v4m, norm: gn, },
      {pos: w8pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_21 */
      {pos: v5m, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: w7m, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: t13p, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: t13p, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: t13p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: w2m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_22 */
      {pos: v5m, norm: gn, },
      {pos: w7m, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: w7m, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: t2p, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: t2p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: w7m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_23 */
      {pos: v5m, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: t7, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: w8m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_24 */
      {pos: v5m, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: t5, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: w3pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_25 */
      {pos: v5m, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v5m, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: v5m, norm: gn, },
      {pos: w10pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_26 */
      {pos: v6m, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: t3p, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: t3p, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: t3p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: w9m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_27 */
      {pos: v6m, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: w10m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_28 */
      {pos: v6m, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: w2pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_29 */
      {pos: v6m, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: t6, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: w6pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_30 */
      {pos: v6m, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: w9m, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: w9m, norm: gn, },
      {pos: t14p, norm: gn, },

      {pos: w9m, norm: gn, },
      {pos: t14p, norm: gn, },
      {pos: v6m, norm: gn, },
      
      {pos: t14p, norm: gn, },
      {pos: v6m, norm: gn, },
      {pos: w4pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_31 */
      {pos: v1pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: w5pm, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: t12, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: w1pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_32 */
      {pos: v1pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: t4, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: w7pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_33 */
      {pos: v1pm, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: t9p, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: t9p, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: t9p, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: w6pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_34 */
      {pos: v1pm, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: w2pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_35 */
      {pos: v1pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v1pm, norm: gn, },
      
      {pos: t1, norm: gn, },
      {pos: v1pm, norm: gn, },
      {pos: w5pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_36 */
      {pos: v2pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: t4, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: t4, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: t4, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: w1pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_37 */
      {pos: v2pm, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: t8, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: w9pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_38 */
      {pos: v2pm, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: w4m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_39 */
      {pos: v2pm, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: t10p, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: t10p, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: t10p, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: w8pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_40 */
      {pos: v2pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v2pm, norm: gn, },
      
      {pos: t2, norm: gn, },
      {pos: v2pm, norm: gn, },
      {pos: w7pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_41 */
      {pos: v3pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: w5pm, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: t12, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: t12, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: t12, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: w1pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_42 */
      {pos: v3pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: w5pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_43 */
      {pos: v3pm, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: t5, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: t5, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: t5, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: w3pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_44 */
      {pos: v3pm, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: t3, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: w10pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_45 */
      {pos: v3pm, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: w1pm, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: w1pm, norm: gn, },
      {pos: t8, norm: gn, },

      {pos: w1pm, norm: gn, },
      {pos: t8, norm: gn, },
      {pos: v3pm, norm: gn, },
      
      {pos: t8, norm: gn, },
      {pos: v3pm, norm: gn, },
      {pos: w9pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_46 */
      {pos: v4pm, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: w5pm, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: t15, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: t15, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: t15, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: w3pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_47 */
      {pos: v4pm, norm: gn, },
      {pos: w5pm, norm: gn, },
      {pos: w6pm, norm: gn, },

      {pos: w5pm, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: t1, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: t1, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: w5pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_48 */
      {pos: v4pm, norm: gn, },
      {pos: w6pm, norm: gn, },
      {pos: w4pm, norm: gn, },

      {pos: w6pm, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: t6, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: t6, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: w6pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_49 */
      {pos: v4pm, norm: gn, },
      {pos: w4pm, norm: gn, },
      {pos: w8m, norm: gn, },

      {pos: w4pm, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: t10, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: t10, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: t10, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: w4pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_50 */
      {pos: v4pm, norm: gn, },
      {pos: w8m, norm: gn, },
      {pos: w3pm, norm: gn, },

      {pos: w8m, norm: gn, },
      {pos: w3pm, norm: gn, },
      {pos: t7, norm: gn, },

      {pos: w3pm, norm: gn, },
      {pos: t7, norm: gn, },
      {pos: v4pm, norm: gn, },
      
      {pos: t7, norm: gn, },
      {pos: v4pm, norm: gn, },
      {pos: w8m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_51 */
      {pos: v5pm, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: w7pm, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: t13, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: t13, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: t13, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: w2pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_52 */
      {pos: v5pm, norm: gn, },
      {pos: w7pm, norm: gn, },
      {pos: w8pm, norm: gn, },

      {pos: w7pm, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: t2, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: t2, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: w7pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_53 */
      {pos: v5pm, norm: gn, },
      {pos: w8pm, norm: gn, },
      {pos: w3m, norm: gn, },

      {pos: w8pm, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: t7p, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: t7p, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: t7p, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: w8pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_54 */
      {pos: v5pm, norm: gn, },
      {pos: w3m, norm: gn, },
      {pos: w10m, norm: gn, },

      {pos: w3m, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: t5p, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: t5p, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: t5p, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: w3m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_55 */
      {pos: v5pm, norm: gn, },
      {pos: w10m, norm: gn, },
      {pos: w2pm, norm: gn, },

      {pos: w10m, norm: gn, },
      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },

      {pos: w2pm, norm: gn, },
      {pos: t11, norm: gn, },
      {pos: v5pm, norm: gn, },
      
      {pos: t11, norm: gn, },
      {pos: v5pm, norm: gn, },
      {pos: w10m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_56 */
      {pos: v6pm, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: w10pm, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: t3, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: t3, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: t3, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: w9pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_57 */
      {pos: v6pm, norm: gn, },
      {pos: w10pm, norm: gn, },
      {pos: w2m, norm: gn, },

      {pos: w10pm, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: t11p, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: t11p, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: w10pm, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_58 */
      {pos: v6pm, norm: gn, },
      {pos: w2m, norm: gn, },
      {pos: w6m, norm: gn, },

      {pos: w2m, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: t9, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: t9, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: t9, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: w2m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_59 */
      {pos: v6pm, norm: gn, },
      {pos: w6m, norm: gn, },
      {pos: w4m, norm: gn, },

      {pos: w6m, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: t6p, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: t6p, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: w6m, norm: gn, },
    /* #endregion */
    /* #region cell_tvww_60 */
      {pos: v6pm, norm: gn, },
      {pos: w4m, norm: gn, },
      {pos: w9pm, norm: gn, },

      {pos: w4m, norm: gn, },
      {pos: w9pm, norm: gn, },
      {pos: t14, norm: gn, },

      {pos: w9pm, norm: gn, },
      {pos: t14, norm: gn, },
      {pos: v6pm, norm: gn, },
      
      {pos: t14, norm: gn, },
      {pos: v6pm, norm: gn, },
      {pos: w4m, norm: gn, },
    /* #endregion */
    ]} else {
    verts11 = []
  }
  /* #endregion */
  /* #region vwwq cells (60) */
  if (layer12) {
    verts12 = [
    /* #region cell_vwwq_1 */
      {pos: q1m, norm: qwv_1, },
      {pos: v1m, norm: qwv_1, },
      {pos: w1m, norm: qwv_1, },

      {pos: v1m, norm: qwv_3, },
      {pos: q1m, norm: qwv_3, },
      {pos: w5m, norm: qwv_3, },

      {pos: w1m, norm: wwv_6, },
      {pos: v1m, norm: wwv_6, },
      {pos: w5m, norm: wwv_6, },

      {pos: w5m, norm: qww_6, },
      {pos: q1m, norm: qww_6, },
      {pos: w1m, norm: qww_6, },
    /* #endregion */
    /* #region cell_vwwq_2 */
      {pos: q1m, norm: qwv_3, },
      {pos: v1m, norm: qwv_3, },
      {pos: w5m, norm: qwv_3, },

      {pos: v1m, norm: qwv_4, },
      {pos: q1m, norm: qwv_4, },
      {pos: w6m, norm: qwv_4, },

      {pos: w5m, norm: wwv_26, },
      {pos: v1m, norm: wwv_26, },
      {pos: w6m, norm: wwv_26, },

      {pos: w6m, norm: qww_26, },
      {pos: q1m, norm: qww_26, },
      {pos: w5m, norm: qww_26, },
    /* #endregion */
    /* #region cell_vwwq_3 */
      {pos: q1m, norm: qwv_4, },
      {pos: v1m, norm: qwv_4, },
      {pos: w6m, norm: qwv_4, },

      {pos: v1m, norm: qwv_2, },
      {pos: q1m, norm: qwv_2, },
      {pos: w2m, norm: qwv_2, },

      {pos: w6m, norm: wwv_12, },
      {pos: v1m, norm: wwv_12, },
      {pos: w2m, norm: wwv_12, },

      {pos: w2m, norm: qww_12, },
      {pos: q1m, norm: qww_12, },
      {pos: w6m, norm: qww_12, },
    /* #endregion */
    /* #region cell_qwwv_4 */
      {pos: q1m, norm: qwv_2, },
      {pos: v1m, norm: qwv_2, },
      {pos: w2m, norm: qwv_2, },

      {pos: v1m, norm: qwv_5, },
      {pos: q1m, norm: qwv_5, },
      {pos: w7m, norm: qwv_5, },

      {pos: w2m, norm: wwv_7, },
      {pos: v1m, norm: wwv_7, },
      {pos: w7m, norm: wwv_7, },

      {pos: w7m, norm: qww_7, },
      {pos: q1m, norm: qww_7, },
      {pos: w2m, norm: qww_7, },
    /* #endregion */
    /* #region cell_vwwq_5 */
      {pos: q1m, norm: qwv_5, },
      {pos: v1m, norm: qwv_5, },
      {pos: w7m, norm: qwv_5, },

      {pos: v1m, norm: qwv_1, },
      {pos: q1m, norm: qwv_1, },
      {pos: w1m, norm: qwv_1, },

      {pos: w7m, norm: wwv_1, },
      {pos: v1m, norm: wwv_1, },
      {pos: w1m, norm: wwv_1, },

      {pos: w1m, norm: qww_1, },
      {pos: q1m, norm: qww_1, },
      {pos: w7m, norm: qww_1, },
    /* #endregion */
    /* #region cell_vwwq_6 */
      {pos: q2m, norm: qwv_7, },
      {pos: v2m, norm: qwv_7, },
      {pos: w7m, norm: qwv_7, },

      {pos: v2m, norm: qwv_6, },
      {pos: q2m, norm: qwv_6, },
      {pos: w1m, norm: qwv_6, },

      {pos: w7m, norm: wwv_2, },
      {pos: v2m, norm: wwv_2, },
      {pos: w1m, norm: wwv_2, },

      {pos: w1m, norm: qww_2, },
      {pos: q2m, norm: qww_2, },
      {pos: w7m, norm: qww_2, },
    /* #endregion */
    /* #region cell_vwwq_7 */
      {pos: q2m, norm: qwv_6, },
      {pos: v2m, norm: qwv_6, },
      {pos: w1m, norm: qwv_6, },

      {pos: v2m, norm: qwv_9, },
      {pos: q2m, norm: qwv_9, },
      {pos: w9m, norm: qwv_9, },

      {pos: w1m, norm: wwv_3, },
      {pos: v2m, norm: wwv_3, },
      {pos: w9m, norm: wwv_3, },

      {pos: w9m, norm: qww_3, },
      {pos: q2m, norm: qww_3, },
      {pos: w1m, norm: qww_3, },
    /* #endregion */
    /* #region cell_vwwq_8 */
      {pos: q2m, norm: qwv_9, },
      {pos: v2m, norm: qwv_9, },
      {pos: w9m, norm: qwv_9, },

      {pos: v2m, norm: qwv_10, },
      {pos: q2m, norm: qwv_10, },
      {pos: w4pm, norm: qwv_10, },

      {pos: w9m, norm: wwv_36, },
      {pos: v2m, norm: wwv_36, },
      {pos: w4pm, norm: wwv_36, },

      {pos: w4pm, norm: qww_36, },
      {pos: q2m, norm: qww_36, },
      {pos: w9m, norm: qww_36, },
    /* #endregion */
    /* #region cell_vwwq_9 */
      {pos: q2m, norm: qwv_10, },
      {pos: v2m, norm: qwv_10, },
      {pos: w4pm, norm: qwv_10, },

      {pos: v2m, norm: qwv_8, },
      {pos: q2m, norm: qwv_8, },
      {pos: w8m, norm: qwv_8, },

      {pos: w4pm, norm: wwv_32, },
      {pos: v2m, norm: wwv_32, },
      {pos: w8m, norm: wwv_32, },

      {pos: w8m, norm: qww_32, },
      {pos: q2m, norm: qww_32, },
      {pos: w4pm, norm: qww_32, },
    /* #endregion */
    /* #region cell_vwwq_10 */
      {pos: q2m, norm: qwv_8, },
      {pos: v2m, norm: qwv_8, },
      {pos: w8m, norm: qwv_8, },

      {pos: v2m, norm: qwv_7, },
      {pos: q2m, norm: qwv_7, },
      {pos: w7m, norm: qwv_7, },

      {pos: w8m, norm: wwv_27, },
      {pos: v2m, norm: wwv_27, },
      {pos: w7m, norm: wwv_27, },

      {pos: w7m, norm: qww_27, },
      {pos: q2m, norm: qww_27, },
      {pos: w8m, norm: qww_27, },
    /* #endregion */
    /* #region cell_vwwq_11 */
      {pos: q3m, norm: qwv_11, },
      {pos: v3m, norm: qwv_11, },
      {pos: w1m, norm: qwv_11, },

      {pos: v3m, norm: qwv_13, },
      {pos: q3m, norm: qwv_13, },
      {pos: w5m, norm: qwv_13, },

      {pos: w1m, norm: wwv_5, },
      {pos: v3m, norm: wwv_5, },
      {pos: w5m, norm: wwv_5, },

      {pos: w5m, norm: qww_5, },
      {pos: q3m, norm: qww_5, },
      {pos: w1m, norm: qww_5, },
    /* #endregion */
    /* #region cell_vwwq_12 */
      {pos: q3m, norm: qwv_13, },
      {pos: v3m, norm: qwv_13, },
      {pos: w5m, norm: qwv_13, },

      {pos: v3m, norm: qwv_12, },
      {pos: q3m, norm: qwv_12, },
      {pos: w3m, norm: qwv_12, },

      {pos: w5m, norm: wwv_13, },
      {pos: v3m, norm: wwv_13, },
      {pos: w3m, norm: wwv_13, },

      {pos: w3m, norm: qww_13, },
      {pos: q3m, norm: qww_13, },
      {pos: w5m, norm: qww_13, },
    /* #endregion */
    /* #region cell_vwwq_13 */
      {pos: q3m, norm: qwv_12, },
      {pos: v3m, norm: qwv_12, },
      {pos: w3m, norm: qwv_12, },

      {pos: v3m, norm: qwv_15, },
      {pos: q3m, norm: qwv_15, },
      {pos: w10m, norm: qwv_15, },

      {pos: w3m, norm: wwv_18, },
      {pos: v3m, norm: wwv_18, },
      {pos: w10m, norm: wwv_18, },

      {pos: w10m, norm: qww_18, },
      {pos: q3m, norm: qww_18, },
      {pos: w3m, norm: qww_18, },
    /* #endregion */
    /* #region cell_vwwq_14 */
      {pos: q3m, norm: qwv_15, },
      {pos: v3m, norm: qwv_15, },
      {pos: w10m, norm: qwv_15, },

      {pos: v3m, norm: qwv_14, },
      {pos: q3m, norm: qwv_14, },
      {pos: w9m, norm: qwv_14, },

      {pos: w10m, norm: wwv_33, },
      {pos: v3m, norm: wwv_33, },
      {pos: w9m, norm: wwv_33, },

      {pos: w9m, norm: qww_33, },
      {pos: q3m, norm: qww_33, },
      {pos: w10m, norm: qww_33, },
    /* #endregion */
    /* #region cell_vwwq_15 */
      {pos: q3m, norm: qwv_14, },
      {pos: v3m, norm: qwv_14, },
      {pos: w9m, norm: qwv_14, },

      {pos: v3m, norm: qwv_11, },
      {pos: q3m, norm: qwv_11, },
      {pos: w1m, norm: qwv_11, },

      {pos: w9m, norm: wwv_4, },
      {pos: v3m, norm: wwv_4, },
      {pos: w1m, norm: wwv_4, },

      {pos: w1m, norm: qww_4, },
      {pos: q3m, norm: qww_4, },
      {pos: w9m, norm: qww_4, },
    /* #endregion */
    /* #region cell_vwwq_16 */
      {pos: q4m, norm: qwv_16, },
      {pos: v4m, norm: qwv_16, },
      {pos: w3m, norm: qwv_16, },

      {pos: v4m, norm: qwv_18, },
      {pos: q4m, norm: qwv_18, },
      {pos: w5m, norm: qwv_18, },

      {pos: w3m, norm: wwv_14, },
      {pos: v4m, norm: wwv_14, },
      {pos: w5m, norm: wwv_14, },

      {pos: w5m, norm: qww_14, },
      {pos: q4m, norm: qww_14, },
      {pos: w3m, norm: qww_14, },
    /* #endregion */
    /* #region cell_vwwq_17 */
      {pos: q4m, norm: qwv_18, },
      {pos: v4m, norm: qwv_18, },
      {pos: w5m, norm: qwv_18, },

      {pos: v4m, norm: qwv_19, },
      {pos: q4m, norm: qwv_19, },
      {pos: w6m, norm: qwv_19, },

      {pos: w5m, norm: wwv_25, },
      {pos: v4m, norm: wwv_25, },
      {pos: w6m, norm: wwv_25, },

      {pos: w6m, norm: qww_25, },
      {pos: q4m, norm: qww_25, },
      {pos: w5m, norm: qww_25, },
    /* #endregion */
    /* #region cell_vwwq_18 */
      {pos: q4m, norm: qwv_19, },
      {pos: v4m, norm: qwv_19, },
      {pos: w6m, norm: qwv_19, },

      {pos: v4m, norm: qwv_17, },
      {pos: q4m, norm: qwv_17, },
      {pos: w4m, norm: qwv_17, },

      {pos: w6m, norm: wwv_24, },
      {pos: v4m, norm: wwv_24, },
      {pos: w4m, norm: wwv_24, },

      {pos: w4m, norm: qww_24, },
      {pos: q4m, norm: qww_24, },
      {pos: w6m, norm: qww_24, },
    /* #endregion */
    /* #region cell_vwwq_19 */
      {pos: q4m, norm: qwv_17, },
      {pos: v4m, norm: qwv_17, },
      {pos: w4m, norm: qwv_17, },

      {pos: v4m, norm: qwv_20, },
      {pos: q4m, norm: qwv_20, },
      {pos: w8pm, norm: qwv_20, },

      {pos: w4m, norm: wwv_19, },
      {pos: v4m, norm: wwv_19, },
      {pos: w8pm, norm: wwv_19, },

      {pos: w8pm, norm: qww_19, },
      {pos: q4m, norm: qww_19, },
      {pos: w4m, norm: qww_19, },
    /* #endregion */
    /* #region cell_vwwq_20 */
      {pos: q4m, norm: qwv_20, },
      {pos: v4m, norm: qwv_20, },
      {pos: w8pm, norm: qwv_20, },

      {pos: v4m, norm: qwv_16, },
      {pos: q4m, norm: qwv_16, },
      {pos: w3m, norm: qwv_16, },

      {pos: w8pm, norm: wwv_15, },
      {pos: v4m, norm: wwv_15, },
      {pos: w3m, norm: wwv_15, },

      {pos: w3m, norm: qww_15, },
      {pos: q4m, norm: qww_15, },
      {pos: w8pm, norm: qww_15, },
    /* #endregion */
    /* #region cell_vwwq_21 */
      {pos: q5m, norm: qwv_21, },
      {pos: v5m, norm: qwv_21, },
      {pos: w2m, norm: qwv_21, },

      {pos: v5m, norm: qwv_22, },
      {pos: q5m, norm: qwv_22, },
      {pos: w7m, norm: qwv_22, },

      {pos: w2m, norm: wwv_8, },
      {pos: v5m, norm: wwv_8, },
      {pos: w7m, norm: wwv_8, },

      {pos: w7m, norm: qww_8, },
      {pos: q5m, norm: qww_8, },
      {pos: w2m, norm: qww_8, },
    /* #endregion */
    /* #region cell_vwwq_22 */
      {pos: q5m, norm: qwv_22, },
      {pos: v5m, norm: qwv_22, },
      {pos: w7m, norm: qwv_22, },

      {pos: v5m, norm: qwv_23, },
      {pos: q5m, norm: qwv_23, },
      {pos: w8m, norm: qwv_23, },

      {pos: w7m, norm: wwv_28, },
      {pos: v5m, norm: wwv_28, },
      {pos: w8m, norm: wwv_28, },

      {pos: w8m, norm: qww_28, },
      {pos: q5m, norm: qww_28, },
      {pos: w7m, norm: qww_28, },
    /* #endregion */
    /* #region cell_vwwq_23 */
      {pos: q5m, norm: qwv_23, },
      {pos: v5m, norm: qwv_23, },
      {pos: w8m, norm: qwv_23, },

      {pos: v5m, norm: qwv_24, },
      {pos: q5m, norm: qwv_24, },
      {pos: w3pm, norm: qwv_24, },

      {pos: w8m, norm: wwv_29, },
      {pos: v5m, norm: wwv_29, },
      {pos: w3pm, norm: wwv_29, },

      {pos: w3pm, norm: qww_29, },
      {pos: q5m, norm: qww_29, },
      {pos: w8m, norm: qww_29, },
    /* #endregion */
    /* #region cell_vwwq_24 */
      {pos: q5m, norm: qwv_24, },
      {pos: v5m, norm: qwv_24, },
      {pos: w3pm, norm: qwv_24, },

      {pos: v5m, norm: qwv_25, },
      {pos: q5m, norm: qwv_25, },
      {pos: w10pm, norm: qwv_25, },

      {pos: w3pm, norm: wwv_49, },
      {pos: v5m, norm: wwv_49, },
      {pos: w10pm, norm: wwv_49, },

      {pos: w10pm, norm: qww_49, },
      {pos: q5m, norm: qww_49, },
      {pos: w3pm, norm: qww_49, },
    /* #endregion */
    /* #region cell_vwwq_25 */
      {pos: q5m, norm: qwv_25, },
      {pos: v5m, norm: qwv_25, },
      {pos: w10pm, norm: qwv_25, },

      {pos: v5m, norm: qwv_21, },
      {pos: q5m, norm: qwv_21, },
      {pos: w2m, norm: qwv_21, },

      {pos: w10pm, norm: wwv_9, },
      {pos: v5m, norm: wwv_9, },
      {pos: w2m, norm: wwv_9, },

      {pos: w2m, norm: qww_9, },
      {pos: q5m, norm: qww_9, },
      {pos: w10pm, norm: qww_9, },
    /* #endregion */
    /* #region cell_vwwq_26 */
      {pos: q6m, norm: qwv_26, },
      {pos: v6m, norm: qwv_26, },
      {pos: w9m, norm: qwv_26, },

      {pos: v6m, norm: qwv_27, },
      {pos: q6m, norm: qwv_27, },
      {pos: w10m, norm: qwv_27, },

      {pos: w9m, norm: wwv_34, },
      {pos: v6m, norm: wwv_34, },
      {pos: w10m, norm: wwv_34, },

      {pos: w10m, norm: qww_34, },
      {pos: q6m, norm: qww_34, },
      {pos: w9m, norm: qww_34, },
    /* #endregion */
    /* #region cell_vwwq_27 */
      {pos: q6m, norm: qwv_27, },
      {pos: v6m, norm: qwv_27, },
      {pos: w10m, norm: qwv_27, },

      {pos: v6m, norm: qwv_28, },
      {pos: q6m, norm: qwv_28, },
      {pos: w2pm, norm: qwv_28, },

      {pos: w10m, norm: wwv_37, },
      {pos: v6m, norm: wwv_37, },
      {pos: w2pm, norm: wwv_37, },

      {pos: w2pm, norm: qww_37, },
      {pos: q6m, norm: qww_37, },
      {pos: w10m, norm: qww_37, },
    /* #endregion */
    /* #region cell_vwwq_28 */
      {pos: q6m, norm: qwv_28, },
      {pos: v6m, norm: qwv_28, },
      {pos: w2pm, norm: qwv_28, },

      {pos: v6m, norm: qwv_30, },
      {pos: q6m, norm: qwv_30, },
      {pos: w6pm, norm: qwv_30, },

      {pos: w2pm, norm: wwv_45, },
      {pos: v6m, norm: wwv_45, },
      {pos: w6pm, norm: wwv_45, },

      {pos: w6pm, norm: qww_45, },
      {pos: q6m, norm: qww_45, },
      {pos: w2pm, norm: qww_45, },
    /* #endregion */
    /* #region cell_vwwq_29 */
      {pos: q6m, norm: qwv_30, },
      {pos: v6m, norm: qwv_30, },
      {pos: w6pm, norm: qwv_30, },

      {pos: v6m, norm: qwv_29, },
      {pos: q6m, norm: qwv_29, },
      {pos: w4pm, norm: qwv_29, },

      {pos: w6pm, norm: wwv_53, },
      {pos: v6m, norm: wwv_53, },
      {pos: w4pm, norm: wwv_53, },

      {pos: w4pm, norm: qww_53, },
      {pos: q6m, norm: qww_53, },
      {pos: w6pm, norm: qww_53, },
    /* #endregion */
    /* #region cell_vwwq_30 */
      {pos: q6m, norm: qwv_29, },
      {pos: v6m, norm: qwv_29, },
      {pos: w4pm, norm: qwv_29, },

      {pos: v6m, norm: qwv_26, },
      {pos: q6m, norm: qwv_26, },
      {pos: w9m, norm: qwv_26, },

      {pos: w4pm, norm: wwv_35, },
      {pos: v6m, norm: wwv_35, },
      {pos: w9m, norm: wwv_35, },

      {pos: w9m, norm: qww_35, },
      {pos: q6m, norm: qww_35, },
      {pos: w4pm, norm: qww_35, },
    /* #endregion */
    /* #region cell_vwwq_31 */
      {pos: q1pm, norm: qwv_31, },
      {pos: v1pm, norm: qwv_31, },
      {pos: w1pm, norm: qwv_31, },

      {pos: v1pm, norm: qwv_33, },
      {pos: q1pm, norm: qwv_33, },
      {pos: w5pm, norm: qwv_33, },

      {pos: w1pm, norm: wwv_44, },
      {pos: v1pm, norm: wwv_44, },
      {pos: w5pm, norm: wwv_44, },

      {pos: w5pm, norm: qww_44, },
      {pos: q1pm, norm: qww_44, },
      {pos: w1pm, norm: qww_44, },
    /* #endregion */
    /* #region cell_vwwq_32 */
      {pos: q1pm, norm: qwv_33, },
      {pos: v1pm, norm: qwv_33, },
      {pos: w5pm, norm: qwv_33, },

      {pos: v1pm, norm: qwv_34, },
      {pos: q1pm, norm: qwv_34, },
      {pos: w6pm, norm: qwv_34, },

      {pos: w5pm, norm: wwv_56, },
      {pos: v1pm, norm: wwv_56, },
      {pos: w6pm, norm: wwv_56, },

      {pos: w6pm, norm: qww_56, },
      {pos: q1pm, norm: qww_56, },
      {pos: w5pm, norm: qww_56, },
    /* #endregion */
    /* #region cell_vwwq_33 */
      {pos: q1pm, norm: qwv_34, },
      {pos: v1pm, norm: qwv_34, },
      {pos: w6pm, norm: qwv_34, },

      {pos: v1pm, norm: qwv_32, },
      {pos: q1pm, norm: qwv_32, },
      {pos: w2pm, norm: qwv_32, },

      {pos: w6pm, norm: wwv_46, },
      {pos: v1pm, norm: wwv_46, },
      {pos: w2pm, norm: wwv_46, },

      {pos: w2pm, norm: qww_46, },
      {pos: q1pm, norm: qww_46, },
      {pos: w6pm, norm: qww_46, },
    /* #endregion */
    /* #region cell_vwwq_34 */
      {pos: q1pm, norm: qwv_32, },
      {pos: v1pm, norm: qwv_32, },
      {pos: w2pm, norm: qwv_32, },

      {pos: v1pm, norm: qwv_35, },
      {pos: q1pm, norm: qwv_35, },
      {pos: w7pm, norm: qwv_35, },

      {pos: w2pm, norm: wwv_47, },
      {pos: v1pm, norm: wwv_47, },
      {pos: w7pm, norm: wwv_47, },

      {pos: w7pm, norm: qww_47, },
      {pos: q1pm, norm: qww_47, },
      {pos: w2pm, norm: qww_47, },
    /* #endregion */
    /* #region cell_vwwq_35 */
      {pos: q1pm, norm: qwv_35, },
      {pos: v1pm, norm: qwv_35, },
      {pos: w7pm, norm: qwv_35, },

      {pos: v1pm, norm: qwv_31, },
      {pos: q1pm, norm: qwv_31, },
      {pos: w1pm, norm: qwv_31, },

      {pos: w7pm, norm: wwv_39, },
      {pos: v1pm, norm: wwv_39, },
      {pos: w1pm, norm: wwv_39, },

      {pos: w1pm, norm: qww_39, },
      {pos: q1pm, norm: qww_39, },
      {pos: w7pm, norm: qww_39, },
    /* #endregion */
    /* #region cell_vwwq_36 */
      {pos: q2pm, norm: qwv_38, },
      {pos: v2pm, norm: qwv_38, },
      {pos: w7pm, norm: qwv_38, },

      {pos: v2pm, norm: qwv_37, },
      {pos: q2pm, norm: qwv_37, },
      {pos: w1pm, norm: qwv_37, },

      {pos: w7pm, norm: wwv_40, },
      {pos: v2pm, norm: wwv_40, },
      {pos: w1pm, norm: wwv_40, },

      {pos: w1pm, norm: qww_40, },
      {pos: q2pm, norm: qww_40, },
      {pos: w7pm, norm: qww_40, },
    /* #endregion */
    /* #region cell_vwwq_37 */
      {pos: q2pm, norm: qwv_37, },
      {pos: v2pm, norm: qwv_37, },
      {pos: w1pm, norm: qwv_37, },

      {pos: v2pm, norm: qwv_40, },
      {pos: q2pm, norm: qwv_40, },
      {pos: w9pm, norm: qwv_40, },

      {pos: w1pm, norm: wwv_41, },
      {pos: v2pm, norm: wwv_41, },
      {pos: w9pm, norm: wwv_41, },

      {pos: w9pm, norm: qww_41, },
      {pos: q2pm, norm: qww_41, },
      {pos: w1pm, norm: qww_41, },
    /* #endregion */
    /* #region cell_vwwq_38 */
      {pos: q2pm, norm: qwv_40, },
      {pos: v2pm, norm: qwv_40, },
      {pos: w9pm, norm: qwv_40, },

      {pos: v2pm, norm: qwv_36, },
      {pos: q2pm, norm: qwv_36, },
      {pos: w4m, norm: qwv_36, },

      {pos: w9pm, norm: wwv_21, },
      {pos: v2pm, norm: wwv_21, },
      {pos: w4m, norm: wwv_21, },

      {pos: w4m, norm: qww_21, },
      {pos: q2pm, norm: qww_21, },
      {pos: w9pm, norm: qww_21, },
    /* #endregion */
    /* #region cell_vwwq_39 */
      {pos: q2pm, norm: qwv_36, },
      {pos: v2pm, norm: qwv_36, },
      {pos: w4m, norm: qwv_36, },

      {pos: v2pm, norm: qwv_39, },
      {pos: q2pm, norm: qwv_39, },
      {pos: w8pm, norm: qwv_39, },

      {pos: w4m, norm: wwv_20, },
      {pos: v2pm, norm: wwv_20, },
      {pos: w8pm, norm: wwv_20, },

      {pos: w8pm, norm: qww_20, },
      {pos: q2pm, norm: qww_20, },
      {pos: w4m, norm: qww_20, },
    /* #endregion */
    /* #region cell_vwwq_40 */
      {pos: q2pm, norm: qwv_39, },
      {pos: v2pm, norm: qwv_39, },
      {pos: w8pm, norm: qwv_39, },

      {pos: v2pm, norm: qwv_38, },
      {pos: q2pm, norm: qwv_38, },
      {pos: w7pm, norm: qwv_38, },

      {pos: w8pm, norm: wwv_57, },
      {pos: v2pm, norm: wwv_57, },
      {pos: w7pm, norm: wwv_57, },

      {pos: w7pm, norm: qww_57, },
      {pos: q2pm, norm: qww_57, },
      {pos: w8pm, norm: qww_57, },
    /* #endregion */
    /* #region cell_vwwq_41 */
      {pos: q3pm, norm: qwv_41, },
      {pos: v3pm, norm: qwv_41, },
      {pos: w1pm, norm: qwv_41, },

      {pos: v3pm, norm: qwv_43, },
      {pos: q3pm, norm: qwv_43, },
      {pos: w5pm, norm: qwv_43, },

      {pos: w1pm, norm: wwv_43, },
      {pos: v3pm, norm: wwv_43, },
      {pos: w5pm, norm: wwv_43, },

      {pos: w5pm, norm: qww_43, },
      {pos: q3pm, norm: qww_43, },
      {pos: w1pm, norm: qww_43, },
    /* #endregion */
    /* #region cell_vwwq_42 */
      {pos: q3pm, norm: qwv_43, },
      {pos: v3pm, norm: qwv_43, },
      {pos: w5pm, norm: qwv_43, },

      {pos: v3pm, norm: qwv_42, },
      {pos: q3pm, norm: qwv_42, },
      {pos: w3pm, norm: qwv_42, },

      {pos: w5pm, norm: wwv_51, },
      {pos: v3pm, norm: wwv_51, },
      {pos: w3pm, norm: wwv_51, },

      {pos: w3pm, norm: qww_51, },
      {pos: q3pm, norm: qww_51, },
      {pos: w5pm, norm: qww_51, },
    /* #endregion */
    /* #region cell_vwwq_43 */
      {pos: q3pm, norm: qwv_42, },
      {pos: v3pm, norm: qwv_42, },
      {pos: w3pm, norm: qwv_42, },

      {pos: v3pm, norm: qwv_45, },
      {pos: q3pm, norm: qwv_45, },
      {pos: w10pm, norm: qwv_45, },

      {pos: w3pm, norm: wwv_50, },
      {pos: v3pm, norm: wwv_50, },
      {pos: w10pm, norm: wwv_50, },

      {pos: w10pm, norm: qww_50, },
      {pos: q3pm, norm: qww_50, },
      {pos: w3pm, norm: qww_50, },
    /* #endregion */
    /* #region cell_vwwq_44 */
      {pos: q3pm, norm: qwv_45, },
      {pos: v3pm, norm: qwv_45, },
      {pos: w10pm, norm: qwv_45, },

      {pos: v3pm, norm: qwv_44, },
      {pos: q3pm, norm: qwv_44, },
      {pos: w9pm, norm: qwv_44, },

      {pos: w10pm, norm: wwv_59, },
      {pos: v3pm, norm: wwv_59, },
      {pos: w9pm, norm: wwv_59, },

      {pos: w9pm, norm: qww_59, },
      {pos: q3pm, norm: qww_59, },
      {pos: w10pm, norm: qww_59, },
    /* #endregion */
    /* #region cell_vwwq_45 */
      {pos: q3pm, norm: qwv_44, },
      {pos: v3pm, norm: qwv_44, },
      {pos: w9pm, norm: qwv_44, },

      {pos: v3pm, norm: qwv_41, },
      {pos: q3pm, norm: qwv_41, },
      {pos: w1pm, norm: qwv_41, },

      {pos: w9pm, norm: wwv_42, },
      {pos: v3pm, norm: wwv_42, },
      {pos: w1pm, norm: wwv_42, },

      {pos: w1pm, norm: qww_42, },
      {pos: q3pm, norm: qww_42, },
      {pos: w9pm, norm: qww_42, },
    /* #endregion */
    /* #region cell_vwwq_46 */
      {pos: q4pm, norm: qwv_47, },
      {pos: v4pm, norm: qwv_47, },
      {pos: w3pm, norm: qwv_47, },

      {pos: v4pm, norm: qwv_49, },
      {pos: q4pm, norm: qwv_49, },
      {pos: w5pm, norm: qwv_49, },

      {pos: w3pm, norm: wwv_52, },
      {pos: v4pm, norm: wwv_52, },
      {pos: w5pm, norm: wwv_52, },

      {pos: w5pm, norm: qww_52, },
      {pos: q4pm, norm: qww_52, },
      {pos: w3pm, norm: qww_52, },
    /* #endregion */
    /* #region cell_vwwq_47 */
      {pos: q4pm, norm: qwv_49, },
      {pos: v4pm, norm: qwv_49, },
      {pos: w5pm, norm: qwv_49, },

      {pos: v4pm, norm: qwv_50, },
      {pos: q4pm, norm: qwv_50, },
      {pos: w6pm, norm: qwv_50, },

      {pos: w5pm, norm: wwv_55, },
      {pos: v4pm, norm: wwv_55, },
      {pos: w6pm, norm: wwv_55, },

      {pos: w6pm, norm: qww_55, },
      {pos: q4pm, norm: qww_55, },
      {pos: w5pm, norm: qww_55, },
    /* #endregion */
    /* #region cell_vwwq_48 */
      {pos: q4pm, norm: qwv_50, },
      {pos: v4pm, norm: qwv_50, },
      {pos: w6pm, norm: qwv_50, },

      {pos: v4pm, norm: qwv_48, },
      {pos: q4pm, norm: qwv_48, },
      {pos: w4pm, norm: qwv_48, },

      {pos: w6pm, norm: wwv_54, },
      {pos: v4pm, norm: wwv_54, },
      {pos: w4pm, norm: wwv_54, },

      {pos: w4pm, norm: qww_54, },
      {pos: q4pm, norm: qww_54, },
      {pos: w6pm, norm: qww_54, },
    /* #endregion */
    /* #region cell_vwwq_49 */
      {pos: q4pm, norm: qwv_48, },
      {pos: v4pm, norm: qwv_48, },
      {pos: w4pm, norm: qwv_48, },

      {pos: v4pm, norm: qwv_46, },
      {pos: q4pm, norm: qwv_46, },
      {pos: w8m, norm: qwv_46, },

      {pos: w4pm, norm: wwv_31, },
      {pos: v4pm, norm: wwv_31, },
      {pos: w8m, norm: wwv_31, },

      {pos: w8m, norm: qww_31, },
      {pos: q4pm, norm: qww_31, },
      {pos: w4pm, norm: qww_31, },
    /* #endregion */
    /* #region cell_vwwq_50 */
      {pos: q4pm, norm: qwv_46, },
      {pos: v4pm, norm: qwv_46, },
      {pos: w8m, norm: qwv_46, },

      {pos: v4pm, norm: qwv_47, },
      {pos: q4pm, norm: qwv_47, },
      {pos: w3pm, norm: qwv_47, },

      {pos: w8m, norm: wwv_30, },
      {pos: v4pm, norm: wwv_30, },
      {pos: w3pm, norm: wwv_30, },

      {pos: w3pm, norm: qww_30, },
      {pos: q4pm, norm: qww_30, },
      {pos: w8m, norm: qww_30, },
    /* #endregion */
    /* #region cell_vwwq_51 */
      {pos: q5pm, norm: qwv_53, },
      {pos: v5pm, norm: qwv_53, },
      {pos: w2pm, norm: qwv_53, },

      {pos: v5pm, norm: qwv_54, },
      {pos: q5pm, norm: qwv_54, },
      {pos: w7pm, norm: qwv_54, },

      {pos: w2pm, norm: wwv_48, },
      {pos: v5pm, norm: wwv_48, },
      {pos: w7pm, norm: wwv_48, },

      {pos: w7pm, norm: qww_48, },
      {pos: q5pm, norm: qww_48, },
      {pos: w2pm, norm: qww_48, },
    /* #endregion */
    /* #region cell_vwwq_52 */
      {pos: q5pm, norm: qwv_54, },
      {pos: v5pm, norm: qwv_54, },
      {pos: w7pm, norm: qwv_54, },

      {pos: v5pm, norm: qwv_55, },
      {pos: q5pm, norm: qwv_55, },
      {pos: w8pm, norm: qwv_55, },

      {pos: w7pm, norm: wwv_58, },
      {pos: v5pm, norm: wwv_58, },
      {pos: w8pm, norm: wwv_58, },

      {pos: w8pm, norm: qww_58, },
      {pos: q5pm, norm: qww_58, },
      {pos: w7pm, norm: qww_58, },
    /* #endregion */
    /* #region cell_vwwq_53 */
      {pos: q5pm, norm: qwv_55, },
      {pos: v5pm, norm: qwv_55, },
      {pos: w8pm, norm: qwv_55, },

      {pos: v5pm, norm: qwv_51, },
      {pos: q5pm, norm: qwv_51, },
      {pos: w3m, norm: qwv_51, },

      {pos: w8pm, norm: wwv_16, },
      {pos: v5pm, norm: wwv_16, },
      {pos: w3m, norm: wwv_16, },

      {pos: w3m, norm: qww_16, },
      {pos: q5pm, norm: qww_16, },
      {pos: w8pm, norm: qww_16, },
    /* #endregion */
    /* #region cell_vwwq_54 */
      {pos: q5pm, norm: qwv_51, },
      {pos: v5pm, norm: qwv_51, },
      {pos: w3m, norm: qwv_51, },

      {pos: v5pm, norm: qwv_52, },
      {pos: q5pm, norm: qwv_52, },
      {pos: w10m, norm: qwv_52, },

      {pos: w3m, norm: wwv_17, },
      {pos: v5pm, norm: wwv_17, },
      {pos: w10m, norm: wwv_17, },

      {pos: w10m, norm: qww_17, },
      {pos: q5pm, norm: qww_17, },
      {pos: w3m, norm: qww_17, },
    /* #endregion */
    /* #region cell_vwwq_55 */
      {pos: q5pm, norm: qwv_52, },
      {pos: v5pm, norm: qwv_52, },
      {pos: w10m, norm: qwv_52, },

      {pos: v5pm, norm: qwv_53, },
      {pos: q5pm, norm: qwv_53, },
      {pos: w2pm, norm: qwv_53, },

      {pos: w10m, norm: wwv_38, },
      {pos: v5pm, norm: wwv_38, },
      {pos: w2pm, norm: wwv_38, },

      {pos: w2pm, norm: qww_38, },
      {pos: q5pm, norm: qww_38, },
      {pos: w10m, norm: qww_38, },
    /* #endregion */
    /* #region cell_vwwq_56 */
      {pos: q6pm, norm: qwv_59, },
      {pos: v6pm, norm: qwv_59, },
      {pos: w9pm, norm: qwv_59, },

      {pos: v6pm, norm: qwv_60, },
      {pos: q6pm, norm: qwv_60, },
      {pos: w10pm, norm: qwv_60, },

      {pos: w9pm, norm: wwv_60, },
      {pos: v6pm, norm: wwv_60, },
      {pos: w10pm, norm: wwv_60, },

      {pos: w10pm, norm: qww_60, },
      {pos: q6pm, norm: qww_60, },
      {pos: w9pm, norm: qww_60, },
    /* #endregion */
    /* #region cell_vwwq_57 */
      {pos: q6pm, norm: qwv_60, },
      {pos: v6pm, norm: qwv_60, },
      {pos: w10pm, norm: qwv_60, },

      {pos: v6pm, norm: qwv_56, },
      {pos: q6pm, norm: qwv_56, },
      {pos: w2m, norm: qwv_56, },

      {pos: w10pm, norm: wwv_10, },
      {pos: v6pm, norm: wwv_10, },
      {pos: w2m, norm: wwv_10, },

      {pos: w2m, norm: qww_10, },
      {pos: q6pm, norm: qww_10, },
      {pos: w10pm, norm: qww_10, },
    /* #endregion */
    /* #region cell_vwwq_58 */
      {pos: q6pm, norm: qwv_56, },
      {pos: v6pm, norm: qwv_56, },
      {pos: w2m, norm: qwv_56, },

      {pos: v6pm, norm: qwv_58, },
      {pos: q6pm, norm: qwv_58, },
      {pos: w6m, norm: qwv_58, },

      {pos: w2m, norm: wwv_11, },
      {pos: v6pm, norm: wwv_11, },
      {pos: w6m, norm: wwv_11, },

      {pos: w6m, norm: qww_11, },
      {pos: q6pm, norm: qww_11, },
      {pos: w2m, norm: qww_11, },
    /* #endregion */
    /* #region cell_vwwq_59 */
      {pos: q6pm, norm: qwv_58, },
      {pos: v6pm, norm: qwv_58, },
      {pos: w6m, norm: qwv_58, },

      {pos: v6pm, norm: qwv_57, },
      {pos: q6pm, norm: qwv_57, },
      {pos: w4m, norm: qwv_57, },

      {pos: w6m, norm: wwv_23, },
      {pos: v6pm, norm: wwv_23, },
      {pos: w4m, norm: wwv_23, },

      {pos: w4m, norm: qww_23, },
      {pos: q6pm, norm: qww_23, },
      {pos: w6m, norm: qww_23, },
    /* #endregion */
    /* #region cell_vwwq_60 */
      {pos: q6pm, norm: qwv_57, },
      {pos: v6pm, norm: qwv_57, },
      {pos: w4m, norm: qwv_57, },

      {pos: v6pm, norm: qwv_59, },
      {pos: q6pm, norm: qwv_59, },
      {pos: w9pm, norm: qwv_59, },

      {pos: w4m, norm: wwv_22, },
      {pos: v6pm, norm: wwv_22, },
      {pos: w9pm, norm: wwv_22, },

      {pos: w9pm, norm: qww_22, },
      {pos: q6pm, norm: qww_22, },
      {pos: w4m, norm: qww_22, },
    /* #endregion */
    ]} else {
    verts12 = []
  }
  /* #endregion */
  /* #region wwqq cells (30) */
  if (layer13) {
    verts13 = [
    /* #region cell_wwqq_1 */
      {pos: w1m, norm: qqw_1, },
      {pos: q2m, norm: qqw_1, },
      {pos: q1m, norm: qqw_1, },

      {pos: w7m, norm: qqw_19, },
      {pos: q1m, norm: qqw_19, },
      {pos: q2m, norm: qqw_19, },

      {pos: w7m, norm: qww_1, },
      {pos: w1m, norm: qww_1, },
      {pos: q1m, norm: qww_1, },
      
      {pos: w1m, norm: qww_2, },
      {pos: w7m, norm: qww_2, },
      {pos: q2m, norm: qww_2, },
    /* #endregion */
    /* #region cell_wwqq_2 */
      {pos: w1m, norm: qqw_2, },
      {pos: q3m, norm: qqw_2, },
      {pos: q2m, norm: qqw_2, },

      {pos: w9m, norm: qqw_25, },
      {pos: q2m, norm: qqw_25, },
      {pos: q3m, norm: qqw_25, },

      {pos: w1m, norm: qww_3, },
      {pos: w9m, norm: qww_3, },
      {pos: q2m, norm: qww_3, },

      {pos: w9m, norm: qww_4, },
      {pos: w1m, norm: qww_4, },
      {pos: q3m, norm: qww_4, },
    /* #endregion */
    /* #region cell_wwqq_3 */
      {pos: w1m, norm: qqw_3, },
      {pos: q3m, norm: qqw_3, },
      {pos: q1m, norm: qqw_3, },

      {pos: w5m, norm: qqw_13, },
      {pos: q1m, norm: qqw_13, },
      {pos: q3m, norm: qqw_13, },

      {pos: w1m, norm: qww_5, },
      {pos: w5m, norm: qww_5, },
      {pos: q3m, norm: qww_5, },

      {pos: w5m, norm: qww_6, },
      {pos: w1m, norm: qww_6, },
      {pos: q1m, norm: qww_6, },
    /* #endregion */
    /* #region cell_wwqq_4 */
      {pos: w2m, norm: qqw_4, },
      {pos: q1m, norm: qqw_4, },
      {pos: q5m, norm: qqw_4, },

      {pos: w7m, norm: qqw_21, },
      {pos: q5m, norm: qqw_21, },
      {pos: q1m, norm: qqw_21, },

      {pos: w2m, norm: qww_7, },
      {pos: w7m, norm: qww_7, },
      {pos: q1m, norm: qww_7, },

      {pos: w7m, norm: qww_8, },
      {pos: w2m, norm: qww_8, },
      {pos: q5m, norm: qww_8, },
    /* #endregion */
    /* #region cell_wwqq_5 */
      {pos: w2m, norm: qqw_5, },
      {pos: q5m, norm: qqw_5, },
      {pos: q6pm, norm: qqw_5, },

      {pos: w10pm, norm: qqw_60, },
      {pos: q6pm, norm: qqw_60, },
      {pos: q5m, norm: qqw_60, },

      {pos: w2m, norm: qww_9, },
      {pos: w10pm, norm: qww_9, },
      {pos: q5m, norm: qww_9, },

      {pos: w10pm, norm: qww_10, },
      {pos: w2m, norm: qww_10, },
      {pos: q6pm, norm: qww_10, },
    /* #endregion */
    /* #region cell_wwqq_6 */
      {pos: w2m, norm: qqw_6, },
      {pos: q6pm, norm: qqw_6, },
      {pos: q1m, norm: qqw_6, },

      {pos: w6m, norm: qqw_18, },
      {pos: q1m, norm: qqw_18, },
      {pos: q6pm, norm: qqw_18, },

      {pos: w2m, norm: qww_11, },
      {pos: w6m, norm: qww_11, },
      {pos: q6pm, norm: qww_11, },

      {pos: w6m, norm: qww_12, },
      {pos: w2m, norm: qww_12, },
      {pos: q1m, norm: qww_12, },
    /* #endregion */
    /* #region cell_wwqq_7 */
      {pos: w3m, norm: qqw_7, },
      {pos: q3m, norm: qqw_7, },
      {pos: q4m, norm: qqw_7, },

      {pos: w5m, norm: qqw_14, },
      {pos: q4m, norm: qqw_14, },
      {pos: q3m, norm: qqw_14, },

      {pos: w3m, norm: qww_13, },
      {pos: w5m, norm: qww_13, },
      {pos: q3m, norm: qww_13, },

      {pos: w5m, norm: qww_14, },
      {pos: w3m, norm: qww_14, },
      {pos: q4m, norm: qww_14, },
    /* #endregion */
    /* #region cell_wwqq_8 */
      {pos: w3m, norm: qqw_8, },
      {pos: q4m, norm: qqw_8, },
      {pos: q5pm, norm: qqw_8, },

      {pos: w8pm, norm: qqw_54, },
      {pos: q5pm, norm: qqw_54, },
      {pos: q4m, norm: qqw_54, },

      {pos: w3m, norm: qww_15, },
      {pos: w8pm, norm: qww_15, },
      {pos: q4m, norm: qww_15, },

      {pos: w8pm, norm: qww_16, },
      {pos: w3m, norm: qww_16, },
      {pos: q5pm, norm: qww_16, },
    /* #endregion */
    /* #region cell_wwqq_9 */
      {pos: w3m, norm: qqw_9, },
      {pos: q5pm, norm: qqw_9, },
      {pos: q3m, norm: qqw_9, },

      {pos: w10m, norm: qqw_30, },
      {pos: q3m, norm: qqw_30, },
      {pos: q5pm, norm: qqw_30, },

      {pos: w3m, norm: qww_17, },
      {pos: w10m, norm: qww_17, },
      {pos: q5pm, norm: qww_17, },

      {pos: w10m, norm: qww_18, },
      {pos: w3m, norm: qww_18, },
      {pos: q3m, norm: qww_18, },
    /* #endregion */
    /* #region cell_wwqq_10 */
      {pos: w4m, norm: qqw_10, },
      {pos: q4m, norm: qqw_10, },
      {pos: q2pm, norm: qqw_10, },

      {pos: w8pm, norm: qqw_52, },
      {pos: q2pm, norm: qqw_52, },
      {pos: q4m, norm: qqw_52, },

      {pos: w4m, norm: qww_19, },
      {pos: w8pm, norm: qww_19, },
      {pos: q4m, norm: qww_19, },

      {pos: w8pm, norm: qww_20, },
      {pos: w4m, norm: qww_20, },
      {pos: q2pm, norm: qww_20, },
    /* #endregion */
    /* #region cell_wwqq_11 */
      {pos: w4m, norm: qqw_11, },
      {pos: q2pm, norm: qqw_11, },
      {pos: q6pm, norm: qqw_11, },

      {pos: w9pm, norm: qqw_57, },
      {pos: q6pm, norm: qqw_57, },
      {pos: q2pm, norm: qqw_57, },

      {pos: w4m, norm: qww_21, },
      {pos: w9pm, norm: qww_21, },
      {pos: q2pm, norm: qww_21, },

      {pos: w9pm, norm: qww_22, },
      {pos: w4m, norm: qww_22, },
      {pos: q6pm, norm: qww_22, },
    /* #endregion */
    /* #region cell_wwqq_12 */
      {pos: w4m, norm: qqw_12, },
      {pos: q6pm, norm: qqw_12, },
      {pos: q4m, norm: qqw_12, },

      {pos: w6m, norm: qqw_17, },
      {pos: q4m, norm: qqw_17, },
      {pos: q6pm, norm: qqw_17, },

      {pos: w4m, norm: qww_23, },
      {pos: w6m, norm: qww_23, },
      {pos: q6pm, norm: qww_23, },

      {pos: w6m, norm: qww_24, },
      {pos: w4m, norm: qww_24, },
      {pos: q4m, norm: qww_24, },
    /* #endregion */
    /* #region cell_wwqq_13 */
      {pos: w5m, norm: qqw_15, },
      {pos: q4m, norm: qqw_15, },
      {pos: q1m, norm: qqw_15, },

      {pos: w6m, norm: qqw_16, },
      {pos: q1m, norm: qqw_16, },
      {pos: q4m, norm: qqw_16, },

      {pos: w5m, norm: qww_25, },
      {pos: w6m, norm: qww_25, },
      {pos: q4m, norm: qww_25, },

      {pos: w6m, norm: qww_26, },
      {pos: w5m, norm: qww_26, },
      {pos: q1m, norm: qww_26, },
    /* #endregion */
    /* #region cell_wwqq_14 */
      {pos: w7m, norm: qqw_20, },
      {pos: q2m, norm: qqw_20, },
      {pos: q5m, norm: qqw_20, },

      {pos: w8m, norm: qqw_22, },
      {pos: q5m, norm: qqw_22, },
      {pos: q2m, norm: qqw_22, },

      {pos: w7m, norm: qww_27, },
      {pos: w8m, norm: qww_27, },
      {pos: q2m, norm: qww_27, },

      {pos: w8m, norm: qww_28, },
      {pos: w7m, norm: qww_28, },
      {pos: q5m, norm: qww_28, },
    /* #endregion */
    /* #region cell_wwqq_15 */
      {pos: w8m, norm: qqw_23, },
      {pos: q5m, norm: qqw_23, },
      {pos: q4pm, norm: qqw_23, },

      {pos: w3pm, norm: qqw_39, },
      {pos: q4pm, norm: qqw_39, },
      {pos: q5m, norm: qqw_39, },

      {pos: w8m, norm: qww_29, },
      {pos: w3pm, norm: qww_29, },
      {pos: q5m, norm: qww_29, },

      {pos: w3pm, norm: qww_30, },
      {pos: w8m, norm: qww_30, },
      {pos: q4pm, norm: qww_30, },
    /* #endregion */
    /* #region cell_wwqq_16 */
      {pos: w8m, norm: qqw_24, },
      {pos: q4pm, norm: qqw_24, },
      {pos: q2m, norm: qqw_24, },

      {pos: w4pm, norm: qqw_42, },
      {pos: q2m, norm: qqw_42, },
      {pos: q4pm, norm: qqw_42, },

      {pos: w8m, norm: qww_31, },
      {pos: w4pm, norm: qww_31, },
      {pos: q4pm, norm: qww_31, },

      {pos: w4pm, norm: qww_32, },
      {pos: w8m, norm: qww_32, },
      {pos: q2m, norm: qww_32, },
    /* #endregion */
    /* #region cell_wwqq_17 */
      {pos: w9m, norm: qqw_26, },
      {pos: q3m, norm: qqw_26, },
      {pos: q6m, norm: qqw_26, },

      {pos: w10m, norm: qqw_28, },
      {pos: q6m, norm: qqw_28, },
      {pos: q3m, norm: qqw_28, },

      {pos: w9m, norm: qww_33, },
      {pos: w10m, norm: qww_33, },
      {pos: q3m, norm: qww_33, },

      {pos: w10m, norm: qww_34, },
      {pos: w9m, norm: qww_34, },
      {pos: q6m, norm: qww_34, },
    /* #endregion */
    /* #region cell_wwqq_18 */
      {pos: w9m, norm: qqw_27, },
      {pos: q6m, norm: qqw_27, },
      {pos: q2m, norm: qqw_27, },

      {pos: w4pm, norm: qqw_40, },
      {pos: q2m, norm: qqw_40, },
      {pos: q6m, norm: qqw_40, },

      {pos: w9m, norm: qww_35, },
      {pos: w4pm, norm: qww_35, },
      {pos: q6m, norm: qww_35, },

      {pos: w4pm, norm: qww_36, },
      {pos: w9m, norm: qww_36, },
      {pos: q2m, norm: qww_36, },
    /* #endregion */
    /* #region cell_wwqq_19 */
      {pos: w10m, norm: qqw_29, },
      {pos: q6m, norm: qqw_29, },
      {pos: q5pm, norm: qqw_29, },

      {pos: w2pm, norm: qqw_36, },
      {pos: q5pm, norm: qqw_36, },
      {pos: q6m, norm: qqw_36, },

      {pos: w10m, norm: qww_37, },
      {pos: w2pm, norm: qww_37, },
      {pos: q6m, norm: qww_37, },

      {pos: w2pm, norm: qww_38, },
      {pos: w10m, norm: qww_38, },
      {pos: q5pm, norm: qww_38, },
    /* #endregion */
    /* #region cell_wwqq_20 */
      {pos: w1pm, norm: qqw_31, },
      {pos: q1pm, norm: qqw_31, },
      {pos: q2pm, norm: qqw_31, },

      {pos: w7pm, norm: qqw_49, },
      {pos: q2pm, norm: qqw_49, },
      {pos: q1pm, norm: qqw_49, },

      {pos: w1pm, norm: qww_39, },
      {pos: w7pm, norm: qww_39, },
      {pos: q1pm, norm: qww_39, },

      {pos: w7pm, norm: qww_40, },
      {pos: w1pm, norm: qww_40, },
      {pos: q2pm, norm: qww_40, },
    /* #endregion */
    /* #region cell_wwqq_21 */
      {pos: w1pm, norm: qqw_32, },
      {pos: q2pm, norm: qqw_32, },
      {pos: q3pm, norm: qqw_32, },

      {pos: w9pm, norm: qqw_55, },
      {pos: q3pm, norm: qqw_55, },
      {pos: q2pm, norm: qqw_55, },

      {pos: w1pm, norm: qww_41, },
      {pos: w9pm, norm: qww_41, },
      {pos: q2pm, norm: qww_41, },

      {pos: w9pm, norm: qww_42, },
      {pos: w1pm, norm: qww_42, },
      {pos: q3pm, norm: qww_42, },
    /* #endregion */
    /* #region cell_wwqq_22 */
      {pos: w1pm, norm: qqw_33, },
      {pos: q3pm, norm: qqw_33, },
      {pos: q1pm, norm: qqw_33, },

      {pos: w5pm, norm: qqw_43, },
      {pos: q1pm, norm: qqw_43, },
      {pos: q3pm, norm: qqw_43, },

      {pos: w1pm, norm: qww_43, },
      {pos: w5pm, norm: qww_43, },
      {pos: q3pm, norm: qww_43, },

      {pos: w5pm, norm: qww_44, },
      {pos: w1pm, norm: qww_44, },
      {pos: q1pm, norm: qww_44, },
    /* #endregion */
    /* #region cell_wwqq_23 */
      {pos: w2pm, norm: qqw_34, },
      {pos: q6m, norm: qqw_34, },
      {pos: q1pm, norm: qqw_34, },

      {pos: w6pm, norm: qqw_46, },
      {pos: q1pm, norm: qqw_46, },
      {pos: q6m, norm: qqw_46, },

      {pos: w2pm, norm: qww_45, },
      {pos: w6pm, norm: qww_45, },
      {pos: q6m, norm: qww_45, },

      {pos: w6pm, norm: qww_46, },
      {pos: w2pm, norm: qww_46, },
      {pos: q1pm, norm: qww_46, },
    /* #endregion */
    /* #region cell_wwqq_24 */
      {pos: w2pm, norm: qqw_35, },
      {pos: q1pm, norm: qqw_35, },
      {pos: q5pm, norm: qqw_35, },

      {pos: w7pm, norm: qqw_51, },
      {pos: q5pm, norm: qqw_51, },
      {pos: q1pm, norm: qqw_51, },

      {pos: w2pm, norm: qww_47, },
      {pos: w7pm, norm: qww_47, },
      {pos: q1pm, norm: qww_47, },

      {pos: w7pm, norm: qww_48, },
      {pos: w2pm, norm: qww_48, },
      {pos: q5pm, norm: qww_48, },
    /* #endregion */
    /* #region cell_wwqq_25 */
      {pos: w3pm, norm: qqw_37, },
      {pos: q5m, norm: qqw_37, },
      {pos: q3pm, norm: qqw_37, },

      {pos: w10pm, norm: qqw_58, },
      {pos: q3pm, norm: qqw_58, },
      {pos: q5m, norm: qqw_58, },

      {pos: w3pm, norm: qww_49, },
      {pos: w10pm, norm: qww_49, },
      {pos: q5m, norm: qww_49, },

      {pos: w10pm, norm: qww_50, },
      {pos: w3pm, norm: qww_50, },
      {pos: q3pm, norm: qww_50, },
    /* #endregion */
    /* #region cell_wwqq_26 */
      {pos: w3pm, norm: qqw_38, },
      {pos: q3pm, norm: qqw_38, },
      {pos: q4pm, norm: qqw_38, },

      {pos: w5pm, norm: qqw_44, },
      {pos: q4pm, norm: qqw_44, },
      {pos: q3pm, norm: qqw_44, },

      {pos: w3pm, norm: qww_51, },
      {pos: w5pm, norm: qww_51, },
      {pos: q3pm, norm: qww_51, },

      {pos: w5pm, norm: qww_52, },
      {pos: w3pm, norm: qww_52, },
      {pos: q4pm, norm: qww_52, },
    /* #endregion */
    /* #region cell_wwqq_27 */
      {pos: w4pm, norm: qqw_41, },
      {pos: q6m, norm: qqw_41, },
      {pos: q4pm, norm: qqw_41, },

      {pos: w6pm, norm: qqw_48, },
      {pos: q4pm, norm: qqw_48, },
      {pos: q6m, norm: qqw_48, },

      {pos: w4pm, norm: qww_53, },
      {pos: w6pm, norm: qww_53, },
      {pos: q6m, norm: qww_53, },

      {pos: w6pm, norm: qww_54, },
      {pos: w4pm, norm: qww_54, },
      {pos: q4pm, norm: qww_54, },
    /* #endregion */
    /* #region cell_wwqq_28 */
      {pos: w5pm, norm: qqw_45, },
      {pos: q4pm, norm: qqw_45, },
      {pos: q1pm, norm: qqw_45, },

      {pos: w6pm, norm: qqw_47, },
      {pos: q1pm, norm: qqw_47, },
      {pos: q4pm, norm: qqw_47, },

      {pos: w5pm, norm: qww_55, },
      {pos: w6pm, norm: qww_55, },
      {pos: q4pm, norm: qww_55, },

      {pos: w6pm, norm: qww_56, },
      {pos: w5pm, norm: qww_56, },
      {pos: q1pm, norm: qww_56, },
    /* #endregion */
    /* #region cell_wwqq_29 */
      {pos: w7pm, norm: qqw_50, },
      {pos: q2pm, norm: qqw_50, },
      {pos: q5pm, norm: qqw_50, },

      {pos: w8pm, norm: qqw_53, },
      {pos: q5pm, norm: qqw_53, },
      {pos: q2pm, norm: qqw_53, },

      {pos: w7pm, norm: qww_57, },
      {pos: w8pm, norm: qww_57, },
      {pos: q2pm, norm: qww_57, },

      {pos: w8pm, norm: qww_58, },
      {pos: w7pm, norm: qww_58, },
      {pos: q5pm, norm: qww_58, },
    /* #endregion */
    /* #region cell_wwqq_30 */
      {pos: w9pm, norm: qqw_56, },
      {pos: q3pm, norm: qqw_56, },
      {pos: q6pm, norm: qqw_56, },

      {pos: w10pm, norm: qqw_59, },
      {pos: q6pm, norm: qqw_59, },
      {pos: q3pm, norm: qqw_59, },

      {pos: w9pm, norm: qww_59, },
      {pos: w10pm, norm: qww_59, },
      {pos: q3pm, norm: qww_59, },

      {pos: w10pm, norm: qww_60, },
      {pos: w9pm, norm: qww_60, },
      {pos: q6pm, norm: qww_60, },
    /* #endregion */
    ]} else {
    verts13 = []
  }
  /* #endregion */
  /* #region wqqq cells (20) */
  if (layer14) {  
    verts14 = [
    /* #region cell_wqqq_1 */
      {pos: w1m, norm: qqw_1, },
      {pos: q1m, norm: qqw_1, },
      {pos: q2m, norm: qqw_1, },

      {pos: w1m, norm: qqw_2, },
      {pos: q2m, norm: qqw_2, },
      {pos: q3m, norm: qqw_2, },

      {pos: w1m, norm: qqw_3, },
      {pos: q3m, norm: qqw_3, },
      {pos: q1m, norm: qqw_3, },

      {pos: q1m, norm: qqq_1, },
      {pos: q3m, norm: qqq_1, },
      {pos: q2m, norm: qqq_1, },
    /* #endregion */
    /* #region cell_wqqq_2 */
      {pos: w2m, norm: qqw_4, },
      {pos: q5m, norm: qqw_4, },
      {pos: q1m, norm: qqw_4, },

      {pos: w2m, norm: qqw_5, },
      {pos: q6pm, norm: qqw_5, },
      {pos: q5m, norm: qqw_5, },

      {pos: w2m, norm: qqw_6, },
      {pos: q1m, norm: qqw_6, },
      {pos: q6pm, norm: qqw_6, },

      {pos: q1m, norm: qqq_5, },
      {pos: q5m, norm: qqq_5, },
      {pos: q6pm, norm: qqq_5, },
    /* #endregion */
    /* #region cell_wqqq_3 */
      {pos: w3m, norm: qqw_7, },
      {pos: q4m, norm: qqw_7, },
      {pos: q3m, norm: qqw_7, },

      {pos: w3m, norm: qqw_8, },
      {pos: q5pm, norm: qqw_8, },
      {pos: q4m, norm: qqw_8, },

      {pos: w3m, norm: qqw_9, },
      {pos: q3m, norm: qqw_9, },
      {pos: q5pm, norm: qqw_9, },

      {pos: q4m, norm: qqq_9, },
      {pos: q5pm, norm: qqq_9, },
      {pos: q3m, norm: qqq_9, },
    /* #endregion */
    /* #region cell_wqqq_4 */
      {pos: w4m, norm: qqw_10, },
      {pos: q4m, norm: qqw_10, },
      {pos: q2pm, norm: qqw_10, },

      {pos: w4m, norm: qqw_11, },
      {pos: q2pm, norm: qqw_11, },
      {pos: q6pm, norm: qqw_11, },

      {pos: w4m, norm: qqw_12, },
      {pos: q6pm, norm: qqw_12, },
      {pos: q4m, norm: qqw_12, },

      {pos: q4m, norm: qqq_12, },
      {pos: q6pm, norm: qqq_12, },
      {pos: q2pm, norm: qqq_12, },
    /* #endregion */
    /* #region cell_wqqq_5 */
      {pos: w5m, norm: qqw_13, },
      {pos: q1m, norm: qqw_13, },
      {pos: q3m, norm: qqw_13, },

      {pos: w5m, norm: qqw_14, },
      {pos: q3m, norm: qqw_14, },
      {pos: q4m, norm: qqw_14, },

      {pos: w5m, norm: qqw_15, },
      {pos: q4m, norm: qqw_15, },
      {pos: q1m, norm: qqw_15, },

      {pos: q1m, norm: qqq_3, },
      {pos: q4m, norm: qqq_3, },
      {pos: q3m, norm: qqq_3, },
    /* #endregion */
    /* #region cell_wqqq_6 */
      {pos: w6m, norm: qqw_16, },
      {pos: q1m, norm: qqw_16, },
      {pos: q4m, norm: qqw_16, },

      {pos: w6m, norm: qqw_17, },
      {pos: q4m, norm: qqw_17, },
      {pos: q6pm, norm: qqw_17, },

      {pos: w6m, norm: qqw_18, },
      {pos: q6pm, norm: qqw_18, },
      {pos: q1m, norm: qqw_18, },

      {pos: q1m, norm: qqq_4, },
      {pos: q6pm, norm: qqq_4, },
      {pos: q4m, norm: qqq_4, },
    /* #endregion */
    /* #region cell_wqqq_7 */
      {pos: w7m, norm: qqw_19, },
      {pos: q2m, norm: qqw_19, },
      {pos: q1m, norm: qqw_19, },

      {pos: w7m, norm: qqw_20, },
      {pos: q5m, norm: qqw_20, },
      {pos: q2m, norm: qqw_20, },

      {pos: w7m, norm: qqw_21, },
      {pos: q1m, norm: qqw_21, },
      {pos: q5m, norm: qqw_21, },

      {pos: q2m, norm: qqq_2, },
      {pos: q5m, norm: qqq_2, },
      {pos: q1m, norm: qqq_2, },
    /* #endregion */
    /* #region cell_wqqq_8 */
      {pos: w8m, norm: qqw_22, },
      {pos: q2m, norm: qqw_22, },
      {pos: q5m, norm: qqw_22, },

      {pos: w8m, norm: qqw_23, },
      {pos: q5m, norm: qqw_23, },
      {pos: q4pm, norm: qqw_23, },

      {pos: w8m, norm: qqw_24, },
      {pos: q4pm, norm: qqw_24, },
      {pos: q2m, norm: qqw_24, },

      {pos: q5m, norm: qqq_7, },
      {pos: q2m, norm: qqq_7, },
      {pos: q4pm, norm: qqq_7, },
    /* #endregion */
    /* #region cell_wqqq_9 */
      {pos: w9m, norm: qqw_25, },
      {pos: q3m, norm: qqw_25, },
      {pos: q2m, norm: qqw_25, },

      {pos: w9m, norm: qqw_26, },
      {pos: q6m, norm: qqw_26, },
      {pos: q3m, norm: qqw_26, },

      {pos: w9m, norm: qqw_27, },
      {pos: q2m, norm: qqw_27, },
      {pos: q6m, norm: qqw_27, },

      {pos: q3m, norm: qqq_6, },
      {pos: q6m, norm: qqq_6, },
      {pos: q2m, norm: qqq_6, },
    /* #endregion */
    /* #region cell_wqqq_10 */
      {pos: w10m, norm: qqw_28, },
      {pos: q3m, norm: qqw_28, },
      {pos: q6m, norm: qqw_28, },

      {pos: w10m, norm: qqw_29, },
      {pos: q6m, norm: qqw_29, },
      {pos: q5pm, norm: qqw_29, },

      {pos: w10m, norm: qqw_30, },
      {pos: q5pm, norm: qqw_30, },
      {pos: q3m, norm: qqw_30, },

      {pos: q3m, norm: qqq_10, },
      {pos: q5pm, norm: qqq_10, },
      {pos: q6m, norm: qqq_10, },
    /* #endregion */
    /* #region cell_wqqq_11 */
      {pos: w1pm, norm: qqw_31, },
      {pos: q2pm, norm: qqw_31, },
      {pos: q1pm, norm: qqw_31, },

      {pos: w1pm, norm: qqw_32, },
      {pos: q3pm, norm: qqw_32, },
      {pos: q2pm, norm: qqw_32, },

      {pos: w1pm, norm: qqw_33, },
      {pos: q1pm, norm: qqw_33, },
      {pos: q3pm, norm: qqw_33, },

      {pos: q1pm, norm: qqq_18, },
      {pos: q2pm, norm: qqq_18, },
      {pos: q3pm, norm: qqq_18, },
    /* #endregion */
    /* #region cell_wqqq_12 */
      {pos: w2pm, norm: qqw_34, },
      {pos: q6m, norm: qqw_34, },
      {pos: q1pm, norm: qqw_34, },

      {pos: w2pm, norm: qqw_35, },
      {pos: q1pm, norm: qqw_35, },
      {pos: q5pm, norm: qqw_35, },

      {pos: w2pm, norm: qqw_36, },
      {pos: q5pm, norm: qqw_36, },
      {pos: q6m, norm: qqw_36, },

      {pos: q6m, norm: qqq_16, },
      {pos: q5pm, norm: qqq_16, },
      {pos: q1pm, norm: qqq_16, },
    /* #endregion */
    /* #region cell_wqqq_13 */
      {pos: w3pm, norm: qqw_37, },
      {pos: q5m, norm: qqw_37, },
      {pos: q3pm, norm: qqw_37, },

      {pos: w3pm, norm: qqw_38, },
      {pos: q3pm, norm: qqw_38, },
      {pos: q4pm, norm: qqw_38, },

      {pos: w3pm, norm: qqw_39, },
      {pos: q4pm, norm: qqw_39, },
      {pos: q5m, norm: qqw_39, },

      {pos: q5m, norm: qqq_13, },
      {pos: q4pm, norm: qqq_13, },
      {pos: q3pm, norm: qqq_13, },
    /* #endregion */
    /* #region cell_wqqq_14 */
      {pos: w4pm, norm: qqw_40, },
      {pos: q6m, norm: qqw_40, },
      {pos: q2m, norm: qqw_40, },

      {pos: w4pm, norm: qqw_41, },
      {pos: q4pm, norm: qqw_41, },
      {pos: q6m, norm: qqw_41, },

      {pos: w4pm, norm: qqw_42, },
      {pos: q2m, norm: qqw_42, },
      {pos: q4pm, norm: qqw_42, },

      {pos: q2m, norm: qqq_8, },
      {pos: q6m, norm: qqq_8, },
      {pos: q4pm, norm: qqq_8, },
    /* #endregion */
    /* #region cell_wqqq_15 */
      {pos: w5pm, norm: qqw_43, },
      {pos: q3pm, norm: qqw_43, },
      {pos: q1pm, norm: qqw_43, },

      {pos: w5pm, norm: qqw_44, },
      {pos: q4pm, norm: qqw_44, },
      {pos: q3pm, norm: qqw_44, },

      {pos: w5pm, norm: qqw_45, },
      {pos: q1pm, norm: qqw_45, },
      {pos: q4pm, norm: qqw_45, },

      {pos: q1pm, norm: qqq_17, },
      {pos: q3pm, norm: qqq_17, },
      {pos: q4pm, norm: qqq_17, },
    /* #endregion */
    /* #region cell_wqqq_16 */
      {pos: w6pm, norm: qqw_46, },
      {pos: q1pm, norm: qqw_46, },
      {pos: q6m, norm: qqw_46, },

      {pos: w6pm, norm: qqw_47, },
      {pos: q4pm, norm: qqw_47, },
      {pos: q1pm, norm: qqw_47, },

      {pos: w6pm, norm: qqw_48, },
      {pos: q6m, norm: qqw_48, },
      {pos: q4pm, norm: qqw_48, },

      {pos: q6m, norm: qqq_15, },
      {pos: q1pm, norm: qqq_15, },
      {pos: q4pm, norm: qqq_15, },
    /* #endregion */
    /* #region cell_wqqq_17 */
      {pos: w7pm, norm: qqw_49, },
      {pos: q1pm, norm: qqw_49, },
      {pos: q2pm, norm: qqw_49, },

      {pos: w7pm, norm: qqw_50, },
      {pos: q2pm, norm: qqw_50, },
      {pos: q5pm, norm: qqw_50, },

      {pos: w7pm, norm: qqw_51, },
      {pos: q5pm, norm: qqw_51, },
      {pos: q1pm, norm: qqw_51, },

      {pos: q1pm, norm: qqq_19, },
      {pos: q5pm, norm: qqq_19, },
      {pos: q2pm, norm: qqq_19, },
    /* #endregion */
    /* #region cell_wqqq_18 */
      {pos: w8pm, norm: qqw_52, },
      {pos: q2pm, norm: qqw_52, },
      {pos: q4m, norm: qqw_52, },

      {pos: w8pm, norm: qqw_53, },
      {pos: q5pm, norm: qqw_53, },
      {pos: q2pm, norm: qqw_53, },

      {pos: w8pm, norm: qqw_54, },
      {pos: q4m, norm: qqw_54, },
      {pos: q5pm, norm: qqw_54, },

      {pos: q4m, norm: qqq_11, },
      {pos: q2pm, norm: qqq_11, },
      {pos: q5pm, norm: qqq_11, },
    /* #endregion */
    /* #region cell_wqqq_19 */
      {pos: w9pm, norm: qqw_55, },
      {pos: q2pm, norm: qqw_55, },
      {pos: q3pm, norm: qqw_55, },

      {pos: w9pm, norm: qqw_56, },
      {pos: q3pm, norm: qqw_56, },
      {pos: q6pm, norm: qqw_56, },

      {pos: w9pm, norm: qqw_57, },
      {pos: q6pm, norm: qqw_57, },
      {pos: q2pm, norm: qqw_57, },

      {pos: q2pm, norm: qqq_20, },
      {pos: q6pm, norm: qqq_20, },
      {pos: q3pm, norm: qqq_20, },
    /* #endregion */
    /* #region cell_wqqq_20 */
      {pos: w10pm, norm: qqw_58, },
      {pos: q3pm, norm: qqw_58, },
      {pos: q5m, norm: qqw_58, },

      {pos: w10pm, norm: qqw_59, },
      {pos: q6pm, norm: qqw_59, },
      {pos: q3pm, norm: qqw_59, },

      {pos: w10pm, norm: qqw_60, },
      {pos: q5m, norm: qqw_60, },
      {pos: q6pm, norm: qqw_60, },

      {pos: q5m, norm: qqq_14, },
      {pos: q3pm, norm: qqq_14, },
      {pos: q6pm, norm: qqq_14, },
    /* #endregion */
    ]} else {
      verts14 = []
    }
  /* #endregion */
  /* #region qqqi cells (20) */
  if (layer15) {  
    verts15 = [
    /* #region cell_qqqi_1 */
      {pos: iw1m, norm: qqw_1, },
      {pos: q1m, norm: qqw_1, },
      {pos: q2m, norm: qqw_1, },

      {pos: iw1m, norm: qqw_2, },
      {pos: q2m, norm: qqw_2, },
      {pos: q3m, norm: qqw_2, },

      {pos: iw1m, norm: qqw_3, },
      {pos: q3m, norm: qqw_3, },
      {pos: q1m, norm: qqw_3, },

      {pos: q1m, norm: qqq_1, },
      {pos: q3m, norm: qqq_1, },
      {pos: q2m, norm: qqq_1, },
    /* #endregion */
    /* #region cell_qqqi_2 */
      {pos: iw2m, norm: qqw_4, },
      {pos: q5m, norm: qqw_4, },
      {pos: q1m, norm: qqw_4, },

      {pos: iw2m, norm: qqw_5, },
      {pos: q6pm, norm: qqw_5, },
      {pos: q5m, norm: qqw_5, },

      {pos: iw2m, norm: qqw_6, },
      {pos: q1m, norm: qqw_6, },
      {pos: q6pm, norm: qqw_6, },

      {pos: q1m, norm: qqq_5, },
      {pos: q5m, norm: qqq_5, },
      {pos: q6pm, norm: qqq_5, },
    /* #endregion */
    /* #region cell_qqqi_3 */
      {pos: iw3m, norm: qqw_7, },
      {pos: q4m, norm: qqw_7, },
      {pos: q3m, norm: qqw_7, },

      {pos: iw3m, norm: qqw_8, },
      {pos: q5pm, norm: qqw_8, },
      {pos: q4m, norm: qqw_8, },

      {pos: iw3m, norm: qqw_9, },
      {pos: q3m, norm: qqw_9, },
      {pos: q5pm, norm: qqw_9, },

      {pos: q4m, norm: qqq_9, },
      {pos: q5pm, norm: qqq_9, },
      {pos: q3m, norm: qqq_9, },
    /* #endregion */
    /* #region cell_qqqi_4 */
      {pos: iw4m, norm: qqw_10, },
      {pos: q4m, norm: qqw_10, },
      {pos: q2pm, norm: qqw_10, },

      {pos: iw4m, norm: qqw_11, },
      {pos: q2pm, norm: qqw_11, },
      {pos: q6pm, norm: qqw_11, },

      {pos: iw4m, norm: qqw_12, },
      {pos: q6pm, norm: qqw_12, },
      {pos: q4m, norm: qqw_12, },

      {pos: q4m, norm: qqq_12, },
      {pos: q6pm, norm: qqq_12, },
      {pos: q2pm, norm: qqq_12, },
    /* #endregion */
    /* #region cell_qqqi_5 */
      {pos: iw5m, norm: qqw_13, },
      {pos: q1m, norm: qqw_13, },
      {pos: q3m, norm: qqw_13, },

      {pos: iw5m, norm: qqw_14, },
      {pos: q3m, norm: qqw_14, },
      {pos: q4m, norm: qqw_14, },

      {pos: iw5m, norm: qqw_15, },
      {pos: q4m, norm: qqw_15, },
      {pos: q1m, norm: qqw_15, },

      {pos: q1m, norm: qqq_3, },
      {pos: q4m, norm: qqq_3, },
      {pos: q3m, norm: qqq_3, },
    /* #endregion */
    /* #region cell_qqqi_6 */
      {pos: iw6m, norm: qqw_16, },
      {pos: q1m, norm: qqw_16, },
      {pos: q4m, norm: qqw_16, },

      {pos: iw6m, norm: qqw_17, },
      {pos: q4m, norm: qqw_17, },
      {pos: q6pm, norm: qqw_17, },

      {pos: iw6m, norm: qqw_18, },
      {pos: q6pm, norm: qqw_18, },
      {pos: q1m, norm: qqw_18, },

      {pos: q1m, norm: qqq_4, },
      {pos: q6pm, norm: qqq_4, },
      {pos: q4m, norm: qqq_4, },
    /* #endregion */
    /* #region cell_qqqi_7 */
      {pos: iw7m, norm: qqw_19, },
      {pos: q2m, norm: qqw_19, },
      {pos: q1m, norm: qqw_19, },

      {pos: iw7m, norm: qqw_20, },
      {pos: q5m, norm: qqw_20, },
      {pos: q2m, norm: qqw_20, },

      {pos: iw7m, norm: qqw_21, },
      {pos: q1m, norm: qqw_21, },
      {pos: q5m, norm: qqw_21, },

      {pos: q2m, norm: qqq_2, },
      {pos: q5m, norm: qqq_2, },
      {pos: q1m, norm: qqq_2, },
    /* #endregion */
    /* #region cell_qqqi_8 */
      {pos: iw8m, norm: qqw_22, },
      {pos: q2m, norm: qqw_22, },
      {pos: q5m, norm: qqw_22, },

      {pos: iw8m, norm: qqw_23, },
      {pos: q5m, norm: qqw_23, },
      {pos: q4pm, norm: qqw_23, },

      {pos: iw8m, norm: qqw_24, },
      {pos: q4pm, norm: qqw_24, },
      {pos: q2m, norm: qqw_24, },

      {pos: q5m, norm: qqq_7, },
      {pos: q2m, norm: qqq_7, },
      {pos: q4pm, norm: qqq_7, },
    /* #endregion */
    /* #region cell_qqqi_9 */
      {pos: iw9m, norm: qqw_25, },
      {pos: q3m, norm: qqw_25, },
      {pos: q2m, norm: qqw_25, },

      {pos: iw9m, norm: qqw_26, },
      {pos: q6m, norm: qqw_26, },
      {pos: q3m, norm: qqw_26, },

      {pos: iw9m, norm: qqw_27, },
      {pos: q2m, norm: qqw_27, },
      {pos: q6m, norm: qqw_27, },

      {pos: q3m, norm: qqq_6, },
      {pos: q6m, norm: qqq_6, },
      {pos: q2m, norm: qqq_6, },
    /* #endregion */
    /* #region cell_qqqi_10 */
      {pos: iw10m, norm: qqw_28, },
      {pos: q3m, norm: qqw_28, },
      {pos: q6m, norm: qqw_28, },

      {pos: iw10m, norm: qqw_29, },
      {pos: q6m, norm: qqw_29, },
      {pos: q5pm, norm: qqw_29, },

      {pos: iw10m, norm: qqw_30, },
      {pos: q5pm, norm: qqw_30, },
      {pos: q3m, norm: qqw_30, },

      {pos: q3m, norm: qqq_10, },
      {pos: q5pm, norm: qqq_10, },
      {pos: q6m, norm: qqq_10, },
    /* #endregion */
    /* #region cell_qqqi_11 */
      {pos: iw1pm, norm: qqw_31, },
      {pos: q2pm, norm: qqw_31, },
      {pos: q1pm, norm: qqw_31, },

      {pos: iw1pm, norm: qqw_32, },
      {pos: q3pm, norm: qqw_32, },
      {pos: q2pm, norm: qqw_32, },

      {pos: iw1pm, norm: qqw_33, },
      {pos: q1pm, norm: qqw_33, },
      {pos: q3pm, norm: qqw_33, },

      {pos: q1pm, norm: qqq_18, },
      {pos: q2pm, norm: qqq_18, },
      {pos: q3pm, norm: qqq_18, },
    /* #endregion */
    /* #region cell_qqqi_12 */
      {pos: iw2pm, norm: qqw_34, },
      {pos: q6m, norm: qqw_34, },
      {pos: q1pm, norm: qqw_34, },

      {pos: iw2pm, norm: qqw_35, },
      {pos: q1pm, norm: qqw_35, },
      {pos: q5pm, norm: qqw_35, },

      {pos: iw2pm, norm: qqw_36, },
      {pos: q5pm, norm: qqw_36, },
      {pos: q6m, norm: qqw_36, },

      {pos: q6m, norm: qqq_16, },
      {pos: q5pm, norm: qqq_16, },
      {pos: q1pm, norm: qqq_16, },
    /* #endregion */
    /* #region cell_qqqi_13 */
      {pos: iw3pm, norm: qqw_37, },
      {pos: q5m, norm: qqw_37, },
      {pos: q3pm, norm: qqw_37, },

      {pos: iw3pm, norm: qqw_38, },
      {pos: q3pm, norm: qqw_38, },
      {pos: q4pm, norm: qqw_38, },

      {pos: iw3pm, norm: qqw_39, },
      {pos: q4pm, norm: qqw_39, },
      {pos: q5m, norm: qqw_39, },

      {pos: q5m, norm: qqq_13, },
      {pos: q4pm, norm: qqq_13, },
      {pos: q3pm, norm: qqq_13, },
    /* #endregion */
    /* #region cell_qqqi_14 */
      {pos: iw4pm, norm: qqw_40, },
      {pos: q6m, norm: qqw_40, },
      {pos: q2m, norm: qqw_40, },

      {pos: iw4pm, norm: qqw_41, },
      {pos: q4pm, norm: qqw_41, },
      {pos: q6m, norm: qqw_41, },

      {pos: iw4pm, norm: qqw_42, },
      {pos: q2m, norm: qqw_42, },
      {pos: q4pm, norm: qqw_42, },

      {pos: q2m, norm: qqq_8, },
      {pos: q6m, norm: qqq_8, },
      {pos: q4pm, norm: qqq_8, },
    /* #endregion */
    /* #region cell_qqqi_15 */
      {pos: iw5pm, norm: qqw_43, },
      {pos: q3pm, norm: qqw_43, },
      {pos: q1pm, norm: qqw_43, },

      {pos: iw5pm, norm: qqw_44, },
      {pos: q4pm, norm: qqw_44, },
      {pos: q3pm, norm: qqw_44, },

      {pos: iw5pm, norm: qqw_45, },
      {pos: q1pm, norm: qqw_45, },
      {pos: q4pm, norm: qqw_45, },

      {pos: q1pm, norm: qqq_17, },
      {pos: q3pm, norm: qqq_17, },
      {pos: q4pm, norm: qqq_17, },
    /* #endregion */
    /* #region cell_qqqi_16 */
      {pos: iw6pm, norm: qqw_46, },
      {pos: q1pm, norm: qqw_46, },
      {pos: q6m, norm: qqw_46, },

      {pos: iw6pm, norm: qqw_47, },
      {pos: q4pm, norm: qqw_47, },
      {pos: q1pm, norm: qqw_47, },

      {pos: iw6pm, norm: qqw_48, },
      {pos: q6m, norm: qqw_48, },
      {pos: q4pm, norm: qqw_48, },

      {pos: q6m, norm: qqq_15, },
      {pos: q1pm, norm: qqq_15, },
      {pos: q4pm, norm: qqq_15, },
    /* #endregion */
    /* #region cell_qqqi_17 */
      {pos: iw7pm, norm: qqw_49, },
      {pos: q1pm, norm: qqw_49, },
      {pos: q2pm, norm: qqw_49, },

      {pos: iw7pm, norm: qqw_50, },
      {pos: q2pm, norm: qqw_50, },
      {pos: q5pm, norm: qqw_50, },

      {pos: iw7pm, norm: qqw_51, },
      {pos: q5pm, norm: qqw_51, },
      {pos: q1pm, norm: qqw_51, },

      {pos: q1pm, norm: qqq_19, },
      {pos: q5pm, norm: qqq_19, },
      {pos: q2pm, norm: qqq_19, },
    /* #endregion */
    /* #region cell_qqqi_18 */
      {pos: iw8pm, norm: qqw_52, },
      {pos: q2pm, norm: qqw_52, },
      {pos: q4m, norm: qqw_52, },

      {pos: iw8pm, norm: qqw_53, },
      {pos: q5pm, norm: qqw_53, },
      {pos: q2pm, norm: qqw_53, },

      {pos: iw8pm, norm: qqw_54, },
      {pos: q4m, norm: qqw_54, },
      {pos: q5pm, norm: qqw_54, },

      {pos: q4m, norm: qqq_11, },
      {pos: q2pm, norm: qqq_11, },
      {pos: q5pm, norm: qqq_11, },
    /* #endregion */
    /* #region cell_qqqi_19 */
      {pos: iw9pm, norm: qqw_55, },
      {pos: q2pm, norm: qqw_55, },
      {pos: q3pm, norm: qqw_55, },

      {pos: iw9pm, norm: qqw_56, },
      {pos: q3pm, norm: qqw_56, },
      {pos: q6pm, norm: qqw_56, },

      {pos: iw9pm, norm: qqw_57, },
      {pos: q6pm, norm: qqw_57, },
      {pos: q2pm, norm: qqw_57, },

      {pos: q2pm, norm: qqq_20, },
      {pos: q6pm, norm: qqq_20, },
      {pos: q3pm, norm: qqq_20, },
    /* #endregion */
    /* #region cell_qqqi_20 */
      {pos: iw10pm, norm: qqw_58, },
      {pos: q3pm, norm: qqw_58, },
      {pos: q5m, norm: qqw_58, },

      {pos: iw10pm, norm: qqw_59, },
      {pos: q6pm, norm: qqw_59, },
      {pos: q3pm, norm: qqw_59, },

      {pos: iw10pm, norm: qqw_60, },
      {pos: q5m, norm: qqw_60, },
      {pos: q6pm, norm: qqw_60, },

      {pos: q5m, norm: qqq_14, },
      {pos: q3pm, norm: qqq_14, },
      {pos: q6pm, norm: qqq_14, },
    /* #endregion */
    ]} else {
      verts15 = []
    }
  /* #endregion */
  /* #endregion */
  let poss = []
  let norms = []
  let rgb1 = []
  for (const vertex of verts1) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color1.r/256)
    rgb1.push(color1.g/256)
    rgb1.push(color1.b/256)
    rgb1.push(color1.a)
  }
  for (const vertex of verts2) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color2.r/256)
    rgb1.push(color2.g/256)
    rgb1.push(color2.b/256)
    rgb1.push(color2.a)
  }
  for (const vertex of verts3) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color3.r/256)
    rgb1.push(color3.g/256)
    rgb1.push(color3.b/256)
    rgb1.push(color3.a)
  }
  for (const vertex of verts4) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color4.r/256)
    rgb1.push(color4.g/256)
    rgb1.push(color4.b/256)
    rgb1.push(color4.a)
  }
  for (const vertex of verts5) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color5.r/256)
    rgb1.push(color5.g/256)
    rgb1.push(color5.b/256)
    rgb1.push(color5.a)
  }
  for (const vertex of verts6) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color6.r/256)
    rgb1.push(color6.g/256)
    rgb1.push(color6.b/256)
    rgb1.push(color6.a)
  }
  for (const vertex of verts7) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color7.r/256)
    rgb1.push(color7.g/256)
    rgb1.push(color7.b/256)
    rgb1.push(color7.a)
  }
  for (const vertex of verts8) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color8.r/256)
    rgb1.push(color8.g/256)
    rgb1.push(color8.b/256)
    rgb1.push(color8.a)
  }
  for (const vertex of verts9) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color9.r/256)
    rgb1.push(color9.g/256)
    rgb1.push(color9.b/256)
    rgb1.push(color9.a)
  }
  for (const vertex of verts10) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color10.r/256)
    rgb1.push(color10.g/256)
    rgb1.push(color10.b/256)
    rgb1.push(color10.a)
  }
  for (const vertex of verts11) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color11.r/256)
    rgb1.push(color11.g/256)
    rgb1.push(color11.b/256)
    rgb1.push(color11.a)
  }
  for (const vertex of verts12) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color12.r/256)
    rgb1.push(color12.g/256)
    rgb1.push(color12.b/256)
    rgb1.push(color12.a)
  }
  for (const vertex of verts13) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color13.r/256)
    rgb1.push(color13.g/256)
    rgb1.push(color13.b/256)
    rgb1.push(color13.a)
  }
  for (const vertex of verts14) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color14.r/256)
    rgb1.push(color14.g/256)
    rgb1.push(color14.b/256)
    rgb1.push(color14.a)
  }
  for (const vertex of verts15) {
    poss.push(...vertex.pos)
    norms.push(...vertex.norm)
    rgb1.push(color15.r/256)
    rgb1.push(color15.g/256)
    rgb1.push(color15.b/256)
    rgb1.push(color15.a)
  }

  positions.current = new Float32Array(poss)
  normals.current = new Float32Array(norms)
  colors.current = new Float32Array(rgb1)

  useEffect (() =>{
    meshRef.current.geometry.setAttribute('position', new BufferAttribute(positions.current, 3))
    meshRef.current.geometry.setAttribute('normal', new BufferAttribute(normals.current, 3))
    meshRef.current.geometry.setAttribute('color', new BufferAttribute(colors.current, 4))
    meshRef.needsUpdate=true
    meshWireRef.current.geometry.setAttribute('position', new BufferAttribute(positions.current, 3))
    meshWireRef.current.geometry.setAttribute('normal', new BufferAttribute(normals.current, 3))
    meshWireRef.current.geometry.setAttribute('color', new BufferAttribute(colors.current, 4))
    meshWireRef.needsUpdate=true
  },[colors.current]);



  return (
    <group>
      <mesh ref={meshRef} colors={colors} > 
        <ambientLight />
        <pointLight />
        <meshPhongMaterial
            vertexColors
            transparent={true}
            opacity={opacity}
            polygonOffset={true}
            polygonOffsetFactor={1}
            polygonOffsetUnits={1}
            side={TwoPassDoubleSide}
            needsUpdate={true}
        />
        <bufferGeometry >
          <bufferAttribute
            attach = "attributes-position" 
            array = {positions.current}
            count = {positions.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
        </bufferGeometry >
      </mesh >
      <mesh ref={meshWireRef} colors={colors} >
        <ambientLight />
        <pointLight />
        <meshPhongMaterial
            wireframeLinewidth = {linewidth}
            vertexColors
            wireframe = {true}
        />
        <bufferGeometry >
          <bufferAttribute
            attach = "attributes-position" 
            array = {positions.current}
            count = {positions.current.length / 3}
            itemSize = {3}
            needsUpdate = {true}
          />
        </bufferGeometry >
      </mesh >
    </group>
  )
}

function v2n(v1, v2, v3) {
  return new Vector3().fromArray(subtract(v2,v1)).cross(new Vector3().fromArray(subtract(v3,v1))).normalize().toArray()
}

function Cell600() {
  return (
    <div style={{width: "100vw", height:"100vh"}} >
      <Leva />
      <Canvas style={{
        position: "fixed",
        width: "100%",
        height: "100%"
      }}>
        <Cell600Mesh />
        <OrbitControls />
      </Canvas>
    </div>
  )
}

export default Cell600