import React from "react";
import Summary from "./Summary";

const Summaries = () => {

  return (
    <div className="container py-4">
      <div className="row justify-content-center">
        <div className="col-lg-12 order-lg-1 offset-md-3">
          <div className="blog-posts">
            <div className="row align-items-end">
              <div className="col">
                <Summary summaryData = "post1" />
              </div>
              <div className="col">
                <Summary summaryData = "post2" />
              </div>
              {/* <Summary summaryData = "post3" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Summaries