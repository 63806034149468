import React, { useState, useEffect }  from "react";
import {Link} from "react-router-dom";

const Head = (props) => {
  let [headText, setHeadText] = useState([])
  let [headLink, setHeadLink] = useState([])
  let [headAuthor, setHeadAuthor] = useState([])

  useEffect(() => {
    setHeadText(props.quoteText)
    setHeadLink(props.quoteLink)
    setHeadAuthor(props.quoteAuthor)
  });
  


  return (
    <section className="page-header page-header-modern page-header-md mb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 align-self-center p-static order-2 text-center justify-content-center" >
            <div className="align-items-center justify-content-center mb-2 mb-lg-0 pb-5 logo pe-0 pe-lg-3">
              <Link to="/" >
                <img src="./img/deltabar.png" height="150" />
              </Link>
              <span className="sub-title text-color-light text-justify text-5 mt-4">
                {headText}
              </span>
              <span className="sub-title font-weight-bold text-color-secondary text-align-right text-5">
                <a href={headLink} className = "text-color-secondary" >
                  -{headAuthor}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Head